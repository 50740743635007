/** Класс модели производителя */
export class Manufacture {
	/** ID производителя */
	public id!: string;
	/** Марка производителя */
	public brand!: string;
	// /** Тип группы каталога */
	// public typeCatalog?: TypeCatalog;
	// /** Список моделей */
	// public models?: Model[];
	// /** Список типов моделей */
	// public typesModels?: TypeModel[];
	/** Возвращает системное имя */
	/// <param name="brand">Марка производителя</param>
	public static GetSysName(brand: string): string {
		return brand.replace('Ë', 'E').replace('(', '').replace(')', '').replace('-', '_').replace(' ', '_').toUpperCase();
	}
}
