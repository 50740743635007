import { useCallback, useEffect, useRef } from 'react';
import { IResult, Page, Pageable } from '@astick/core';
import { Table, TableRef, TableTypeColumn } from '@astick/ui';
import { User } from 'src/models';
import { useLoading } from 'src/hooks';
import { UsersService } from '../../services';
import { useToast } from '../../../base/hooks';
import { RolesWidget, RolesWidgetRef } from '../../widgets/roles.widgets';

export const UsersPage = () => {
	const setLoading = useLoading();
	const toast = useToast();

	const tableRef = useRef<TableRef>(null);
	const rolesRef = useRef<RolesWidgetRef>(null);

	const columns: TableTypeColumn[] = [
		{ name: 'id', header: 'ID', maxWidth: 100, defaultVisible: false, creatableHidden: true, editable: false },
		{ name: 'username', type: 'text', header: 'Логин' },
		{ name: 'lastName', type: 'text', header: 'Фамилия', minWidth: 170 },
		{ name: 'firstName', type: 'text', header: 'Имя', minWidth: 170 },
		{ name: 'surName', type: 'text', header: 'Отчество', minWidth: 170 },
		{ name: 'email', type: 'text', header: 'Email', minWidth: 200 },
		{ name: 'mobile', type: 'text', header: 'Мобильный', minWidth: 100 },
		{ name: 'city', type: 'text', header: 'Город', minWidth: 100 },
	];

	useEffect(() => {
		document.title = 'Пользователи';
	}, []);

	const loadUsers = useCallback(async (pageable: Pageable): Promise<Page<User> | undefined> => {
		const r = await UsersService.getUsers(pageable);
		if (r.success) {
			return r.value;
		} else {
			toast.addError('Не удалось получить список пользователей!');
		}
	}, []);

	return <>
		<RolesWidget ref={rolesRef} getSelectedRoles={async (user: User) => {
			const rUser = await UsersService.getUserRoles(user.id);
			if (!rUser.success) {
				toast.addError(`Не удалось получить роли для пользователя ${user.username}!`);
				return [];
			}
			return rUser.value!;
		}}
		onSubmit={async (user: User, roles) => {
			const r = await UsersService.setUserRoles(user.id, roles);
			if (r.success) {
				toast.addInfo(`Успешно изменены роли для пользователя ${user.username}!`);
			} else {
				toast.addError(`Не удалось изменить роли для пользователя ${user.username}!`);
			}
			tableRef.current?.getTable()?.reload();
			rolesRef.current?.close();
		}} />
		<Table
			ref={tableRef}
			idProperty="id"
			columns={columns}
			filterValue={[{
				name: 'username',
				operator: 'contains',
				type: 'string',
			}, {
				name: 'lastName',
				operator: 'contains',
				type: 'string',
			},
			{
				name: 'firstName',
				operator: 'contains',
				type: 'string',
			},
			{
				name: 'surName',
				operator: 'contains',
				type: 'string',
			},
			{
				name: 'email',
				operator: 'contains',
				type: 'string',
			},
			{
				name: 'mobile',
				operator: 'contains',
				type: 'string',
			},
			{
				name: 'city',
				operator: 'contains',
				type: 'string',
			}]}
			openable
			deletable
			pagination
			showColumnMenuTool
			load={loadUsers}
			renderRowContextMenu={(menuProps, { rowProps }) => {
				const user = rowProps.data;
				if (!user) {
					return;
				}
				menuProps.autoDismiss = true;
				menuProps.items.splice(1, 0, {
					label: 'Роли',
					onClick: () => {
						rolesRef.current!.open(`Роли пользователя "${User.getFio(user)}"`, user);
					},
				});
			}}
			onSubmit={async (operation, data: User) => {
				setLoading(true);
				let r: IResult | undefined;
				switch (operation) {
					case 'delete':
						r = await UsersService.deleteUser(data.id);
						if (!r.success) {
							toast.addError(`Не удалось удалить пользователя "${User.getFio(data)}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
						} else {
							toast.addSuccess(`Успешно удален пользователь "${User.getFio(data)}"`);
						}
						break;
					default:
						r = {
							success: false,
						};
				}
				setLoading(false);
				return r;
			}}
		/>
	</>;
};
