import React from 'react';
import useSettingsState from '../hooks/useSettingsState';
import { Themes } from '../models/constants';

type ThemeContextType = {
	theme: Themes;
	setTheme: (theme: Themes) => void;
};

const ThemeContext = React.createContext<ThemeContextType>({
	theme: 'default',
	setTheme: () => { },
});

const ThemeProvider = ({ children }: { children: any }) => {
	const [theme, setTheme] = useSettingsState('theme', 'default');

	return (
		<ThemeContext.Provider
			value={{
				theme,
				setTheme,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
};

export { ThemeProvider, ThemeContext };
