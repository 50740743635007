import { forwardRef, useEffect, useRef } from 'react';
import SelectBase, { Props as SelectBaseProps } from 'react-select';
import { useTheme } from '../hooks';
import { ControlProps } from './models';
import { Message } from './message.control';
import { Label } from './label.control';
import '../styles/select.control.scss';

export type SelectProps = Omit<SelectBaseProps<any>, 'value'> & ControlProps & {
	mode?: 'general' | 'primary';
	hint?: string;
	hintReadonly?: boolean;
	labelProperty?: string;
	valueProperty?: string;
	labelItemProperty?: string;
};

type SelectRef = object;

export const Select = forwardRef<SelectRef, SelectProps>((props, ref) => {
	const { labelProperty = 'label', valueProperty = 'value' } = props;
	const theme = useTheme();

	const value = props.value !== undefined ? (props.options?.find((item) => (item && props.value !== undefined ? item[valueProperty] === props.value : false)) as any) : null;

	const lProps = useRef({
		initData: false,
		initValue: false,
	});

	useEffect(() => {
		if (lProps.current.initData && lProps.current.initValue && !props.options?.some(i => i[valueProperty] === props.value)) {
			props.onChange?.(undefined);
		}
		lProps.current.initData = true;
	}, [props.options]);

	useEffect(() => {
		lProps.current.initValue = true;
	}, [props.value]);

	return (
		<div style={{ position: 'relative' }}>
			{props.label ? <Label text={props.label} hint={props.hint} hintReadonly={props.hintReadonly} /> : null}
			<SelectBase
				ref={ref as any}
				isDisabled={props.disabled}
				theme={theme.name === 'dark' ? (theme) => ({
					...theme,
					colors: {
						...theme.colors,
						neutral0: 'var(--bs-tertiary-bg)',
						neutral5: 'var(--bs-body-bg)',
						neutral10: 'var(--bs-body-bg)',
						neutral20: 'var(--bs-border-color)',
						neutral30: 'var(--bs-tertiary-color)',
						neutral50: 'var(--bs-secondary-color)',
						neutral60: 'var(--bs-secondary-color)',
						neutral70: 'var(--bs-secondary-color)',
						neutral80: 'var(--bs-secondary-color)',
						neutral90: 'var(--bs-secondary-color)',
						primary: 'var(--bs-secondary-color)',
						primary25: 'var(--bs-secondary-bg)',
						primary50: 'var(--bs-border-color)',
						primary75: 'var(--bs-border-color)',
					},
				}) : undefined}
				getOptionLabel={(v) => {
					return v[labelProperty];
				}}
				getOptionValue={(v) => {
					return v[valueProperty];
				}}
				formatOptionLabel={(data, options) => {
					return options.context === 'menu' && props.labelItemProperty ? data[props.labelItemProperty] : data[labelProperty];
				}}
				{...props}
				value={value}
				className={`select${props.message ? ' is-invalid' : ' is-valid'}${props.openMenuOnClick !== false && !props.isDisabled ? ' clickable' : ''}`}
				classNamePrefix={props.mode === 'primary' ? 'selectPrimary' : 'selectGeneral'}
				name={props.name}
				isSearchable={props.isSearchable !== undefined ? props.isSearchable : false}
				filterOption={() => true}
				onChange={(value) => {
					props.onChange?.(value);
				}}
				onBlur={(e) => {
					props.onBlur?.(e);
				}}
			/>
			{props.message ? <Message type="error" text={props.message} /> : null}
		</div>
	);
});

Select.displayName = 'Select';
