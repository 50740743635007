/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';
import { Provider } from './provider.model';

/** Ставки системы наценок на товар */
export type Rate = {
    /** ID ставки */
    id: string;

    /** ID поставщика товаров */
    providerId: string;

    /** ID поставщика товаров */
    provider: Provider;

    /** Стоимость */
    sum: number;
    
    /** Ставка */
    value: number;
}

export const rateSchema = yup.object({
	providerId: yup.string().required('Необходимо указать поставщика товаров'),
	sum: yup.number().positive().min(1, 'Минимальная значение стоимости ${min}.').required('Необходимо ввести стоимость'),
	value: yup.number().positive().min(1, 'Минимальная значение ставки ${min}.').required('Необходимо ввести ставку'),
});