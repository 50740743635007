import { Base64 } from '../core/base64';

export class AuthUtils {
	static getHeaderBearer(token: string): any {
		return {
			Authorization: `Bearer ${token}`,
		};
	}
	static getHeaderBasic(userName: string, password: string): any {
		const token = userName + ':' + password;
		const hash = Base64.btoa(token);
		return {
			Authorization: `Basic ${hash}`,
		};
	}
}
