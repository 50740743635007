import { Outlet, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks';
import { ShopService } from '../services/shop.service';
import { MainHeaderTop, MainHeaderBottom, MainSearch, MainFooter } from './main';
import '../styles/main.scss';

export const MainLayout = () => {
	const auth = useAuth();
	const user = auth.getUser();
	const navigate = useNavigate();

	const office = undefined;
	const schedule = ShopService.getMainPageSchedule(office);
	const phones = ShopService.getMainPagePhones(office);

	return <div>
		<div className="lMobileMain">
			<div className="lfone"></div>
			<div className="mdl-spinner mdl-js-spinner is-active"></div>
		</div>
		<div className={`main mdl-layout__container${user ? ' isAuth' : ''}`}>
			<div className="mdl-layout">
				<MainHeaderTop navigate={navigate} user={user} />
				<MainHeaderBottom navigate={navigate} schedule={schedule} phones={phones} user={user} />
				<MainSearch user={user} />
				<div className="main_content">
					<div className="main_width">
						<div className="page_content main_content_body">
							<div id="page_content">
								<Outlet />
							</div>
						</div>
					</div>
				</div>
				<MainFooter navigate={navigate} schedule={schedule} phones={phones} />
			</div>
		</div>
	</div>;
};
