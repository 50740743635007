import numbro from 'numbro';
import * as numbroLanguages from 'numbro/dist/languages.min.js';

const apiUrl = 'http://localhost:3000';

const configBase = {
	authEnabled: true,
	apiClientId: 'autoparts_client',
	systemVersion: '1.0',
	apiTitle: 'Астик',
	appVersion: '1.0',
	/** Время до окончании жизни токена, начинается попытки обновления токена (секунды) */
	tokenRefreshTimeToFinish: 300,
	/** Время между попытками обновления токена (секунды) */
	tokenRefreshTryTime: 30,
	language: 'ru-RU',
};
export const config = {
	...configBase,
	authEnabled: process.env.REACT_APP_AUTH_ENABLED !== undefined ? process.env.REACT_APP_AUTH_ENABLED === 'true' : configBase.authEnabled,
	apiUrlAccount: process.env.REACT_APP_API_URL_ACCOUNT ?? apiUrl,
	apiUrlProviders: process.env.REACT_APP_API_URL_PROVIDERS ?? apiUrl,
	apiUrlShop: process.env.REACT_APP_API_URL_SHOP ?? apiUrl,
	apiClientId: process.env.REACT_APP_CLIENT ?? configBase.apiClientId,
	apiTitle: process.env.REACT_APP_API_TITLE ?? configBase.apiTitle,
	appVersion: process.env.REACT_APP_APP_VERSION ?? configBase.appVersion,
	systemVersion: process.env.REACT_APP_SYSTEM_VERSION ?? configBase.systemVersion,
};

numbro.registerLanguage(numbroLanguages[config.language]);
numbro.setLanguage(config.language);
