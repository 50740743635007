import React from 'react';

import { Layouts } from '../models/constants';
import useSettingsState from '../hooks/useSettingsState';

type LayoutContextType = {
  layout: Layouts;
  setLayout: (theme: Layouts) => void;
};

const LayoutContext = React.createContext<LayoutContextType>({
	layout: 'fluid',
	setLayout: () => { },
});

function LayoutProvider({ children }: { children: any }) {
	const [layout, setLayout] = useSettingsState('layout', 'fluid');

	return (
		<LayoutContext.Provider
			value={{
				layout,
				setLayout,
			}}
		>
			{children}
		</LayoutContext.Provider>
	);
}

export { LayoutProvider, LayoutContext };
