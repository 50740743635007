/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

/** Скидка */
export type Discount = {
    /** ID скидки */
    id: string;

    /** Роль скидки */
    roleName: string;

    /** Скидка */
    discount: number;
}

export const discountSchema = yup.object({
	roleName: yup.string().min(2, 'Минимальная длина роли ${min} буквы.').matches(/^[a-z]+$/i, {
		excludeEmptyString: true,
		message: 'Неверный формат роли',
	}).required('Необходимо ввести роль'),
	discount: yup.number().required('Необходимо ввести скидку'),
});