import { useField } from 'formik';
import { Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

export type ToggleButtonGroupFormProps = {
  name: string;
  options: {
    [p: string]: { label: string; value };
  };
  type?: 'checkbox' | 'radio';
  value?: any;
  className?: string;
  style?: any;
};

export const ToggleButtonGroupForm = (props: ToggleButtonGroupFormProps) => {
	const { type = 'radio' } = props;

	const [f, meta, helpers] = useField(props.name);

	return (
		<div className={props.className} style={{ ...props.style, position: 'relative' }}>
			<ToggleButtonGroup
				name={props.name}
				type={type}
				value={props.value ?? f.value}
				onChange={(val) => {
					helpers.setValue(val);
				}}
			>
				{props.options
					? Object.keys(props.options).map((key, index) => (
						<ToggleButton key={key} id={`tbg-${type}-${props.name}-${index}`} variant="outline-primary" value={props.options?.[key].value}>
							{props.options?.[key].label}
						</ToggleButton>
					))
					: null}
			</ToggleButtonGroup>
			{meta.touched && meta.error ? (
				<Form.Control.Feedback type="invalid" tooltip>
					{meta.error}
				</Form.Control.Feedback>
			) : null}
		</div>
	);
};
