import { PasswordValidations } from '@astick/core';
import * as yup from 'yup';

/** Модель сброса пароля */
export type ResetPassword = {
	/** Пароль */
	password: string;

	/** Подтверждение пароля */
	confirmPassword: string;
}

export const resetPasswordSchema = yup.object({
	password: PasswordValidations.password,
	confirmPassword: PasswordValidations.getPasswordConfirmValid('password'),
});


