import { IHttpResult, Pageable, Page } from '@astick/core';
import { SpringUtils } from '@astick/ui';
import { config } from '../config';
import { HttpRepository } from './http.repository';
import { Discount, Provider, ProviderRole, Rate } from '../models/prices';

export class PricesRepository {
	//#region Discounts
	static async getDiscounts(pageable: Pageable): Promise<IHttpResult<Page<Discount>>> {
		const resp = await fetch(`${config.apiUrlProviders}/discounts?${SpringUtils.getHttpParams(pageable)}`, HttpRepository.getRequestInit({ method: 'get' }));
		const r = await HttpRepository.getResult<Discount[]>(resp);
		return {
			...r,
			value: SpringUtils.getPage(r),
		};
	}

	static async createDiscount(discount: Discount): Promise<IHttpResult<Discount>> {
		const resp = await fetch(`${config.apiUrlProviders}/discounts`, HttpRepository.getRequestInit({ method: 'post', body: discount }));
		return await HttpRepository.getResult<Discount>(resp);
	}

	static async updateDiscount(discount: Discount): Promise<IHttpResult<Discount>> {
		const resp = await fetch(`${config.apiUrlProviders}/discounts`, HttpRepository.getRequestInit({ method: 'put', body: discount }));
		return await HttpRepository.getResult<Discount>(resp);
	}

	static async deleteDiscount(discountId: string): Promise<IHttpResult> {
		const resp = await fetch(`${config.apiUrlProviders}/discounts?id=${discountId}`, HttpRepository.getRequestInit({ method: 'delete' }));
		return await HttpRepository.getResult(resp);
	}
	//#endregion

	//#region Providers
	static async getProviders(pageable: Pageable): Promise<IHttpResult<Page<Provider>>> {
		const resp = await fetch(`${config.apiUrlProviders}/providers?${SpringUtils.getHttpParams(pageable)}`, HttpRepository.getRequestInit({ method: 'get' }));
		const r = await HttpRepository.getResult<Provider[]>(resp);
		return {
			...r,
			value: SpringUtils.getPage(r),
		};
	}

	static async createProvider(provider: Provider): Promise<IHttpResult<Provider>> {
		const resp = await fetch(`${config.apiUrlProviders}/providers`, HttpRepository.getRequestInit({ method: 'post', body: provider }));
		return await HttpRepository.getResult<Provider>(resp);
	}

	static async updateProvider(provider: Provider): Promise<IHttpResult<Provider>> {
		const resp = await fetch(`${config.apiUrlProviders}/providers`, HttpRepository.getRequestInit({ method: 'put', body: provider }));
		return await HttpRepository.getResult<Provider>(resp);
	}

	static async deleteProvider(providerId: string): Promise<IHttpResult> {
		const resp = await fetch(`${config.apiUrlProviders}/providers?id=${providerId}`, HttpRepository.getRequestInit({ method: 'delete' }));
		return await HttpRepository.getResult(resp);
	}
	//#endregion

	//#region ProvidersRoles
	static async getProvidersRoles(pageable: Pageable): Promise<IHttpResult<Page<ProviderRole>>> {
		const resp = await fetch(`${config.apiUrlProviders}/providersRoles?${SpringUtils.getHttpParams(pageable)}`, HttpRepository.getRequestInit({ method: 'get' }));
		const r = await HttpRepository.getResult<ProviderRole[]>(resp);
		return {
			...r,
			value: SpringUtils.getPage(r),
		};
	}

	static async createProviderRole(providerRole: ProviderRole): Promise<IHttpResult<ProviderRole>> {
		const resp = await fetch(`${config.apiUrlProviders}/providersRoles`, HttpRepository.getRequestInit({ method: 'post', body: providerRole }));
		return await HttpRepository.getResult<ProviderRole>(resp);
	}

	static async updateProviderRole(providerRole: ProviderRole): Promise<IHttpResult<ProviderRole>> {
		const resp = await fetch(`${config.apiUrlProviders}/providersRoles`, HttpRepository.getRequestInit({ method: 'put', body: providerRole }));
		return await HttpRepository.getResult<ProviderRole>(resp);
	}

	static async deleteProviderRole(providerRoleId: string): Promise<IHttpResult> {
		const resp = await fetch(`${config.apiUrlProviders}/providersRoles?id=${providerRoleId}`, HttpRepository.getRequestInit({ method: 'delete' }));
		return await HttpRepository.getResult(resp);
	}
	//#endregion

	//#region Rates
	static async getRates(pageable: Pageable): Promise<IHttpResult<Page<Rate>>> {
		const resp = await fetch(`${config.apiUrlProviders}/rates?${SpringUtils.getHttpParams(pageable)}`, HttpRepository.getRequestInit({ method: 'get' }));
		const r = await HttpRepository.getResult<Rate[]>(resp);
		return {
			...r,
			value: SpringUtils.getPage(r),
		};
	}

	static async createRate(rate: Rate): Promise<IHttpResult<Rate>> {
		const resp = await fetch(`${config.apiUrlProviders}/rates`, HttpRepository.getRequestInit({ method: 'post', body: rate }));
		return await HttpRepository.getResult<Rate>(resp);
	}

	static async updateRate(rate: Rate): Promise<IHttpResult<Rate>> {
		const resp = await fetch(`${config.apiUrlProviders}/rates`, HttpRepository.getRequestInit({ method: 'put', body: rate }));
		return await HttpRepository.getResult<Rate>(resp);
	}

	static async deleteRate(rateId: string): Promise<IHttpResult> {
		const resp = await fetch(`${config.apiUrlProviders}/rates?id=${rateId}`, HttpRepository.getRequestInit({ method: 'delete' }));
		return await HttpRepository.getResult(resp);
	}
	//#endregion
};
