import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AvtoModel } from '../../models/catalog';

export const CatalogPage = () => {
	const prm = useParams();
	const navigate = useNavigate();

	const [models, _setModels] = useState<AvtoModel[]>();

	const { brand } = prm;

	useEffect(() => {
		if (!brand) {
			return;
		}
		// CatalogsService.getModels(brand).then((r) => {
		// 	if (r.success) {
		// 		setModels(r.value);
		// 	} else {
		// 		toast.addError('Не удалось получить список моделей!');
		// 	}
		// });
	}, [brand]);

	if (!models) {
		return null;
	}
	if (models.length === 0) {
		return (<div>Модели отсутствуют</div>);
	}

	return (<div className="models">
		<h3>Список моделей &quot;{brand}&quot;:</h3>
		{models.map((model, index) => (
			<div key={index} className="brand">
				<a onClick={() => navigate(`/catalogs/${brand}/${model.id}?filters=`)}>{model.name}</a>
			</div>
		))}</div>);
};
