import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface PageTitleProps {
	title: string;
}

export const PageTitle: React.FC<PageTitleProps> = ({ title }: { title: string }) => {
	const location = useLocation();

	useEffect(() => {
		document.title = title;
	}, [location, title]);

	return null; // This component doesn't render anything
};