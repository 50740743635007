import { Form } from 'react-bootstrap';
import { CheckboxProps } from './checkbox.control';
import { Label } from './label.control';
import { Message } from './message.control';

type RadioGroupProps = Omit<CheckboxProps, 'onChange' | 'value'> & {
	data?: any[];
	value?: any;
	valueProperty?: string;
	labelProperty?: string;
	inline?: boolean;
	onChange?: (value?: any) => void;
};

export const RadioGroup = (props: RadioGroupProps) => {
	const { valueProperty = 'value', labelProperty = 'label' } = props;

	return props.data && props.data.length > 0 ? (
    	<div className="radio-list">
			<Form.Group>
				{props.label ? <Label text={props.label} hint={props.hint} hintReadonly={props.hintReadonly} /> : null}
				<div className={props.message ? 'is-invalid' : undefined}>
					{props.data?.map((item, index) => {
						const value = item[valueProperty];
						return (
							<Form.Check
								{...props}
								id={`radio-list-item-${item[labelProperty]}${index}`}
								key={index}
								type="radio"
								label={item[labelProperty]}
								checked={props.value !== undefined ? props.value === value : undefined}
								value={value}
								onChange={() => {
									props.onChange?.(item);
								}}
							/>
						);
					})}
				</div>
				{props.message && props.message !== '' ? <Message type={props.messageType} text={props.message} /> : null}
			</Form.Group>
		</div>
	) : null;
};
