// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.label-control {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}`, "",{"version":3,"sources":["webpack://./src/lib_ui/src/styles/label.control.scss"],"names":[],"mappings":"AAAA;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;AACD","sourcesContent":[".label-control {\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: center;\n\tjustify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
