// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select.is-invalid .selectGeneral__control {
  border-color: var(--bs-form-invalid-border-color);
}`, "",{"version":3,"sources":["webpack://./src/lib_ui/src/styles/select.control.scss"],"names":[],"mappings":"AAEE;EACC,iDAAA;AADH","sourcesContent":[".select {\n\t&.is-invalid {\n\t\t.selectGeneral__control {\n\t\t\tborder-color: var(--bs-form-invalid-border-color);\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
