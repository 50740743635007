import { useRef, useState } from 'react';
import { User } from '../../models';
import { Clock, Search } from 'react-feather';
import { useNavigate } from 'react-router-dom';

export const MainSearch = (props: {
	user?: User;
}) => {
	const { user } = props;

	const navigate = useNavigate();
	const inputRef = useRef<HTMLInputElement>(null);

	const getHistoryArticles = () => localStorage.getItem('historyArticles')?.split(',') ?? [];

	const [searchType, setSearchType] = useState<'article_online' | 'article_price' | 'vin'>('article_online');
	const [historyArticles, setHistoryArticles] = useState(getHistoryArticles());
	const [showHistoryArticles, setShowHistoryArticles] = useState(false);

	const search = async () => {
		const v = inputRef.current!.value;
		const arts = [v, ...getHistoryArticles().filter(art => art !== v)];
		if (arts.length > 15) {
			arts.slice(15);
		}
		localStorage.setItem('historyArticles', arts.join(','));
		setHistoryArticles(arts);
		setShowHistoryArticles(false);
		navigate(`search?value=${v}`);
	};

	return <div className="main_search">
		<div className="main_width">
			<div className="main_search_content">
				<div className="main_search_tabs">
					{user?.isManager ? <>
						<div className="main_search_tab main_search_tab_art main_search_tab_select">ПОИСК ПО ПРАЙСАМ</div>
						<div className="main_search_tab main_search_tab_with_button main_search_tab_online">
							<div className="main_search_tab_with_button_text">ОНЛАЙН ПОИСК</div>
							{/* <div className="main_search_tab_with_button_btn"><i className="material-icons"><Settings /></i></div> */}
						</div>
					</> : <>
						<div className={`main_search_tab main_search_tab_art${searchType === 'article_online' ? ' main_search_tab_select' : ''}`}
							onClick={() => setSearchType('article_online')}>ПОИСК ПО АРТИКУЛУ</div>
						<div className={`main_search_tab main_search_tab_vin${searchType === 'vin' ? ' main_search_tab_select' : ''}`}
							onClick={() => setSearchType('vin')}>ЗАПРОС ПО VIN</div>
					</>}
				</div>
				<div className="main_search_input">
					<div className="main_search_input_title">Поиск:</div>
					<div className="main_search_input_btn" onClick={search}>
						<i className="material-icons"><Search /></i>
					</div>
					<div className="main_search_input_input">
						<input ref={inputRef} type="text" id="main_search_input" placeholder={searchType === 'vin' ? 'Введите VIN' : 'Введите номер детали'}
							onKeyUp={async (ev) => {
								if (ev.key !== 'Enter')
									return;
								await search();
							}}
							onClick={() => setShowHistoryArticles(true)}
							onFocus={() => setShowHistoryArticles(true)}
							onBlur={() => setShowHistoryArticles(false)}
						/>
					</div>
					{showHistoryArticles ? <div className="search-input-history">
						{historyArticles.map((historyArticle, index) =>
							<div key={index} className="search-input-history-item" onMouseDown={() => {
								if (!inputRef.current) {
									return;
								}
								inputRef.current.value = historyArticle;
							}}>
								<Clock />
								<span>{historyArticle}</span>
							</div>,
						)}
					</div> : null}
				</div>
			</div>
		</div>
	</div>;
};
