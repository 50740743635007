import { SearchProduct } from '../catalog';

/** Товар в корзине */
export type CartItem = SearchProduct & {
	/** id */
	id?: string;

	/** Количество товаров в корзине */
	count: number;

	/** Общая стоимость товара в корзине */
	priceTotal?: number;

	/** Общая маржа товара в корзине */
	priceMargin?: number;
};

/** Корзина */
export type Cart = {
	/** Товары в корзине */
	products: CartItem[];

	/** Общая стоимость в корзине */
	priceTotal: number;
	
	/** Общая маржа в корзине */
	priceMargin: number;
}

export const emptyCart: Cart = {
	priceTotal: 0,
	priceMargin: 0,
	products: [],
};
