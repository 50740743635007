import React, { useImperativeHandle, useRef } from 'react';
import { Address, AddressUtils } from '../models/address';
import { Geometries } from '../models/geometry/geometries.model';
import { useScript } from '../hooks/useScript';

declare const ymaps: any;

const mapOptions = {
	behaviors: ['drag', 'dblClickZoom', 'multiTouch'],
	center: [55.74954, 37.621587],
	controls: ['zoomControl'],
	zoom: 10,
};

type YandexMapProps = {
  address?: Address;
  autoLoad?: boolean;
  onLoad?: () => void;
  onChangeAddress?: (address: Address) => void;
  onChangeGeometry?: (value?: Geometries) => void;
};

export type YandexMapRef = {
  load: (address?: Address) => void;
  pasteAddress: () => Promise<Address | undefined>;
  editPolygon: () => void;
  stopEditPolygon: () => void;
  clearPolygon: () => void;
  initGeometries: (geometries?: Geometries) => void;
  getGeometry: () => Geometries | undefined;
};

export const YandexMap = React.forwardRef<YandexMapRef, YandexMapProps>((props: YandexMapProps, ref) => {
	const { autoLoad = true } = props;

	useScript('https://api-maps.yandex.ru/2.1/?lang=ru_RU', () => {
		if (autoLoad) {
			load();
		}
	});

	const mapRef = useRef<any>(null);

	const lProps = useRef({
		map: undefined as any,
		address: props.address,
		polygon: undefined as any,
	});

	useImperativeHandle(ref, () => ({
		load: (address?: Address) => {
			load(address);
		},
		pasteAddress: async (): Promise<Address | undefined> => {
			return await pasteAddress();
		},
		editPolygon: () => {
			editPolygon();
		},
		stopEditPolygon: () => {
			stopEditPolygon();
		},
		clearPolygon: () => {
			clearPolygon();
		},
		initGeometries: (geometries?: Geometries) => {
			initGeometries(geometries);
		},
		getGeometry: () => {
			return getGeometry();
		},
	}));

	const load = (address?: Address) => {
		if (address) {
			lProps.current.address = address;
		}
		if (!lProps.current.map) {
			ymaps.ready().done(() => {
				lProps.current.map = new ymaps.Map(mapRef.current, mapOptions);
				lProps.current.map.geoObjects.removeAll();
				if (lProps.current.address) {
					initAddress(lProps.current.address);
				} else {
					lProps.current.map.setCenter([55.74954, 37.621587], 10);
				}
				setTimeout(() => {
					if (lProps.current.map) {
						lProps.current.map.container.fitToViewport();
						props.onLoad?.();
					}
				}, 500);
				// lProps.current.map.events.add('click', clickMaps, this);
			});
		}
		if (address) {
			initAddress(address);
		}
	};

	const initAddress = (address?: Address) => {
		if (!lProps.current.map) {
			return;
		}
		if (address) {
			lProps.current.address = address;
		} else {
			return;
		}
		if (!lProps.current.address) {
			return;
		}
		lProps.current.map.setCenter(
			[lProps.current.address?.location?.coordinates.lat ?? mapOptions.center[0], lProps.current.address?.location?.coordinates.lon ?? mapOptions.center[1]],
			14,
		);
		const placeMark = new ymaps.Placemark(
			[lProps.current.address?.location?.coordinates?.lat ?? mapOptions.center[0], lProps.current.address?.location?.coordinates.lon ?? mapOptions.center[1]],
			{
				iconContent: AddressUtils.getAddressString(lProps.current.address),
			},
			{
				iconColor: '#ff0000',
				preset: 'islands#redStretchyIcon',
			},
		);
		try {
			lProps.current.map.geoObjects.removeAll();
			lProps.current.map.geoObjects.add(placeMark);
			props.onChangeAddress?.(lProps.current.address);
			// eslint-disable-next-line no-empty
		} catch { }
	};

	const pasteAddress = async (): Promise<Address | undefined> => {
		lProps.current.address = await AddressUtils.pasteAddress();
		initAddress(lProps.current.address);
		return lProps.current.address;
	};

	const createPolygon = () => {
		if (typeof ymaps === 'undefined' || !lProps.current.map) {
			return;
		}
		if (lProps.current.polygon) {
			lProps.current.map.geoObjects.remove(lProps.current.polygon);
		}
		lProps.current.polygon = new ymaps.Polygon(
			[],
			{},
			{
				editorDrawingCursor: 'crosshair',
			},
		);
		const polygon = lProps.current.polygon;

		// Добавляем многоугольник на карту.
		lProps.current.map.geoObjects.add(polygon);

		// В режиме добавления новых вершин меняем цвет обводки многоугольника.
		// var stateMonitor = new ymaps.Monitor(polygon.editor.state);
		// stateMonitor.add('drawing', function (drawing) {
		//   polygon.options.set('strokeColor', drawing ? '#FF0000' : 'rgba(39, 69, 142, 0.4)');
		//   if (!drawing && props.onChangeGeometry) {
		//     props.onChangeGeometry(getGeometry());
		//   }
		// });
	};

	const editPolygon = () => {
		if (!lProps.current.polygon) {
			createPolygon();
		}
		if (lProps.current.polygon.editor.state?._data?.drawing) {
			return;
		}
		lProps.current.polygon.editor.startDrawing();
		lProps.current.polygon.options.set('strokeColor', '#FF0000');
	};

	const stopEditPolygon = () => {
		const polygon = lProps.current.polygon;
		if (!polygon) {
			return;
		}
		if (!polygon.editor.state?._data?.drawing) {
			return;
		}
		polygon.editor.stopDrawing();
		lProps.current.polygon.options.set('strokeColor', 'rgba(39, 69, 142, 0.4)');
		props.onChangeGeometry?.(getGeometry());
		polygon.editor.stopEditing();
		polygon.editor.stopFraming();
	};

	const clearPolygon = () => {
		createPolygon();
		props.onChangeGeometry?.();
	};

	const getGeometry = (): Geometries | undefined => {
		const polygon = lProps.current.polygon;
		if (!polygon) {
			return;
		}
		const geometries: any[] = [];
		const ymapCoords = polygon.editor.geometry.getCoordinates();
		ymapCoords.forEach((polygonObj) => {
			const coordinates: any[] = [];
			polygonObj.forEach((point) => {
				if (point) {
					coordinates.push(point);
				}
			});
			const coordinate = {
				type: 'Polygon',
				coordinates: [coordinates],
			};
			geometries.push(coordinate);
		});
		return {
			type: 'GeometryCollection',
			geometries,
		};
	};

	const coordsToYmap = (geometries?: Geometries) => {
		const result: any[] = [];
		geometries?.geometries.forEach((objPolygon, poly_i) => {
			objPolygon.coordinates.forEach((coord) => {
				coord.forEach((point, point_i) => {
					if (typeof result[poly_i] == 'undefined') {
						result[poly_i] = [];
					}
					result[poly_i][point_i] = [point[0], point[1]];
				});
			});
		});
		return result;
	};

	const initGeometries = (geometries?: Geometries) => {
		if (!lProps.current.map) {
			return;
		}
		lProps.current.map.geoObjects.removeAll();
		// fit map
		setTimeout(() => {
			if (lProps.current.map) {
				lProps.current.map.container.fitToViewport();
			}
		}, 500);

		if (lProps.current.polygon) {
			lProps.current.map.geoObjects.remove(lProps.current.polygon);
		}
		if (!geometries?.geometries?.length) {
			createPolygon();
			stopEditPolygon();
			lProps.current.map.setCenter([55.74954, 37.621587], 10);
		} else {
			lProps.current.polygon = new ymaps.Polygon(
				coordsToYmap(geometries),
				{},
				{
					editorDrawingCursor: 'crosshair',
				},
			);
			// Добавляем многоугольник на карту.
			lProps.current.map.geoObjects.add(lProps.current.polygon);
			stopEditPolygon();

			// get poly bound for map
			const bounds = lProps.current.polygon.geometry.getBounds();
			lProps.current.map.setBounds(bounds);
		}
	};

	return <div className="map" id="map" ref={mapRef}></div>;
});

YandexMap.displayName = 'YandexMap';
