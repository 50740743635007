/* eslint-disable no-bitwise */

const colors = [
	'rgb(0, 91, 155)',
	'rgb(236, 169, 38)',
	'rgb(43, 161, 65)',
	'rgb(230, 51, 42)',
	'rgb(255,153,0)',
	'rgb(51,0,255)',
	'rgb(255,0,51)',
	'rgb(0,204,0)',
	'rgb(51,204,204)',
	'rgb(204,0,204)',
	'rgb(255,255,0)',
	'rgb(204,102,51)',
	'rgb(0,102,102)',
	'rgb(51, 51, 51)',
];

export class ColorsUtils {
	static randomColor = () => {
		// tslint:disable-next-line: no-bitwise
		return ('#' + ((Math.random() * 0xffffff) << 0).toString(16) + '000000').slice(0, 7);
	};

	static getColorByIndex(index?: number) {
		if (index === null || index === undefined || typeof index !== 'number' || index < 0) {
			console.warn('Invalid argument: index is not defined or less 0');
			return colors[0];
		}
		return colors[index % colors.length];
	}

	static getColorWithAlpha(color?: string, alpha?: number) {
		if (!color || color.length < 4) {
			return;
		}
		if (!alpha || alpha < 0 || alpha > 100) {
			return color;
		}
		return color[0] === '#' ? `${color}${alpha}` : color.replace(/rgb\((\d+),\s?(\d+),\s?(\d+)\)/g, `rgba($1, $2, $3, ${alpha / 100})`);
	}
}
