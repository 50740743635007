import React from 'react';
import { Hint } from './hint.control';
import '../styles/formItemContainer.control.scss';

export type FormItemContainerProps = {
	children: any;
	hint?: string;
	hintReadonly?: boolean;
	hintDisabled?: boolean;
	fillWidth?: boolean;
};

export const FormItemContainer = React.memo((props: FormItemContainerProps) => {
	const p = { ...props };
	delete p.children;

	return (
		<div className="form-item-container">
			<div className="form-item-content">{React.Children.map(props.children, (child) => (child ? React.cloneElement(child, p) : null))}</div>
			{props.hint ? (
				<div className="form-item-hint">
					<Hint text={props.hint} readonly={props.hintReadonly} disabled={props.hintDisabled} />
				</div>
			) : null}
		</div>
	);
});

FormItemContainer.displayName = 'FormItemContainer';
