import { IHttpResult, Pageable, Page } from '@astick/core';
import { SpringUtils } from '@astick/ui';
import { config } from '../config';
import { HttpRepository } from './http.repository';
import { Role } from '../models';

export class RolesRepository {
	static async getRoles(pageable: Pageable): Promise<IHttpResult<Page<Role>>> {
		const p = { ...pageable };
		p.filters = undefined;
		const roleName = pageable.filters?.[0].value;
		const resp = await fetch(`${config.apiUrlAccount}/roles?${SpringUtils.getHttpParams(p)}${roleName ?
			`&filter=${roleName}` : ''}`, HttpRepository.getRequestInit({ method: 'get' }));
		const r = await HttpRepository.getResult<Role[]>(resp);
		return {
			...r,
			value: SpringUtils.getPage(r),
		};
	}

	static async createRole(role: Role): Promise<IHttpResult<Role>> {
		const resp = await fetch(`${config.apiUrlAccount}/roles`, HttpRepository.getRequestInit({ method: 'post', body: role }));
		return await HttpRepository.getResult<Role>(resp);
	}

	static async updateRole(role: Role): Promise<IHttpResult<Role>> {
		const resp = await fetch(`${config.apiUrlAccount}/roles`, HttpRepository.getRequestInit({ method: 'put', body: role }));
		return await HttpRepository.getResult<Role>(resp);
	}

	static async deleteRole(roleId: string): Promise<IHttpResult> {
		const resp = await fetch(`${config.apiUrlAccount}/roles?name=${roleId}`, HttpRepository.getRequestInit({ method: 'delete' }));
		return await HttpRepository.getResult(resp);
	}

	static async getUserRoles(userId: string): Promise<IHttpResult<string[]>> {
		const resp = await fetch(`${config.apiUrlAccount}/roles/userRoles?userId=${userId}`, HttpRepository.getRequestInit({ method: 'get' }));
		return await HttpRepository.getResult<string[]>(resp);
	}

	static async setUserRoles(userId: string, roles: string[]): Promise<IHttpResult> {
		const resp = await fetch(`${config.apiUrlAccount}/roles/userRoles`, HttpRepository.getRequestInit({
			method: 'put', body: {
				userId,
				roles,
			},
		}));
		return await HttpRepository.getResult(resp);
	}
};
