// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is_active {
  text-align: center;
}
.is_active div {
  display: inline-block;
  vertical-align: middle;
  width: 40px;
  height: 20px;
}
.is_active .active {
  background-color: green;
}
.is_active .no_active {
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/areas/admin/pages/prices/providersRoles.page.scss"],"names":[],"mappings":"AAAA;EACC,kBAAA;AACD;AACC;EACC,qBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;AACF;AAEC;EACC,uBAAA;AAAF;AAGC;EACC,qBAAA;AADF","sourcesContent":[".is_active {\n\ttext-align: center;\n\n\tdiv {\n\t\tdisplay: inline-block;\n\t\tvertical-align: middle;\n\t\twidth: 40px;\n\t\theight: 20px;\n\t}\n\n\t.active {\n\t\tbackground-color: green;\n\t}\n\n\t.no_active {\n\t\tbackground-color: red;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
