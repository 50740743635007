import {
	extensionsArchive, extensionsAudio, extensionsCrypto, extensionsExcel, extensionsImageGraph, extensionsImageRast, extensionsImageVect,
	extensionsPdf, extensionsText, extensionsVideo, extensionsWord, FileTypes,
} from '../models/file';

export class FilesUtils {
	static getExtensions = (fileTypes: (FileTypes)[]): string[] => {
		if (!fileTypes) {
			return [];
		}
		const getExtensionsByFileType = (fileType: FileTypes | string): string[] => {
			switch (fileType) {
				case 'arh':
					return extensionsArchive;
				case 'audio':
					return extensionsAudio;
				case 'video':
					return extensionsVideo;
				case 'image_rast':
					return extensionsImageRast;
				case 'image_vect':
					return extensionsImageVect;
				case 'image_graph':
					return extensionsImageGraph;
				case 'text':
					return extensionsText;
				case 'pdf':
					return extensionsPdf;
				case 'power_point':
					return extensionsPdf;
				case 'excel':
					return extensionsExcel;
				case 'word':
					return extensionsWord;
				case 'crypto':
					return extensionsCrypto;
				default: {
					const items: string[] = [];
					items.push(fileType);
					return items;
				}
			}
		};
		const types: string[] = [];
		fileTypes.forEach((fileType) => {
			types.push(...getExtensionsByFileType(fileType));
		});
		return types;
	};
}
