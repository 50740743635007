// tslint:disable: no-bitwise
// tslint:disable: ban-comma-operator
// tslint:disable: no-conditional-assignment

const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
export const Base64 = {
	btoa: (input: string = '') => {
		const str = input;
		let output = '';

		// eslint-disable-next-line no-bitwise
		for (let block = 0, charCode, i = 0, map = chars; str.charAt(i | 0) || ((map = '='), i % 1); output += map.charAt(63 & (block >> (8 - (i % 1) * 8)))) {
			charCode = str.charCodeAt((i += 3 / 4));

			if (charCode > 0xff) {
				throw new Error('"btoa" failed: The string to be encoded contains characters outside of the Latin1 range.');
			}

			// eslint-disable-next-line no-bitwise
			block = (block << 8) | charCode;
		}

		return output;
	},

	atob: (input: string = '') => {
		const str = input.replace(/[=]+$/, '');
		let output = '';

		// eslint-disable-next-line eqeqeq
		if (str.length % 4 == 1) {
			throw new Error('"atob" failed: The string to be decoded is not correctly encoded.');
		}
		for (
			let bc = 0, bs = 0, buffer, i = 0;
			(buffer = str.charAt(i++));
		// eslint-disable-next-line no-bitwise
			~buffer && ((bs = bc % 4 ? bs * 64 + buffer : buffer), bc++ % 4) ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6)))) : 0
		) {
			buffer = chars.indexOf(buffer);
		}

		return output;
	},
};
