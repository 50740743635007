/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

/** Класс роли пользователей */
export class Role {
	/** Наименование роли */
	public name!: string;

	/** Описание роли */
	public description?: string;
}

export const roleSchema = yup.object({
	name: yup.string().min(2, 'Минимальная длина роли ${min} буквы.').matches(/^[a-z]+$/i, {
		excludeEmptyString: true,
		message: 'Неверный формат роли',
	}).max(30, 'Максимальная длина роли не должна быть больше 30').required('Необходимо ввести роль'),
	discount: yup.string().max(500, 'Максимальная длина описания не должна быть больше 500'),
});