import { AuthValidations } from '@astick/core';
import * as yup from 'yup';

/** Модель авторизации пользователя */
export type Login = {
	/** Логин */
	login: string;
	
	/** Пароль */
	password: string;
}

export const loginSchema = yup.object({
	login: AuthValidations.login,
	password: AuthValidations.password,
});
  
  
