import * as jwt_decode from 'jwt-decode';
import { IResult } from '@astick/core';
import { SecurityService, Token } from '@astick/security-core';
import { config } from '../config';
import { User } from '../models/user/user.model';
import { AccountRepository } from '../repositories/accounts.repository';
import { RegisterUser } from '../models';

export class AccountService {
	private static isInit = false;
	public static isAuthenticated = false;
	public static currentUser: User | undefined = undefined;

	static async init() {
		if (AccountService.isInit) {
			return;
		}
		AccountService.isInit = true;
		await SecurityService.init({
			securityRepository: AccountRepository,
			tokenRefreshTimeToFinish: config.tokenRefreshTimeToFinish,
			tokenRefreshTryTime: config.tokenRefreshTryTime,
			getTokenStorage: async () => {
				const t = localStorage.getItem('access_token');
				if (!t) {
					return;
				}
				try {
					return JSON.parse(t);
				} catch {
					return;
				}
			},
			setTokenStorage: async (token) => {
				if (token) {
					localStorage.setItem('access_token', JSON.stringify(token));
				} else {
					localStorage.removeItem('access_token');
					this.setCurrentUser();
				}
			},
		});
	}

	private static destroySecurity() {
		AccountService.isInit = false;
		SecurityService.destroy();
		AccountService.setCurrentUser();
	}

	static async registerUser(user: RegisterUser): Promise<IResult<RegisterUser>> {
		return await AccountRepository.registerUser(user);
	};

	static async requestResetPassword(email: string): Promise<IResult> {
		return await AccountRepository.requestResetPassword(email);
	};

	static async resetPassword(email: string, token: string, password: string): Promise<IResult> {
		return await AccountRepository.resetPassword(email, token, password);
	};

	static async requestEmailVerified(email: string): Promise<IResult> {
		return await AccountRepository.requestEmailVerified(email);
	};

	static async verifyEmail(email: string, token: string): Promise<IResult> {
		return await AccountRepository.verifyEmail(email, token);
	};

	static async signIn(userName: string, password: string): Promise<IResult<User>> {
		const formData = new FormData();
		formData.append('login', userName);
		formData.append('password', password);
		const result = await AccountRepository.signIn(userName, password);
		if (!result.success || !result.value?.accessToken) {
			return {
				success: false,
				code: result.code,
				error: result.error,
			};
		}
		const token = result.value;
		SecurityService.initToken(token, true);
		const resultUser = await this.getUserInfo();
		if (!resultUser.success) {
			return resultUser;
		}
		const decoded: any = jwt_decode.jwtDecode(token.accessToken);
		const roles: string[] = decoded.resource_access?.[config.apiClientId]?.roles ?? [];
		const user = new User({
			...resultUser.value!,
			roles,
		});
		Object.assign(user, resultUser.value);
		AccountService.setCurrentUser(user);
		return {
			success: true,
			value: user,
		};
	};

	static signOut() {
		AccountService.destroySecurity();
	};

	static getToken(): Token | undefined {
		return SecurityService.getToken();
	};

	static getCurrentUser(): User | undefined {
		if (!config.authEnabled) {
			const user = new User();
			user.username = 'test';
			return user;
		}

		if (AccountService.currentUser) {
			return AccountService.currentUser;
		}
		const u = localStorage.getItem('user');
		if (!u) {
			return;
		}
		const obj = JSON.parse(u);
		if (!obj) {
			return;
		}
		const user = new User();
		Object.assign(user, obj);
		AccountService.currentUser = user;
		return user;
	};

	static setCurrentUser(user?: User) {
		AccountService.currentUser = user;
		if (!user) {
			SecurityService.clearToken();
			localStorage.removeItem('user');
			AccountService.isAuthenticated = false;
		} else {
			localStorage.setItem('user', JSON.stringify(user));
			AccountService.isAuthenticated = true;
		}
	};

	static async updateUser(user: User): Promise<IResult<User>> {
		return await AccountRepository.updateUser(user);
	}

	static async getUserInfo(): Promise<IResult<User>> {
		return await AccountRepository.getUserInfo();
	}
};

AccountService.init();
