import { IOEventsTarget, IOEventsTypes } from './targets/ioTarget.event';

export class IOEvents {
	private static _ioEventsTarget = new IOEventsTarget();

	static addEventListener = (type: IOEventsTypes, callback: any) => {
		IOEvents._ioEventsTarget.addEventListener(type, callback);
	};

	static removeEventListener = (type: IOEventsTypes, callback: any) => {
		IOEvents._ioEventsTarget.removeEventListener(type, callback);
	};

	static emitTouch = (target: any) => {
		const type = IOEvents._ioEventsTarget.emitTouch(target);
		IOEvents._ioEventsTarget.emitIO(type, target);
	};

	static emitKey = (target: any) => {
		const type = IOEvents._ioEventsTarget.emitKey(target);
		IOEvents._ioEventsTarget.emitIO(type, target);
	};
}
