import { HTMLAttributes, memo } from 'react';
import { useField } from 'formik';
import { Form } from 'react-bootstrap';
import { RangeInput, RangeInputProps } from '../rangeInput.control';

export type RangeInputFormProps = RangeInputProps & HTMLAttributes<any> & {
  label?: string;
  name: string;
};

export const RangeInputForm = memo(
	(props: RangeInputFormProps) => {
		const [f1, meta1, helpers1] = useField(`${props.name}Min`);
		const [f2, meta2, helpers2] = useField(`${props.name}Max`);

		return (
			<div style={{ ...props.style, position: 'relative' }}>
				{props.label ? <div className="label">{props.label}</div> : null}
				<div className={(meta1.touched && !!meta1.error) || (meta2.touched && !!meta2.error) ? 'is-invalid' : 'is-valid'}>
					<RangeInput
						{...props}
						value={{
							value1: f1.value ?? '',
							value2: f2.value ?? '',
						}}
						onChangeValue={(r) => {
							helpers1.setValue(r?.value1 ?? '');
							helpers2.setValue(r?.value2 ?? '');
							helpers1.setTouched(true);
							helpers2.setTouched(true);
							props.onChangeValue?.(r);
						}}
					/>
				</div>
				{(meta1.touched && meta1.error) || (meta2.touched && meta2.error) ? (
					<Form.Control.Feedback type="invalid" tooltip>
						<div>{meta1.error}</div>
						<div>{meta2.error}</div>
					</Form.Control.Feedback>
				) : null}
			</div>
		);
	},
	(prevProps, nextProps) => {
		if (prevProps.name !== nextProps.name) {
			return false;
		}
		return true;
	},
);

RangeInputForm.displayName = 'RangeInputForm';
