import { Address, IResult, MapPosition, MapRegion } from '../models';

export const COORDINATE_MOSCOW: MapRegion = {
	latitude: 55.756246,
	longitude: 37.616364,
	latitudeDelta: 0.015,
	longitudeDelta: 0.0121,
};

export type PermissionStatus = 'granted' | 'denied' | 'blocked';

export interface IMapService {
  getAddressesBySearchText: (searchText: string) => Promise<Address[] | undefined>;
  getAddressBySearchText: (searchText: string) => Promise<Address | undefined>;
  hasLocationPermission: () => Promise<PermissionStatus>;
  getCurrentPosition: () => Promise<IResult<MapPosition>>;
  getAddressFromCoordinates: (pos: MapPosition) => Promise<Address | undefined>;
  getAddressString: (address?: Address) => string;
}
