// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.search-catalogs thead th {
  vertical-align: middle;
  text-align: center;
  font-size: 1rem;
  padding: 8px 10px;
}
.search-catalogs tbody tr:hover {
  cursor: pointer;
  opacity: 0.6;
}
.search-catalogs tbody tr td {
  vertical-align: middle;
  font-size: 1rem;
  padding: 8px 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/search/catalogs.page.scss"],"names":[],"mappings":"AAIE;EACC,sBAAA;EACA,kBAAA;EACA,eAAA;EACA,iBAAA;AAHH;AASG;EACC,eAAA;EACA,YAAA;AAPJ;AAUG;EACC,sBAAA;EACA,eAAA;EACA,iBAAA;AARJ","sourcesContent":["@import \"search\";\n\n.search-catalogs {\n\tthead {\n\t\tth {\n\t\t\tvertical-align: middle;\n\t\t\ttext-align: center;\n\t\t\tfont-size: 1rem;\n\t\t\tpadding: 8px 10px;\n\t\t}\n\t}\n\n\ttbody {\n\t\ttr {\n\t\t\t&:hover {\n\t\t\t\tcursor: pointer;\n\t\t\t\topacity: 0.6;\n\t\t\t}\n\n\t\t\ttd {\n\t\t\t\tvertical-align: middle;\n\t\t\t\tfont-size: 1rem;\n\t\t\t\tpadding: 8px 10px;\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
