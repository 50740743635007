export class UrlUtils {
	static getAllUrlParams(url: string): any {
		if (!url) {
			return {};
		}
		const urlParams: any = {};
		let queryString = url.split('?')[1];
		if (!queryString) {
			if (url.indexOf('=') > -1) {
				queryString = url;
			}
		}
		if (queryString) {
			const keyValuePairs = queryString.split('&');
			for (const keyValue of keyValuePairs) {
				const keyValuePair = keyValue.split('=');
				const paramName = keyValuePair[0];
				const paramValue = keyValuePair[1] || '';
				if (urlParams[paramName]) {
					if (Array.isArray(urlParams[paramName])) {
						urlParams[paramName].push(decodeURIComponent(paramValue.replace(/\+/g, ' ')));
					} else {
						urlParams[paramName] = [urlParams[paramName], decodeURIComponent(paramValue.replace(/\+/g, ' '))];
					}
				} else {
					urlParams[paramName] = decodeURIComponent(paramValue.replace(/\+/g, ' '));
				}
			}
		}
		return urlParams;
	}
	static getRequestParams(urlParams): string {
		let params = '';
		if (!urlParams) {
			return params;
		}
		const getHttpParamsFromObj = (key, value): string => {
			let val = value;
			if (val instanceof Date) {
				const dt = val;
				val = dt.toISOString();
			}
			return `&${key}=${
				typeof val === 'string' && val.indexOf('&&') !== -1
					? val.split('&&').map((l) => {
						const values = l.split('=');
						if (values.length === 2) {
							values[1] = encodeURIComponent(values[1]);
						}
						return values.join('=');
					}).join('&&')
					: encodeURIComponent(val)
			}`;
		};
		Object.keys(urlParams).forEach((key) => {
			const value = urlParams[key];
			if (Array.isArray(value)) {
				for (const item of value) {
					params += typeof item === 'object' ? UrlUtils.getRequestParams(item) : getHttpParamsFromObj(key, item);
				}
				return;
			}
			params += typeof value === 'object' ? UrlUtils.getRequestParams(value) : getHttpParamsFromObj(key, value);
		});
		if (params.length > 0) {
			params = '?' + params.substring(1);
		}
		return params;
	}
}
