/* eslint-disable @typescript-eslint/no-require-imports */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { MdArrowForward, MdAddShoppingCart, MdAccountBalanceWallet, MdDirectionsCar } from 'react-icons/md';
import { useAuth } from 'src/hooks';
import { Manufacture } from '../../models';

export const HomePage = () => {
	const auth = useAuth();
	const user = auth.getUser();
	const navigate = useNavigate();

	const categories = [
		{ id: 100001, name: 'Кузов', type: 'body' },
		{ id: 100002, name: 'Двигатель', type: 'engine' },
		{ id: 100238, name: 'Коробка передач', type: 'box' },
		{ id: 100006, name: 'Тормозная система', type: 'drag' },
		{ id: 100011, name: 'Подвеска / амортизация', type: 'steering' },
		{ id: 100012, name: 'Рулевое управление', type: 'wheel' },
		{ id: 103099, name: 'Колёса / шины', type: 'wheel' },
		{ id: 100010, name: 'Электрика', type: 'electric' },
	];
	const m_Triple = (categories.length / 3) * 3;

	const information = [
		{
			title: 'КАК ОФОРМИТЬ ЗАКАЗ',
			image: <MdAddShoppingCart />,
			text: <>Заказ можно сформировать:<br />
				1. Самостоятельно путем подбора деталей из <a href="/catalog/manufacturers">каталога</a>;<br />
				2. Обратиться к менеджеру через <a href="#" onClick={() => {
					// searchProductsVin();
					return false;
				}}>запрос по vin автомобиля</a>.<br />
				<span style={{ fontWeight: 'bold' }}>Важно! </span>
				Перед обращением необходимо {user ? 'зарегистрироваться' : <a href="/Account/authorization">зарегистрироваться</a>}.<br /><br />
			</>,
			action: 'cart',
		},
		{
			title: 'КАК ОПЛАТИТЬ',
			image: <MdAccountBalanceWallet />,
			text: <>
				После подтверждения заказа менеджером Вам на почту будет отправлено соответствующее уведомление.
				По ссылке из письма Вы будете перенаправлены на платежный шлюз ТОЧКА ПАО БАНКА &quot;ФК ОТКРЫТИЕ&quot;. К оплате принимаются
				<span style={{ fontWeight: 'bold' }}> VISA*</span> и <span style={{ fontWeight: 'bold' }}>MasterCard</span>.<br />
				<div className="main_info_block_info_footnote">
					<span>* Visa Electron не применима для оплаты через интернет, за исключением карт, выпущенных отдельными банками.</span>
					<span>О возможности оплаты картой Visa Electron вам нужно выяснять у банка-эмитента вашей карты.</span>
				</div>
			</>,
			action: 'pay',
		},
		{
			title: 'КАК ПОЛУЧИТЬ ТОВАР',
			image: <MdDirectionsCar />,
			text: <>Передача товара клиенту производится в специализированном пункте выдачи, который вы указали при оформлении заказа.<br />
				Товар можно получить после соответствующего уведомления.<br />
				Статус вашего заказа уточняйте из <a href={user ? '/client/orders' : '/Account/authorization'}>личного кабинета</a>.<br /><br />
			</>,
			action: 'delivery',
		},
	];

	const brands = ['MERCEDES-BENZ', 'AUDI', 'BMW', 'VOLVO', 'VW', 'SKODA', 'OPEL', 'LAND ROVER', 'HUMMER', 'INFINITI', 'LEXUS', 'TOYOTA', 'SUBARU', 'MAZDA',
		'HONDA', 'MITSUBISHI', 'NISSAN', 'HYUNDAI', 'KIA', 'SUZUKI', 'FORD', 'PEUGEOT', 'FERRARI', 'ACURA', 'ALFA ROMEO', 'ASTON MARTIN', 'AVIA',
		'BENTLEY', 'BRILLIANCE', 'BUGATTI', 'BUICK', 'BYD', 'CADILLAC', 'CHERY', 'CHEVROLET', 'CHRYSLER', 'CITROËN',
		'DACIA', 'DAEWOO', 'DAF', 'DAIHATSU', 'DAIMLER', 'DODGE', 'FAW TIANJIN', 'FIAT', 'FORD OTOSAN', 'FORD USA', 'FOTON', 'GAZ', 'GEELY', 'GMC',
		'GREAT WALL', 'HAFEI', 'HAIMA (FAW)', 'HAIMA (ZHENGZHOU)', 'ISUZU', 'IVECO', 'JAGUAR', 'JEEP', 'KAMAZ', 'LADA', 'LAMBORGHINI', 'LANCIA', 'LIFAN',
		'LINCOLN', 'MAN', 'MASERATI', 'MAZ', 'MG', 'MINI', 'MOSKVICH', 'NISSAN DONGFENG', 'PLYMOUTH', 'PONTIAC', 'PORSCHE', 'RENAULT', 'RENAULT TRUCKS',
		'ROLLS-ROYCE', 'ROVER', 'SAAB', 'SCANIA', 'SEAT', 'SMART', 'SSANGYONG', 'TATA', 'UAZ', 'VAUXHALL', 'ZAZ'];

	useEffect(() => {
		document.title = 'Автозапчасти для иномарок и отечественных авто недорого.';
	}, []);

	const catalogOpenCategory = (_catID) => {
		// const id = 'dlg_catalog_open';
		// var dlg = new MdlDialog({
		// 	id: id,
		// 	classes: 'dlg_catalog_open',
		// 	title: 'Выберите автомобиль',
		// 	text: '<div class="dlg_catalog_open_content"></div>',
		// 	negative: { title: "ОТМЕНА" },
		// 	positive: {
		// 		title: "ОК",
		// 		onClick: function (e) {
		// 			var $dlg = $('#' + id);
		// 			if (f_Mdl_Dailog_Valid($dlg)) {
		// 				var elCatTypesModels = document.querySelector('#cat_TypesModelsUser');
		// 				if (elCatTypesModels == null || elCatTypesModels.getAttribute("key") == "new")
		// 					elCatTypesModels = document.querySelector('#cat_TypesModels');
		// 				var typeModelID = elCatTypesModels.getAttribute("key");
		// 				var elTypesModelsAdding = document.getElementById("cat_TypesModelsAdding");
		//     if (elTypesModelsAdding != null && elTypesModelsAdding.checked) {
		//       $.ajax({
		//         type: 'put',
		//         url: '/client/autopark',
		//         data: dlg.f_GetData()
		//       });
		// 				}
		// 				goLink('/catalogs/analogs/products/@TypeCatalog.Cars.GetHashCode()/' + typeModelID + '-' + a_CatID);
		// 			} else {
		// 				return true;
		// 			}
		// 		}
		// 	},
		// 	onLoaded: function () {
		// 		var elDlgContent = document.querySelector('#' + id + ' .dlg_catalog_open_content');
		// 		@if (User.Identity.IsAuthenticated && User.IsInRole("client")) {
		// 			<text>if (m_AutoParkAutos.length > 0) {
		// 			var autoParkAutos = [];
		// 			for (var i in m_AutoParkAutos) {
		// 				autoParkAutos.push(m_AutoParkAutos[i]);
		// 			}
		//   autoParkAutos.push({ autoName: 'Новый', autoTypeModelId: 'new' });
		// 			elDlgContent.innerHTML = ['<div class="cat_SelectAuto">',
		// 				'<div class="mdl-selectfield mdl-selectfield--floating-label" data-read-only="true" data-field-name="autoName" data-field-key="autoTypeModelId">',
		// 					'<input id="cat_TypesModelsUser" class="mdl-selectfield__input" type="text" required />',
		// 					'<label class="mdl-selectfield__label" for="cat_TypesModelsUser">Автомобиль</label>',
		// 					'<ul for="cat_TypesModelsUser"></ul>',
		// 				'</div>'].join('');
		// 			var mdlAutos = elDlgContent.querySelector('.cat_SelectAuto .mdl-selectfield');
		// 			componentHandler.upgradeElement(mdlAutos, "MaterialSelectfield");
		// 			mdlAutos = mdlAutos.MaterialSelectfield;
		// 			mdlAutos.f_SetData(autoParkAutos);
		// 			mdlAutos.f_SetIndex(0);
		// 			for (var i = 0; i @Html.Raw("<") m_AutoParkAutos.length; i++) {
		// 				if (m_AutoParkAutos[i].isDefault) {
		//       mdlAutos.f_SetKey(m_AutoParkAutos[i].autoTypeModelId);
		// 					break;
		// 				}
		// 			}
		// 			var elSelectAuto = null;
		// 			mdlAutos.el_Input.onchange = function () {
		// 				if (this.getAttribute("key") == "new") {
		// 					elSelectAuto = catalogInsertElClient(elDlgContent);
		// 				} else if (elSelectAuto != null) {
		// 					elSelectAuto.remove();
		// 					elSelectAuto = null;
		// 				}
		// 			}
		// 		} else {
		// 			catalogInsertElClient(elDlgContent);
		// 		}
		// 		</text>
		// 		}
		// 		else	{
		// 			@:selectAutoInit(elDlgContent);
		// 						}
		// 	}
		// });
	};

	const more = (_action) => {
		// goLink('/main/info' + action);
	};

	return <>
		<div className="main_blocks main_catalog">
			{categories.map((category, index) => {
				return <div key={index} className={`main_block main_catalog_block${index >= m_Triple ? 'main_catalog_block_none3' : ''}`}
					onClick={() => catalogOpenCategory(category.id)}>
					<div className="main_block_content main_catalog_block_content">
						<div className="main_catalog_block_img" style={{ backgroundImage: `url(${require(`../../images/catalog/category/${category.type}.png`)})` }}></div>
						<div className="main_catalog_block_link">
							<div className="main_catalog_block_link_text">{category.name}</div>
							<div className="main_catalog_block_link_img"><i className="material-icons"><MdArrowForward /></i></div>
						</div>
					</div>
				</div>;
			})}
		</div>
		<div className="main_blocks main_info">
			{information.map((info, index) => {
				return <div key={index} className="main_block main_info_block">
					<div className="main_block_content main_info_block_content">
						<div className="main_info_block_title">
							<div className="main_info_block_title_img"><i className="material-icons">{info.image}</i></div>
							<div className="main_info_block_title_text">{info.title}</div>
						</div>
						<div className="main_info_block_info">
							<div className="main_info_block_info_text">{info.text}</div>
							<div className="main_info_block_info_action">
								<button className="mdl-button mdl-js-button mdl-button--raised mdl-js-ripple-effect mdl-button--accent"
									onClick={() => more(info.action)}>Подробнее</button>
							</div>
						</div>
					</div>
				</div>;
			})}
		</div>
		<div className="main_description">
			<h2 className="main_description_title">Запчасти для автомобилей всех марок</h2>
			<div className="main_description_text">
				Компания «АСТИК», представляет магазин розничной торговли автомобильными деталями.
				Мы предлагаем нашим клиентам обширный каталог аналоговых деталей и автоматический подбор наиболее привлекательных цен и сроков доставки от наших поставщиков.
				Каталог обновляется ежеквартально. Наш менеджер готов подобрать вам детали по vin автомобиля.
				В настоящее время оплата осуществляется только по без. налу, после успешного подтверждения заказа менеджером магазина.
				Клиент забирает товар самостоятельно в специализированном пункте выдачи.
				В перспективе планируется организовать доставку и расчет при получении товара.
			</div>
		</div>
		<div className="main_banners">
			<a className="main_banners_item" target="_blank" href="http://www.miles-auto.com" style={{ backgroundImage: `url(${require('../../images/banners/miles.jpg')})` }} rel="noreferrer"></a>
			<a className="main_banners_item" target="_blank" href="https://masuma.ru" style={{ backgroundImage: `url(${require('../../images/banners/masuma.jpg')})` }} rel="noreferrer"></a>
			<a className="main_banners_item" target="_blank" href="https://www.idemitsu.ru" style={{ backgroundImage: `url(${require('../../images/banners/idemitsu.jpg')})` }} rel="noreferrer"></a>
		</div>
		<div className="main_brands">
			<div className="main_brands_content">
				{
					brands.map((brand, index) => {
						return <div key={index} className="main_brands_block">
							<div className="main_brands_block_img"
								style={{ backgroundImage: `url(${require(`../../images/catalog/brands/${Manufacture.GetSysName(brand)}.png`)})` }}
								onClick={() => navigate('/catalogs/analogs/car/@Manufacture.GetUrl(brands[i])')}></div>
						</div>;
					})
				}
			</div>
		</div>
	</ >;
};
