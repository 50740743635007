/* eslint-disable no-dupe-class-members */
import { Constructor, IHttpRequest, IHttpResult, IHttpResponse } from '../models';
import { HttpMethod } from '../types/httpMethod';

export class HttpUtils {
	static async sendAsync<T = any>(
    method: HttpMethod,
    request: IHttpRequest,
    c?: Constructor<T> | T,
    fetchMethodAsync?: (url: string, options?: IHttpRequest) => Promise<IHttpResponse>,
  ): Promise<IHttpResult<T | undefined>>;
	static async sendAsync<T>(
    method: HttpMethod,
    request: IHttpRequest,
    c: Constructor<T> | T,
    fetchMethodAsync?: (url: string, options?: IHttpRequest) => Promise<IHttpResponse>,
  ): Promise<IHttpResult<T>>;
	static async sendAsync<T = any>(
		method: HttpMethod,
		request: IHttpRequest,
		c?: Constructor<T> | T,
		fetchMethodAsync: (url: string, options?: IHttpRequest) => Promise<IHttpResponse> = fetch,
	): Promise<IHttpResult<T | undefined>> {
		if (!request.headers) {
			request.headers = {};
		}
		if (method === 'GET') {
			let uriFilter = '';
			if (request.body) {
				const keys = Object.keys(request.body);
				const countFilterKey = keys.length;
				keys.forEach((key, index) => {
					const val = request.body[key];
					uriFilter += `${key}=${val}`;
					if (countFilterKey > 1 && index + 1 < countFilterKey) {
						uriFilter += '&';
					}
				});
			}
			const opt = {
				...request,
				method,
				headers: request.headers,
				filename: request.filename,
			};
			delete opt.body;
			return this.fetchAsync(c, uriFilter !== '' ? `${request.url}?${uriFilter}` : request.url, opt, fetchMethodAsync);
		} else {
			if (!request.headers['Content-Type']) {
				request.headers['Content-Type'] = request.body instanceof FormData ? 'multipart/form-data; charset=UTF-8' : 'application/json; charset=utf-8';
			}
			let body: any = request.body;
			if (request.body && !(request.body instanceof FormData) && typeof request.body === 'object') {
				body = JSON.stringify(body);
			}
			return this.fetchAsync(
				c,
				request.url,
				{
					...request,
					method,
					headers: request.headers,
					body: body ?? '',
				},
				fetchMethodAsync,
			);
		}
	}

	static async getAsync<T = any>(request: IHttpRequest, fetchMethodAsync?: (url: string, options?: IHttpRequest) => Promise<IHttpResponse>): Promise<IHttpResult<T | undefined>>;
	static async getAsync<T>(
    request: IHttpRequest,
    c: Constructor<T> | T,
    fetchMethodAsync?: (url: string, options?: IHttpRequest) => Promise<IHttpResponse>,
  ): Promise<IHttpResult<T>>;
	static async getAsync<T = any>(
		request: IHttpRequest,
		c?: Constructor<T> | T,
		fetchMethodAsync: (url: string, options?: IHttpRequest) => Promise<IHttpResponse> = fetch,
	): Promise<IHttpResult<T | undefined>> {
		return await HttpUtils.sendAsync('GET', request, c, fetchMethodAsync);
	}

	static async postAsync<T = any>(
    request: IHttpRequest,
    c?: Constructor<T> | T,
    fetchMethodAsync?: (url: string, options?: IHttpRequest) => Promise<IHttpResponse>,
  ): Promise<IHttpResult<T | undefined>>;
	static async postAsync<T>(
    request: IHttpRequest,
    c: Constructor<T> | T,
    fetchMethodAsync?: (url: string, options?: IHttpRequest) => Promise<IHttpResponse>,
  ): Promise<IHttpResult<T>>;
	static async postAsync<T = any>(
		request: IHttpRequest,
		c?: Constructor<T> | T,
		fetchMethodAsync: (url: string, options?: IHttpRequest) => Promise<IHttpResponse> = fetch,
	): Promise<IHttpResult<T | undefined>> {
		return await HttpUtils.sendAsync('POST', request, c, fetchMethodAsync);
	}

	static async putAsync<T = any>(
    request: IHttpRequest,
    c?: Constructor<T> | T,
    fetchMethodAsync?: (url: string, options?: IHttpRequest) => Promise<IHttpResponse>,
  ): Promise<IHttpResult<T | undefined>>;
	static async putAsync<T>(
    request: IHttpRequest,
    c: Constructor<T> | T,
    fetchMethodAsync?: (url: string, options?: IHttpRequest) => Promise<IHttpResponse>,
  ): Promise<IHttpResult<T>>;
	static async putAsync<T = any>(
		request: IHttpRequest,
		c?: Constructor<T> | T,
		fetchMethodAsync: (url: string, options?: IHttpRequest) => Promise<IHttpResponse> = fetch,
	): Promise<IHttpResult<T | undefined>> {
		return await HttpUtils.sendAsync('PUT', request, c, fetchMethodAsync);
	}

	static async deleteAsync(request: IHttpRequest, fetchMethodAsync?: (url: string, options?: IHttpRequest) => Promise<IHttpResponse>): Promise<IHttpResult<void>> {
		return await HttpUtils.sendAsync('DELETE', request, undefined, fetchMethodAsync);
	}

	private static async fetchAsync<T>(
		c: Constructor<T> | T | undefined,
		url: string,
		options?: IHttpRequest & {
      method: HttpMethod;
      headers: any;
      body?: any;
      filename?: string;
    },
		fetchMethodAsync: (_url: string, _options?: IHttpRequest) => Promise<IHttpResponse> = fetch,
	): Promise<IHttpResult<T>> {
		try {
			const defaultTimeoutInterval = 60000;

			// TODO Нужно применить прерывание запроса
			// const fetchWithTimeout = async (resource, options: IHttpRequest) => {
			//   const { timeoutInterval = defaultTimeoutInterval } = options;

			//   const controller = new AbortController();
			//   const id = setTimeout(() => controller.abort(), timeoutInterval);

			//   const response = await fetchMethodAsync(resource, {
			//     ...options,
			//     signal: controller.signal,
			//   } as any);
			//   clearTimeout(id);
			//   return response;
			// };

			const res = await fetchMethodAsync(url, { url, ...options, timeoutInterval: options?.timeoutInterval ?? defaultTimeoutInterval });
			let text;
			let obj;
			if (options?.filename) {
				obj = await res.blob();
			} else {
				text = await res.text();
				try {
					obj = text && text.length > 0 ? JSON.parse(text) : null;
				} catch {
					obj = text;
				}
			}
			if (res.status >= 200 && res.status < 300 && !obj?.error_code) {
				if (!c || !obj) {
					return {
						success: true,
						response: res,
						code: obj?.code,
						value: obj,
						error: undefined,
					};
				}
				let objT: T;
				if (typeof c === 'function') {
					const constr = c as Constructor<T>;
					objT = constr ? new constr() : (c as T);
				} else {
					objT = c as T;
				}
				if (objT) {
					Object.assign(objT, obj);
				}
				return {
					success: true,
					response: res,
					value: objT,
					error: undefined,
				};
			} else {
				let txt = '';
				if (res.status === 401) {
					txt = 'Запрос не авторизованный';
					console.log('Путь запроса: ', url, '. Запрос: ', options);
					console.warn(txt, res);
				} else if (res.status === 500 && !res.headers?.Server) {
					txt = 'Сервисы на техническом перерыве';
					if (!obj) {
						obj = {};
					}
					obj.code = 9999;
				} else {
					if (obj?.title && obj.text) {
						txt = obj.text;
					} else if (obj?.error) {
						txt = obj.error_description ? obj.error_description : obj.error;
					} else {
						txt = text ? text : 'Не удалось выполнить запрос. Некорректный запрос';
					}
				}
				// console.log('Путь запроса: ', url, '. Запрос: ', options, '. Результат: ', obj);
				return {
					success: false,
					response: res,
					code: obj?.code,
					value: undefined,
					errorTitle: obj?.title,
					error: txt,
				};
			}
		} catch (error) {
			console.warn('Ошибка запроса:', error);
			console.log('Путь запроса: ', url, '. Запрос: ', options, '. Ответ: ', error);
			return {
				success: false,
				response: undefined,
				value: undefined,
				error: 'Не удалось выполнить запрос. Сервер недоступен',
			};
		}
	}
}
