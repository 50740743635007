import { Navigate, useRoutes } from 'react-router-dom';
import { routes } from './routes';
import { useAuth } from 'src/hooks';

export const AdminRoutes = () => {
	const content = useRoutes(routes);
	const auth = useAuth();

	const user = auth.getUser();
	if (!user) {
		return <Navigate to="/" />;
	}

	return content;
};
