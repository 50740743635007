import { IResult } from '@astick/core';
import { Cart, CartItem, Office } from '../models';
import { ShopRepository } from '../repositories/shop.repository';

/** Сервис работы магазина */
export class ShopService {
	private static mainPagePhones = ['Ваши телефоны'];
	private static mainPageSchedule = 'Пн - Пт: с 9.00 до 20.00, Сб - Вс: с 10.00 до 16.00';

	public static getMainPagePhones(office?: Office): string[] {
		return office?.telephones && office.telephones.length > 0 ? office.telephones : this.mainPagePhones;
	}

	public static getMainPageSchedule(office?: Office): string {
		return office?.schedule ?? this.mainPageSchedule;
	}

	public static async getCart(userId?: string): Promise<IResult<Cart>> {
		return await ShopRepository.getCart(userId);
	}

	/** Добавление товара в корзину пользователя */
	public static async addCartItem(cartItem: CartItem, userId?: string): Promise<IResult> {
		return await ShopRepository.addCartItem(cartItem, userId);
	}

	public static async deleteCartItems(cartItems: CartItem[], userId?: string): Promise<IResult> {
		return await ShopRepository.deleteCartItems(cartItems, userId);
	}
}
