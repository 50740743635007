import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSidebar } from '../../hooks/useSidebar';
import SidebarFooter from './SidebarFooter';
import SidebarNav from './SidebarNav';
import { MenuSection } from '../../models';
import { config } from 'src/config';
import { IconMain } from 'src/resources/icons';

const Sidebar = ({ items, showFooter = true }: { items: MenuSection[]; showFooter?: boolean }) => {
	const { isOpen } = useSidebar();

	return (
		<nav className={`sidebar ${!isOpen ? 'collapsed' : ''}`}>
			<div className="sidebar-content">
				<PerfectScrollbar>
					<a className="sidebar-brand" href="/">
						<IconMain type="logo" />
						<span className="align-middle me-3">{config.apiTitle}</span>
					</a>
					<SidebarNav items={items} />
					{!!showFooter && <SidebarFooter />}
				</PerfectScrollbar>
			</div>
		</nav>
	);
};

export default Sidebar;
