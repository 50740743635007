// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.categories-items .catalog-block {
  display: inline-block;
  padding: 1rem;
  background-color: rgba(255, 0, 0, 0.297);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/styles/categories.scss"],"names":[],"mappings":"AACE;EACE,qBAAA;EACA,aAAA;EACA,wCAAA;EACA,eAAA;AAAJ","sourcesContent":[".categories-items {\n  .catalog-block {\n    display: inline-block;\n    padding: 1rem;\n    background-color: rgba(255, 0, 0, 0.297);\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
