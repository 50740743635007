import { User } from '../../models';

export const MainHeaderTop = (props: {
	navigate: (url: string) => void;
	user?: User;
}) => {
	const { user } = props;

	return <div className="main_header_top">
		<div className="main_width">
			<div className="main_menu main_header_top_menu">
				<a href="/catalogs"><span>КАТАЛОГ</span></a><span className="main_header_top_menu_sep">|</span>
				<a href="/main/delivery">ДОСТАВКА И ОПЛАТА</a><span className="main_header_top_menu_sep">|</span>
				<a href="/main/contacts">КОНТАКТЫ</a>
				{user ? <><span className="main_header_top_menu_sep">|</span>
					<a href="/client/orders" target="_blank">ЗАКАЗЫ</a><span className="main_header_top_menu_sep">|</span>
					<a href="/client/finances" target="_blank">ФИНАНСЫ</a><span className="main_header_top_menu_sep">|</span>
					<a href="/client/autopark" target="_blank">ГАРАЖ</a><span className="main_header_top_menu_sep">|</span>
				</> : null
				}
				<div className="site_state">
					<a href="https://metrika.yandex.ru/stat/?id=42577449&amp;from=informer" target="_blank" rel="nofollow noreferrer">
						<img src="https://informer.yandex.ru/informer/42577449/1_0_FFFFFFFF_EFEFEFFF_0_pageviews" style={{ width: 80, height: 15, border: 0 }}
							alt="Яндекс.Метрика" title="Яндекс.Метрика: данные за сегодня (просмотры)" className="ym-advanced-informer" data-cid="42577449" data-lang="ru" />
					</a>
				</div>
			</div>
		</div>
	</div>;
};
