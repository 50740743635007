import { useRef, useState } from 'react';
import { useField } from 'formik';
import { Button, Form, Modal } from 'react-bootstrap';
import { InputFormProps } from './inputForm.control';
import { YandexMap, YandexMapRef } from '../yandexMap.control';
import { Address, AddressUtils } from '../../models/address';

type AddressFormProps = InputFormProps;

export const AddressForm = (props: AddressFormProps) => {
	const mapRef = useRef<YandexMapRef>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [f, meta, helpers] = useField<Address | undefined>(props.name);

	const [show, setShow] = useState(false);
	const [address, setAddress] = useState(meta.value);

	return (
		<div style={{ ...props.style, position: 'relative' }}>
			<Modal
				show={show}
				size="lg"
				onHide={() => {
					setAddress(meta.value);
					mapRef.current?.load(meta.value);
					setShow(false);
				}}
				backdrop="static"
				animation={false}
				className="address_modal"
				backdropClassName="address_modal_backdrop"
			>
				<Modal.Header closeButton>
					<Modal.Title>Выбор адреса</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<YandexMap ref={mapRef} address={address} />
					{props.label ? <div className="label">{props.label}</div> : null}
					<Form.Control value={AddressUtils.getAddressString(address)} readOnly />
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => {
							mapRef.current?.pasteAddress().then((a) => {
								setAddress(a);
							});
						}}
					>
							Вставить адрес
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							helpers.setValue(address);
							setShow(false);
						}}
					>
							Изменить
					</Button>
				</Modal.Footer>
			</Modal>
			{props.label ? <div className="label">{props.label}</div> : null}
			<Form.Control value={AddressUtils.getAddressString(meta.value)} isInvalid={meta.touched && !!meta.error} readOnly onClick={() => setShow(true)} />
			{meta.touched && meta.error ? (
				<Form.Control.Feedback type="invalid" tooltip>
					{meta.error}
				</Form.Control.Feedback>
			) : null}
		</div>
	);
};

AddressForm.displayName = 'AddressForm';
