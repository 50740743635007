import { useCallback, useEffect } from 'react';
import { IResult } from '@astick/core';
import { Table, TableTypeColumn } from '@astick/ui';
import { useLoading } from 'src/hooks';
import { useToast } from '../../../base/hooks';
import { PricesService } from '../../services';
import { Rate, rateSchema } from 'src/models/prices';

export const RatesPage = () => {
	const setLoading = useLoading();
	const toast = useToast();

	const columns: TableTypeColumn[] = [
		{ name: 'id', header: 'ID', maxWidth: 100, defaultVisible: false, creatableHidden: true, editable: false },
		{
			name: 'providerId', path: 'provider.name', header: 'Поставщик', render: (item) => item.data.provider?.name, type: 'dropdown', maxWidth: 150,
			getData: async () => {
				const r = await PricesService.getProviders({
					offset: 0,
					limit: 999,
					sort: [{
						name: 'name',
						direction: 'asc',
					}],
				});
				if (r.success) {
					return r.value?.items.map(item => ({ label: item.name, value: item.id }));
				} else {
					toast.addError('Не удалось получить список поставщиков!');
				}
			},
		},
		{ name: 'sum', header: 'Стоимость', type: 'number', maxWidth: 200, defaultFlex: 1 },
		{ name: 'value', header: 'Ставка', type: 'number', maxWidth: 200, defaultFlex: 1 },
	];

	useEffect(() => {
		document.title = 'Ставки наценок';
	}, []);

	const loadRates = useCallback(async (pageable) => {
		const r = await PricesService.getRates(pageable);
		if (r.success) {
			return r.value;
		} else {
			toast.addError('Не удалось получить список наценок!');
		}
	}, []);

	return <Table
		idProperty="id"
		columns={columns}
		schema={rateSchema}
		sortable
		defaultSortInfo={[{
			name: 'providerId',
			path: 'provider.name',
			dir: 1,
		}]}
		filterValue={[{
			name: 'providerId',
			operator: 'eq',
			type: 'string',
		}, {
			name: 'sum',
			operator: 'eq',
			type: 'number',
		}, {
			name: 'value',
			operator: 'eq',
			type: 'number',
		}]}
		creatable
		editable
		deletable
		pagination
		showColumnMenuTool
		load={loadRates}
		onSubmit={async (operation, newData: Rate, initData: Rate) => {
			setLoading(true);
			let r: IResult | undefined;
			switch (operation) {
				case 'create':
					r = await PricesService.createRate(newData);
					if (!r.success) {
						toast.addError(`Не удалось добавить новую ставку.${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess('Успешно добавлена новая ставка');
					}
					break;
				case 'edit':
					r = await PricesService.updateRate(newData);
					if (!r.success) {
						toast.addError(`Не удалось изменить ставку.${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess('Успешно изменена ставка');
					}
					break;
				case 'delete':
					r = await PricesService.deleteRate(initData.id);
					if (!r.success) {
						toast.addError(`Не удалось удалить ставку.${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess('Успешно удалена ставка');
					}
					break;
				default:
					r = {
						success: false,
					};
			}
			setLoading(false);
			return r;
		}}
	/>;
};
