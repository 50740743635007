import { Outlet } from 'react-router-dom';
import { UIProvider as UIProviderBase } from '@astick/ui';
import { ThemeProvider } from '../contexts/ThemeContext';
import { SidebarProvider } from '../contexts/SidebarContext';
import { LayoutProvider } from '../contexts/LayoutContext';

import Wrapper from '../components/Wrapper';
import Sidebar from '../components/sidebar/Sidebar';
import Main from '../components/Main';
import Navbar from '../components/navbar/Navbar';
import Content from '../components/Content';
import Footer from '../components/Footer';
import Settings from '../components/Settings';


import '../assets/scss/app.scss';
import { MenuSection } from '../models';
import { ToastProvider } from '../contexts/ToastContext';
import { useTheme } from '../hooks';

const UIProvider = ({ children }: { children: any }) => {
	const theme = useTheme();
	
	return <UIProviderBase theme={theme.theme}>
		{children}
	</UIProviderBase>;
};

export const BaseLayout = ({ menuItems }: { menuItems: MenuSection[] }) => (
	<ThemeProvider>
		<UIProvider>
			<ToastProvider>
				<SidebarProvider>
					<LayoutProvider>
						<Wrapper>
							<Sidebar items={menuItems} />
							<Main>
								<Navbar />
								<Content>
									<Outlet />
								</Content>
								<Footer />
							</Main>
						</Wrapper>
						<Settings />
					</LayoutProvider>
				</SidebarProvider>
			</ToastProvider>
		</UIProvider>
	</ThemeProvider>
);