import { forwardRef, useEffect, useRef, useState } from 'react';
import { Select, SelectProps } from './select.control';

export type AutocompleteProps = SelectProps & {
	data?: any[];
	minLengthSearch?: number;
	handleSearch?: (t: string) => Promise<any[] | null | undefined>;
};

type AutocompleteRef = object;

export const Autocomplete = forwardRef<AutocompleteRef, AutocompleteProps>((props, ref) => {
	const { minLengthSearch = 3 } = props;

	const [data, setData] = useState(props.data);
	const [loading, setLoading] = useState(false);

	const lProps = useRef({
		initData: false,
		initValue: false,
	});

	useEffect(() => {
		setData(props.data);
		if (lProps.current.initData && lProps.current.initValue && (props.value === undefined || props.value === null)) {
			props.onChange?.(undefined);
		}
		lProps.current.initData = true;
	}, [props.data]);

	useEffect(() => {
		lProps.current.initValue = true;
	}, [props.value]);

	return (
		<Select
			ref={ref as any}
			{...props}
			className={props.message ? 'is-invalid' : undefined}
			openMenuOnFocus={false}
			openMenuOnClick={false}
			isSearchable={true}
			isLoading={loading}
			options={data ?? []}
			onInputChange={(searchText) => {
				if (props.handleSearch) {
					if (searchText?.length < minLengthSearch) {
						return;
					}
					setLoading(true);
					props.handleSearch(searchText).then((d) => {
						setData(d ?? undefined);
						setLoading(false);
					});
				} else {
					setData(
						props.data?.filter((i) => {
							for (const f in i) {
								if (i[f] && i[f] !== '' && typeof i[f] === 'string' && i[f].toLowerCase().indexOf(searchText.toLowerCase()) !== -1) {
									return true;
								}
							}
							return false;
						}),
					);
				}
			}}
		/>
	);
});

Autocomplete.displayName = 'Autocomplete';