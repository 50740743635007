import React from 'react';
import toast, { ToastBar, Toaster, ToastOptions } from 'react-hot-toast';
import { X } from 'react-feather';

type ToastContextType = {
	addLoading: (message: string) => void;
	addInfo: (message: string) => void;
	addSuccess: (message: string) => void;
	addError: (message: string) => void;
	addToast: (message: string, opts?: ToastOptions) => void;
};

const ToastContext = React.createContext<ToastContextType>({
	addLoading: () => { },
	addInfo: () => { },
	addSuccess: () => { },
	addError: () => { },
	addToast: () => { },
});

const ToastProvider = ({ children }: { children: any }) => (
	<ToastContext.Provider
		value={{
			addLoading: (message: string) => {
				toast.loading(message);
			},
			addInfo: (message: string) => {
				toast(message);
			},
			addSuccess: (message: string) => {
				toast.success(message);
			},
			addError: (message: string) => {
				toast.error(message);
			},
			addToast: (message: string, opts?: ToastOptions) => {
				toast(message, opts);
			},
		}}
	>
		<>
			{children}
			<Toaster toastOptions={{
				duration: 20000,
				style: {
					background: 'var(--bs-quaternary-bg)',
					color: 'var(--bs-emphasis-color)',
				},
			}}>
				{(t) => (
					<ToastBar toast={t}>
						{({ icon, message }) => (
							<>
								{icon}
								{message}
								{t.type !== 'loading' && (
									<X className='clickable' onClick={() => toast.dismiss(t.id)} />
								)}
							</>
						)}
					</ToastBar>
				)}
			</Toaster>
		</>
	</ToastContext.Provider>
);

export { ToastProvider, ToastContext };
