import { forwardRef, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import momentBase from 'moment';
import { DateType } from '../core/textInput';
import { InputRef } from './textInput.control';
import { ControlProps } from './models';
import { Message } from './message.control';
import { Label } from './label.control';
import '../styles/datePicker.control.scss';

export type DatePickerRef = InputRef;

export type DatePickerProps = ControlProps & Omit<DateType, 'ref'> & {
	ref?: React.RefObject<InputRef>;
	/** Установка режима работы с датами в UTC */
	dateIsUtc?: boolean;
};

export const DatePicker = forwardRef<DatePickerRef, DatePickerProps>((props, ref) => {
	const { format = props.mode === 'time' ? 'HH:mm:ss' : 'DD.MM.YYYY', emptyValue = '-', value, dateIsUtc } = props;
	const moment = (...args): momentBase.Moment => {
		return dateIsUtc ? momentBase.utc(...args) : momentBase(...args);
	};

	const minDate = useMemo(() => {
		return props.minDate instanceof Date ?
			props.minDate?.toISOString().slice(0, -14) :
			typeof props.minDate === 'string' ?
				moment(props.minDate, format).format('Y-m-d') :
				undefined;
	}, [format, props.minDate]);
	const maxDate = useMemo(() => {
		return props.maxDate instanceof Date ?
			props.maxDate?.toISOString().slice(0, -14) :
			typeof props.maxDate === 'string' ?
				moment(props.maxDate, format).format('Y-m-d') :
				undefined;
	}, [format, props.maxDate]);

	const date = useMemo(() => {
		const getEmptyValue = () => (props.value === emptyValue ? emptyValue : '');
		if (props.mode === 'time') {
			return {
				dateString: value ?? getEmptyValue(),
			};
		}

		let d: Date | undefined;
		if (!value || value === emptyValue) {
			d = undefined;
		} else if (value instanceof Date) {
			d = value;
		} else {
			d = moment(value, format).toDate();
		}

		return {
			date: d,
			dateString: d ? (Object.prototype.toString.call(d) === '[object Date]' ? moment(d).format('yyyy-MM-DD') : getEmptyValue()) : getEmptyValue(),
		};
	}, [value?.toString()]);

	return (
		<Form.Group className="form-control-date">
			{props.label ? <Label text={props.label} hint={props.hint} hintReadonly={props.hintReadonly} /> : null}
			<Form.Control
				ref={ref}
				type={props.mode ?? 'date'}
				readOnly={props.disabled || !props.editable}
				step={1}
				min={minDate}
				max={maxDate}
				value={date?.dateString ?? ''}
				disabled={props.disabled}
				className={`${!date?.dateString ? 'form-control-date-empty-value' : ''}${props.message ? ' is-invalid' : ''}`}
				onBlur={props.onBlur}
				onChange={(e) => {
					if (e.target.value && props.mode === 'date') {
						const d = moment(e.target.value).toDate();
						if (d instanceof Date && !isNaN(d.getTime())) {
							props.onValueChange?.(d);
						}
						return;
					}
					props.onValueChange?.(e.target.value);
				}}
			/>
			{props.message ? <Message type="error" text={props.message} /> : null}
		</Form.Group>
	);
});

DatePicker.displayName = 'DatePicker';
