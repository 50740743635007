import { IHttpResult } from '@astick/core';
import { HttpRepository } from './http.repository';
import { config } from '../config';
import { Cart, CartItem, emptyCart } from '../models';
import { CartUtils } from 'src/utils';

export class ShopRepository {
	private static getCartStorage(): Cart {
		const cartValue = localStorage.getItem('cart');
		if (!cartValue) {
			return emptyCart;
		}
		const cart = JSON.parse(cartValue);
		if (Array.isArray(cart?.products)) {
			return cart;
		}
		return emptyCart;
	}

	static async getCart(userId?: string): Promise<IHttpResult<Cart>> {
		if (userId) {
			const resp = await fetch(`${config.apiUrlAccount}/cart?userId=${userId}`, HttpRepository.getRequestInit({ method: 'get' }));
			return await HttpRepository.getResult(resp);
		}
		return {
			success: true,
			value: ShopRepository.getCartStorage(),
		};
	}

	/** Добавление товара в корзину пользователя */
	static async addCartItem(cartItem: CartItem, userId?: string): Promise<IHttpResult> {
		if (userId) {
			const resp = await fetch(`${config.apiUrlAccount}/cart`, HttpRepository.getRequestInit({ method: 'post', body: {
				userId,
				cartItem,
			} }));
			return await HttpRepository.getResult(resp);
		}
		const cart = ShopRepository.getCartStorage();
		const cartItemFind = cart.products.find(p => CartUtils.equalsCartItem(p, cartItem));
		if (cartItemFind) {
			cartItemFind.count = cartItem.count;
		} else {
			cart.products.push({
				...cartItem,
				id: CartUtils.getCartTempId(cartItem),
			});
		}
		cart.products = cart.products.sort((a, b) => (a.firmName.localeCompare(b.firmName) || a.articleNumber.localeCompare(b.articleNumber)));
		localStorage.setItem('cart', JSON.stringify(cart));
		return {
			success: true,
		};
	}

	static async deleteCartItems(cartItems: CartItem[], userId?: string): Promise<IHttpResult> {
		if (userId) {
			const resp = await fetch(`${config.apiUrlAccount}/cart`, HttpRepository.getRequestInit({ method: 'delete', body: {
				userId,
				items: cartItems.map(i => i.id),
			} }));
			return await HttpRepository.getResult(resp);
		}
		const cart = ShopRepository.getCartStorage();
		cart.products = cart.products.filter(product => {
			if (cartItems.some(p => p.id === product.id)) {
				return false;
			}
			return true;
		});
		localStorage.setItem('cart', JSON.stringify(cart));
		return {
			success: true,
		};
	}
};
