import { IHttpResult, IResult, ObjUtils } from '@astick/core';
import { Token } from '@astick/security-core';
import { config } from '../config';
import { HttpRepository } from './http.repository';
import { User } from '../models/user/user.model';
import { RegisterUser } from '../models';

export class AccountRepository {
	static async registerUser(user: RegisterUser): Promise<IResult<RegisterUser>> {
		const newUser: any = {...user};
		delete newUser.confirmPassword;
		const resp = await fetch(`${config.apiUrlAccount}/auth/register`, HttpRepository.getRequestInit({
			method: 'post',
			body: newUser,
		}));
		return await HttpRepository.getResult(resp);
	};

	static async requestResetPassword(email: string): Promise<IResult> {
		const resp = await fetch(`${config.apiUrlAccount}/auth/requestResetPassword?email=${email}`);
		return await HttpRepository.getResult(resp);
	}

	static async resetPassword(email: string, token: string, password: string): Promise<IResult> {
		const resp = await fetch(`${config.apiUrlAccount}/auth/resetPassword`, HttpRepository.getRequestInit({
			method: 'post', body: {
				email,
				token,
				password,
			},
		}));
		return await HttpRepository.getResult(resp);
	}

	static async requestEmailVerified(email: string): Promise<IResult> {
		const resp = await fetch(`${config.apiUrlAccount}/auth/requestEmailVerified?email=${email}`);
		return await HttpRepository.getResult(resp);
	};

	static async verifyEmail(email: string, token: string): Promise<IResult> {
		const resp = await fetch(`${config.apiUrlAccount}/auth/verifyEmail`, HttpRepository.getRequestInit({
			method: 'post', body: {
				email,
				token,
			},
		}));
		return await HttpRepository.getResult(resp);
	};

	static async signIn(
		userName: string,
		password: string,
	): Promise<IHttpResult<Token>> {
		const resp = await fetch(`${config.apiUrlAccount}/auth`, HttpRepository.getRequestInit({
			method: 'post', body: {
				username: userName,
				password,
			},
		}));
		return await HttpRepository.getResult(resp);
	}
	
	static refreshTokenAsync = async (refreshToken: string): Promise<IHttpResult<Token | undefined>> => {
		const formData = new FormData();
		formData.append('client_id', config.apiClientId);
		// formData.append('client_secret', config.apiClientSecret);
		formData.append('grant_type', 'refresh_token');
		formData.append('refresh_token', refreshToken);
		const resp = await fetch(`${config.apiUrlAccount}/connect/token`, HttpRepository.getRequestInit({ method: 'post', body: formData }));
		const result = await HttpRepository.getResult<Token>(resp);
		if (!result.success) {
			if (result.error === 'invalid_grant') {
				result.error = 'Не удалось обновить токен.';
			}
		}
		return {
			...result,
			value: ObjUtils.normalizeObjKeys(result.value),
		};
	};

	static async updateUser(user: User): Promise<IHttpResult<User>> {
		const resp = await fetch(`${config.apiUrlAccount}/users/update`, HttpRepository.getRequestInit({ method: 'post', body: user }));
		return await HttpRepository.getResult<User>(resp);
	}

	static async getUserInfo(): Promise<IHttpResult<User>> {
		const resp = await fetch(`${config.apiUrlAccount}/users/userInfo`, HttpRepository.getRequestInit());
		return await HttpRepository.getResult<User>(resp);
	}
};
