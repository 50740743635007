import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'src/hooks';
import { Form, InputForm, PageTitle } from '@astick/ui';
import { Button } from 'react-bootstrap';
import { Formik } from 'formik';
import { ResetPassword, resetPasswordSchema } from '../../models';
import { useLoading } from '../../hooks';
import { ErrorWidget } from '../../widgets';
import { AccountService } from '../../services';
import { IconMain } from '../../resources/icons';
import { useState } from 'react';

export const ResetPasswordPage = () => {
	const auth = useAuth();
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const email = searchParams.get('email');
	const token = searchParams.get('token');

	const navigate = useNavigate();
	const setLoading = useLoading();

	const [showPassword, setShowPassword] = useState(false);

	const user = auth.getUser();
	if (user) {
		return <Navigate to="/" />;
	}

	if (!email || !token)
		return <ErrorWidget message="Не верный запрос. 404" />;

	return (
		<>
			<PageTitle title="Сброс пароля" />
			<Formik
				validationSchema={resetPasswordSchema}
				initialValues={{} as ResetPassword}
				onSubmit={async (data, { resetForm: _rm, setSubmitting: _s }) => {
					setLoading(true);
					const r = await AccountService.resetPassword(email, token, data.password);
					setLoading(false);
					if (!r.success) {
						alert(r.code === 400 ? r.error : 'Не удалось отправить запрос на сброс пароля, пожалуйста, повторите попытку');
						return;
					}
					alert('Пароль успешно изменен');
					navigate('/');
				}}
			>
				{({ handleSubmit }) => (
					<div>
						<h4>Сброс пароля. Введите новый пароль для {email}</h4>
						<Form noValidate onSubmit={handleSubmit} className="col-md-5">
							<div className="input-container-with-icon">
								<InputForm name="password" type={showPassword ? 'text' : 'password'} label="Новый пароль" />
								<IconMain type={showPassword ? 'password_hide' : 'password_show'} onClick={() => setShowPassword(!showPassword)} />
							</div>
							<InputForm type={showPassword ? 'text' : 'password'} label="Подтверждение пароля" name="confirmPassword" />
							<Button type="submit">Войти</Button>
						</Form>
					</div>
				)}
			</Formik >
		</>
	);
};
