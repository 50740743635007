import React, { useEffect, useState } from 'react';

export type Themes = 'default' | 'colored' | 'dark' | 'light';

type ThemeContextType = {
	name: Themes;
	setName: (theme: Themes) => void;
};

export const ThemeContext = React.createContext<ThemeContextType>({
	name: 'default',
	setName: () => { },
});

export const ThemeProvider = ({ theme, children }: { theme: Themes; children: any }) => {
	const [name, setName] = useState<Themes>(theme);

	useEffect(() => {
		setName(theme);
	}, [theme]);

	return (
		<ThemeContext.Provider
			value={{
				name,
				setName,
			}}
		>
			{children}
		</ThemeContext.Provider>
	);
};
