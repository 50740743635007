import { Form } from 'react-bootstrap';
import { MessageTypes } from '@astick/core';

type MessageProps = {
	type?: MessageTypes;
	text: string;
};

export const Message = (props: MessageProps) => {
	return <Form.Control.Feedback type={props.type === 'error' || props.type === 'warning' ? 'invalid' : 'valid'}>{props.text}</Form.Control.Feedback>;
};
