import { useEffect, useState } from 'react';
import { AvtoBrand } from '../../models/catalog';
import { useNavigate } from 'react-router-dom';

export const CatalogsPage = () => {
	const navigate = useNavigate();

	const [brands, _setBrands] = useState<AvtoBrand[]>();

	useEffect(() => {
		// CatalogsService.getBrands().then((r) => {
		// 	if (r.success) {
		// 		setBrands(r.value);
		// 	} else {
		// 		toast.addError('Не удалось получить список производителей!');
		// 	}
		// });
	}, []);

	if (!brands) {
		return null;
	}
	if (brands.length === 0) {
		return (<div>Производители отсутствуют</div>);
	}

	return (<div className="brands">
		<h3>Список производителей:</h3>
		{brands.map((brand, index) => (
			<div key={index} className="brand">
				<a onClick={() => navigate(`/catalogs/${brand.sysName}`)}>{brand.name}</a>
			</div>
		))}</div>);
};
