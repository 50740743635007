import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { ComponentEditable, FileInfo, FilesUtils, FileTypes, MessageTypes } from '@astick/core';
import { Message } from './message.control';
import { Label } from './label.control';
import { TextInput } from './textInput.control';
import '../styles/loaderFile.control.scss';

type LoaderFileProps = {
	label?: string;
	hint?: string;
	hintReadonly?: boolean;
	readonly?: boolean;
	disabled?: boolean;
	disabledLoadFile?: boolean;
	disabledLoadImageLibrary?: boolean;
	disabledLoadImageUseCamera?: boolean;
	fileTypes?: FileTypes[];
	type?: 'download' | 'load';
	isDelete?: boolean;
	value?: FileInfo;
	messageType?: MessageTypes;
	maxSize?: number;
	message?: string;
	setLoading?: (loading: boolean) => void;
	onPress?: () => void;
	onClose?: () => void;
	onDownload?: (file: FileInfo) => Promise<void>;
	onChange?: (file?: FileInfo) => Promise<void>;
};

export const LoaderFile = forwardRef<ComponentEditable, LoaderFileProps>((props, ref) => {
	const { type = 'load', isDelete = true } = props;
	const fileInfo = props.value;

	const [loading, setLoading] = useState(false);

	const inputRef = useRef<any>();

	const readOnly = props.disabled || (type === 'download' && !props.value?.id) || (type === 'load' && props.readonly);

	useImperativeHandle(ref, () => ({
		isDisabled: () => !!readOnly,
	}));

	const getMessageType = (): MessageTypes => {
		return props.messageType ?? (props.value ? 'success' : props.message ? 'error' : 'info');
	};

	const messageType = getMessageType();

	const deleteFile = async () => {
		if (window.confirm('Вы действительно хотите удалить файл?')) {
			if (!props.onChange) {
				return;
			}
			props.setLoading?.(true);
			setLoading(true);
			inputRef.current.value = '';
			await props.onChange(undefined);
			props.setLoading?.(false);
			setLoading(false);
		}
	};

	const isDeleteMode = !!fileInfo && isDelete && !props.disabled && (type === 'load' || fileInfo);

	return (
		<Form.Group className="loader-file">
			{props.label ? <Label text={props.label} hint={props.hint} hintReadonly={props.hintReadonly} /> : null}
			<input
				type="file"
				ref={inputRef}
				accept={props.fileTypes ? FilesUtils.getExtensions(props.fileTypes).join(',') : undefined}
				onChange={async (e) => {
					const file = e.target.files?.[0];
					if (!file) {
						return;
					}
					if (props?.onChange) {
						props.setLoading?.(true);
						setLoading(true);
						await props.onChange({
							fileName: file.name,
							mimeType: file.type,
							size: file.size,
							file,
						});
						props.setLoading?.(false);
						setLoading(false);
					}
				}}
				style={{ display: 'none' }}
			/>
			<InputGroup>
				<TextInput
					editable={false}
					value={fileInfo ? `${fileInfo.fileName} - ${fileInfo.size ? (fileInfo.size / 1024).toFixed(2) + ' кб' : ''}` : ''}
					placeholder={!fileInfo && (type === 'download' || props.disabled) ? 'Файл не прикреплен' : ''}
					loading={loading}
					className={`loader-file-input${props.message ? ' is-invalid' : ''}`}
					onClick={async () => {
						if (fileInfo && props.onDownload) {
							setLoading(true);
							await props.onDownload(fileInfo);
							setLoading(false);
						}
					}}
				/>
				<div className="loader-file-button">
					{isDeleteMode ? (
						<Button
							variant="outline-secondary"
							onClick={() => {
								deleteFile();
							}}
						>Удалить файл</Button>
					) : (
						<Button
							disabled={readOnly}
							variant="outline-secondary"
							onClick={async () => {
								props.onPress?.();
								if (type === 'load') {
									inputRef.current.click();
								} else if (type === 'download' && fileInfo && props.onDownload) {
									props.setLoading?.(true);
									setLoading(true);
									await props.onDownload(fileInfo);
									props.setLoading?.(false);
									setLoading(false);
								}
							}}
						>Выберите файл</Button>
					)}
				</div>
			</InputGroup>
			{props.message ? <Message type={messageType} text={props.message} /> : null}
		</Form.Group>
	);
});

LoaderFile.displayName = 'LoaderFile';