export const MainFooter = (props: {
	navigate: (url: string) => void;
	phones: string[];
	schedule: string;
}) => {
	const { navigate, phones, schedule } = props;

	const indexSchedule = schedule.indexOf(', ');

	return <div className="main_footer">
		<div className="main_width">
			<div className="main_footer_content">
				<div className="main_footer_c">© 2024. АСТИК, Чебоксары<br />Все права защищены</div>
				<div className="main_menu main_footer_menu">
					<a onClick={() => navigate('/catalogs')}>КАТАЛОГ</a>
					<a onClick={() => navigate('/main/delivery')}>ДОСТАВКА И ОПЛАТА</a>
					<a onClick={() => navigate('/main/contacts')}>КОНТАКТЫ</a>
				</div>
				<div className="main_footer_contacts">
					<div className="main_footer_contacts_phone">{phones.join(', ')}</div>
					<div className="main_footer_contacts_schedule main_footer_contacts_schedule_desc">{schedule}</div>
				</div>
				<div className="main_footer_contacts phone">
					<div className="main_footer_contacts_phone">{phones.join('<br />')}</div>
					<div className="main_footer_contacts_schedule main_footer_contacts_schedule_desc">
						{schedule.substring(0, indexSchedule)}
						<br />
						{schedule.substring(indexSchedule + 2, schedule.length - indexSchedule - 2)}
					</div>
				</div>
			</div>
		</div>
	</div>;
};
