export const fileTypes = ['default', 'arh', 'audio', 'video', 'image_rast', 'image_vect', 'image_graph', 'text', 'pdf', 'power_point', 'excel', 'word', 'crypto'] as const;
export type FileTypes = typeof fileTypes[number];
export const fileTypesArray: FileTypes[] = fileTypes.map((t) => t);

const stringLitArray = <L extends string>(arr: L[]) => arr;
export const extensionsArchive = stringLitArray([
	'7z', //	Файл архива 7Z	Очень часто используется
	'ace', //	Архив WinAce	Часто используется
	'arj', //	Файл с архивом, сжатый алгоритмом ARJ	Средне используется
	'bin', //	Сжатый файл Macbinary	Средне используется |	Файл обновления AVG	Редко используется
	'cab', //	Архив CAB	Очень часто используется | Установочный файл для Windows Mobile	Часто используется
	'cbr', //	RAR-архив с комиксами	Очень часто используется
	'deb', //	Пакет программного обеспечения Debian	Очень часто используется
	'exe', //	Самораспаковывающийся архив	Очень часто используется
	'gz', //	Архив Gnu Gzip	Очень часто используется
	'gzip', //	Архив Gnu Zip (Gnu Zipped File)	Очень часто используется
	'jar', //	Файл архива Java	Очень часто используется
	'one', //	Заархивированный файл SEMONE	Очень редко используется
	'pak', //	Архив PAK	Средне используется |	Архив карты Sygic	Средне используется |	Самораспаковывающийся файл CrazyTalk	Редко используется
	'pkg', //	Установочный пакет Mac OS X	Часто используется | Установочный пакет Sony PlayStation 3	Часто используется | Скомпилированный пакет установки InstallShield	Редко используется | Установочный пакет BeOS	Редко используется
	'ppt', //	Файл BIFF	Очень редко используется
	'rar', //	Архив RAR	Очень часто используется
	'rpm', //	Файл менеджера установки Linux	Очень часто используется
	'sh', //	Самораспаковывающийся архив Unix	Средне используется
	'sib', //	Файл архива ShipInBottle	Редко используется
	'sis', //	Файл установки Symbian	Часто используется
	'sisx', //	Файл установки Symbian OS	Часто используется
	'sit', //	Архив StuffIt	Средне используется
	'sitx', //	Архив StuffIt X	Очень часто используется
	'spl', //	Файл архива SPLINT	Очень редко используется | Разбитый на части файл	Очень редко используется
	'tar', //	Архив Unix	Очень часто используется
	'tar-gz', //	Сжатый TAR-архив GNU zip	Очень часто используется
	'tgz', //	Сжатый TAR-архив	Часто используется
	'xar', //	Расширенный архивный файл	Средне используется
	'zip', //	Файл архива ZIP	Очень часто используется | Файл темы ObjectBar	Редко используется
	'zipx', //	Расширенный ZIP-архив	Очень часто используется
]);

export const extensionsAudio = stringLitArray([
	'aac', //	Улучшенный кодированный аудио-файл	Редко используется
	'ac3', //	Файл аудио кодека 3	Часто используется
	'aif', //	Аудио-файл в формате AIFF	Очень часто используется
	'aiff', //	Аудио формат AIFF	Средне используется
	'amr', //	Файл адаптивного кодека с переменной скоростью	Часто используется
	'aob', //	Аудио файл DVD	Средне используется
	'ape', //	Сжатый аудио-файл Monkey's	Средне используется
	'asf', //	Музыкальный файл Electronic Arts	Средне используется
	'aud', //	Сжатый аудио-файл для игр	Средне используется |	Компонент плагина WinAmp	Средне используется |	Звуковой формат сжатия потока DTS	Часто используется | Файл NICE Media Player	Редко используется
	'awb', //	Аудио файл AMR-WB	Редко используется
	'bin', //	Формат рингтона Nokia	Редко используется
	'bwg', //	Аудио файл BrainWave Generator	Редко используется
	'cdr', //	Аудио-файл звуковой дорожки на CD	Средне используется
	'flac', //	Аудио-файл в формате FLAC	Часто используется
	'gpx', //	Документ Guitar Pro 6	Средне используется
	'ics', //	Аудио файл диктофона Sony IC	Средне используется
	'iff', //	Универсальный формат Electronic Arts	Очень часто используется
	'm', //	Аудио файл PC-98	Редко используется
	'm3u', //	Файл плейлиста	Очень часто используется
	'm3u8', //	Файл плейлиста в кодировке UTF-8	Часто используется
	'm4a', //	Аудио-файл MPEG-4	Очень часто используется
	'm4b', //	Аудио-файл книги MPEG-4	Часто используется
	'm4p', //	Защищенный аудио-файл iTunes Music Store	Средне используется
	'm4r', //	Файл рингтона iPhone	Часто используется
	'mid', //	Аудио-файл MIDI	Очень часто используется
	'midi', //	Аудио-файл MIDI	Часто используется
	'mod', //	Аудио-модуль Amiga	Часто используется
	'mp3', //	Аудио файл MP3	Очень часто используется | Файл mp3PRO	Средне используется | Аудио файл mp3HD	Средне используется
	'mpa', //	Аудио файл MPEG-2	Очень часто используется
	'mpp', //	Аудиофайл Musepack	Редко используется
	'msc', //	Файл AdLib MSCplay	Редко используется
	'msv', //	Файл звукозаписи Memory Stick	Редко используется
	'mts', //	Сэмпл MadTracker 2	Средне используется
	'nkc', //	Файл Библиотеки Native Instruments Kontakt	Средне используется
	'ogg', //	Аудио файл Ogg Vorbis	Часто используется
	'ps', //	Аудио файл Paul Shields	Очень редко используется
	'ra', //	Аудиофайл Real Audio	Очень часто используется
	'ram', //	Метафайл Real Audio	Часто используется
	'sdf', //	Аудио файл KAWAI Musical Instruments	Редко используется
	'sib', //	Партитура Sibelius	Часто используется
	'sln', //	Файл Asterisk	Редко используется
	'spl', //	Файл Digitrakker	Очень редко используется
	'srt', //	Аудио файл мобильного телефона Siemens C60	Редко используется | Формат рингтона Sagem	Очень редко используется
	'temp', //	Музыкальный файл загрузки Audiogalaxy	Очень редко используется
	'vb', //	Аудио файл Grand Theft Auto	Редко используется
	'wav', //	Цифровой аудио формат	Очень часто используется | Файл DTS-WAV	Редко используется
	'wave', //	Аудио файл WAVE	Средне используется
	'wm', //	Аудио или видео Windows Media	Редко используется
	'wma', //	Аудио файл Windows Media	Очень часто используется
	'wpd', //	Аудио файл обработки SAW Studio	Редко используется
	'xsb', //	Аудио файл XACT	Средне используется
	'xwb', //	Библиотека аудио файлов XACT	Средне используется
]);

export const extensionsVideo = stringLitArray([
	'3g2', //	Файл мультимедиа 3GPP2	Очень часто используется
	'3gp', //	Файл мультимедиа 3GPP	Очень часто используется
	'3gp2', //	Файл мультимедиа 3GPP2	Средне используется
	'3gpp', //	Файл мультимедиа 3GPP	Средне используется
	'3gpp2', //	Файл мультимедиа 3GPP2	Редко используется
	'asf', //	Формат для потокового видео	Очень часто используется
	'asx', //	Ярлык мультимедиа-файла ASF	Очень часто используется
	'avi', //	Видео файл AVI	Очень часто используется
	'bin', //	Бинарный видео файл	Часто используется
	'dat', //	Видео-файл VCD	Часто используется
	'drv', //	Видео файлы	Средне используется
	'f4v', //	Видео файл Flash MP4	Часто используется
	'flv', //	Видео-файл Flash	Очень часто используется
	'gtp', //	Видео файл MultiFS	Очень редко используется
	'h264', //	Видео файл с кодировкой H.264	Средне используется
	'm4v', //	Видео файл iTunes	Часто используется
	'mkv', //	Видео-файл Matroska	Очень часто используется
	'mod', //	Видео файл в формате MPEG-2	Часто используется
	'moov', //	Видео-файл Apple QuickTime	Редко используется
	'mov', //	Видео-файл Apple QuickTime	Очень часто используется
	'mp4', //	Видео файл в формате MPEG-4	Очень часто используется
	'mpeg', //	Видео-файл MPEG	Часто используется
	'mpg', //	Видео-файл MPEG	Очень часто используется
	'mts', //	Видеофайл AVCHD	Часто используется
	'rm', //	Файл Real Media	Очень часто используется
	'rmvb', //	Видео-файл RealMedia с переменным битрейтом	Часто используется
	'spl', //	Файл анимации FutureSplash	Редко используется
	'srt', //	Файл видео субтитров в формате SubRip	Очень часто используется
	'stl', //	Файл субтитров Spruce Technologies	Средне используется
	'swf', //	Flash-анимация	Очень часто используется
	'ts', //	Видео-файл TS	Часто используется
	'vcd', //	Файл видео CD	Редко используется
	'vid', //	Видео файл	Средне используется |	Видео файл SymbOS	Редко используется | Видео файл DepoView	Редко используется
	'vob', //	Видео файл DVD	Очень часто используется
	'webm', //	Видео-файл WebM	Часто используется
	'wm', //	Аудио или видео Windows Media	Редко используется
	'wmv', //	Видео файл Windows Media	Очень часто используется
	'yuv', //	Видео файл YUV	Часто используется
]);

export const extensionsImageRast = stringLitArray([
	'apt', //	Закодированный точечный рисунок	Редко используется
	'bmp', //	Точечный рисунок	Очень часто используется
	'bmp', //	Изображение Award	Редко используется
	'dds', //	Файл поверхностей Direct Draw	Очень часто используется
	'djvu', //	Файл DjVu	Средне используется
	'dng', //	Файл изображений Digital Negative	Очень часто используется
	'gbr', //	Файл кисти GIMP	Средне используется
	'gif', //	Изображение в формате GIF	Очень часто используется
	'gz', //	Изображение GIMP	Редко используется
	'hta', //	Файл предпросмотра изображений Hemera	Редко используется
	'iff', //	Изображение Autodesk Maya	Средне используется
	'iso', //	Графический растровый файл CALS ISO 8613	Редко используется
	'jpeg', //	Изображение в формате JPEG	Часто используется
	'jpg', //	Изображение в формате JPEG	Очень часто используется
	'kdc', //	Файл изображения Kodak	Средне используется
	'mng', //	Изображение в формате MNG	Часто используется
	'msp', //	Изображение Microsoft Paint	Часто используется
	'php', //	Файл слайд-шоу Callisto PhotoParade	Редко используется | Файл проекта Microsoft Picture It!	Средне используется
	'png', //	Изображение в формате PNG	Очень часто используется | Файл изображения Adobe Fireworks	Средне используется
	'pot', //	Изображение фрактала Fractint	Средне используется
	'psd', //	Файл изображения Adobe Photoshop	Очень часто используется
	'pspimage', //	Изображение PaintShop Pro	Очень часто используется
	'scr', //	Файл изображения Sun Raster	Редко используется | Файл изображений ZX Spectrum	Редко используется
	'tga', //	Растровое изображение Truevision TarGA	Очень часто используется
	'thm', //	Файл изображения (эскиз)	Очень часто используется | Эскиз видео-файла	Часто используется
	'tif', //	Изображение TIF	Очень часто используется | Изображение GeoTIFF	Часто используется
	'tiff', //	Изображение TIFF	Часто используется
	'vst', //	Изображение Targa	Редко используется
	'xcf', //	Файл изображения GIMP	Часто используется
	'yuv', //	Кодированный файл изображения YUV	Очень часто используется
]);

export const extensionsImageVect = stringLitArray([
	'ai', //	Файл изображения Adobe Illustrator	Очень часто используется
	'cdd', //	Диаграмма ConceptDraw	Часто используется
	'cdr', //	Векторное изображение CorelDRAW	Часто используется
	'dem', //	Файл формата вышивки Melco	Редко используется
	'eps', //	Инкапсулированный файл PostScript	Очень часто используется
	'max', //	Формат вышивки Pfaff	Средне используется
	'pl', //	Файл цветных графиков Unix	Редко используется
	'ps', //	Файл Adobe PostScript	Очень часто используется
	'svg', //	Файл масштабируемой векторной графики	Часто используется
	'vsd', //	Диаграмма Visio	Часто используется
	'vst', //	Шаблон чертежа Visio	Средне используется
	'wmf', //	Метафайл Windows	Средне используется
	'xar', //	Рисунок Xara Xtreme	Средне используется
]);

export const extensionsImageGraph = stringLitArray([
	'asf', //	Файл шрифта Lotus	Средне используется
	'cdw', //	Файл чертежа CeledyDraw	Редко используется
	'cr2', //	Файл данных изображения Canon	Часто используется
	'cs', //	Файл ColorSchemer Studio	Редко используется
	'cur', //	Курсор изображения или анимации Windows	Очень часто используется
	'dmp', //	Файл карты Dream Maker	Редко используется
	'drv', //	Файл чертежа Traverse PC Desktop	Редко используется
	'icns', //	Формат изображения, используемый для хранения иконок в Mac OS X	Очень часто используется
	'ico', //	Формат изображения, используемый для хранения иконок в Windows	Часто используется
	'max', //	Отсканированный документ PaperPort	Средне используется
	'mds', //	Растровая карта SeeYou	Редко используется
	'mng', //	Файл анимации Corel PaintShop Photo Pro	Редко используется
	'msv', //	Сохраненный файл MyStars!	Очень редко используется
	'odt', //	Объектный файл MindRender Virtual Reality Explorer Kit (VREK)	Очень редко используется
	'ogg', //	Графический файл Origin	Очень редко используется
	'pct', //	Файл изображения Macintosh	Очень часто используется
	'pict', //	Файл изображения Macintosh	Средне используется
	'png', //	Каталог браузера Corel PaintShop Photo Pro	Редко используется
	'pps', //	Файл изображения Corel PaintShop Photo Pro	Средне используется
	'prf', //	Файл изображения Polychrome Recursive Format	Редко используется
	'spl', //	Плоттер файл основного языка управления принтерами HP	Очень редко используется
	'tex', //	Файл текстуры	Средне используется
	'ttf', //	Файл изображения OptiGraphics	Редко используется
	'xps', //	Файл MonkeyJam	Редко используется
]);

export const extensionsText = stringLitArray([
	'apt', //	Почти обычный текстовый файл	Редко используется
	'err', //	Текстовый файл, содержащий сообщения об ошибках	Часто используется | Журнал компиляции ошибок в Microsoft Visual FoxPro	Редко используется
	'log', //	Файл журнала	Очень часто используется
	'pwi', //	Документ Pocket Word	Средне используется
	'rtf', // Rich Text Format
	'sub', //	Файл субтитров	Часто используется
	'ttf', //	Текстовый файл Quartus II	Средне используется
	'tex', //	Документ LaTeX	Средне используется
	'text', //	Текстовый файл	Часто используется
	'txt', //	Текстовый файл	Очень часто используется | Файл субтитров	Средне используется
]);

export const extensionsPdf = stringLitArray(['pdf']);

export const extensionsPowerPoint = stringLitArray([
	'pot', //	Шаблон PowerPoint	Средне используется
	'potm', //	Шаблон Open XML PowerPoint с поддержкой макросов	Средне используется
	'potx', //	Шаблон презентации PowerPoint Open XML	Средне используется
	'pps', //	Слайд-шоу Microsoft PowerPoint 1997—2003	Очень часто используется
	'ppsm', //	Слайд-шоу Microsoft PowerPoint 2007—2013 с поддержкой макросов в формате Open XML	Часто используется
	'ppsx', //	Слайд-шоу Microsoft PowerPoint 2007—2013 в формате Open XML	Часто используется
	'ppt', //	Презентация Microsoft PowerPoint	Очень часто используется
	'pptm', //	Презентация Microsoft PowerPoint 2007—2013 с поддержкой макросов в формате Open XML	Средне используется
	'pptx', //	Презентация Microsoft PowerPoint 2007 в формате Open XML	Очень часто используется
	'sldm', //	Слайд Microsoft PowerPoint с поддержкой макросов Open XML
]);

export const extensionsExcel = stringLitArray([
	'ods', // Электронная таблица OpenOffice
	'xar', //	Файл Excel с авто восстановлением	Средне используется
	'xlr', //	Таблица Microsoft Works	Очень часто используется
	'xls', //	Электронная таблица Microsoft Excel 97-2003	Очень часто используется
	'xlsb', //	Книга Excel в бинарном виде	Часто используется
	'xlsm', //	Лист Excel Open XML с поддержкой макросов	Средне используется
	'xlsx', //	Электронная таблица Microsoft Excel 2007-2010	Очень часто используется
	'xlt', //	Шаблон Microsoft Excel	Средне используется
	'xltm', //	Шаблон листа Excel Open XML с поддержкой макросов	Часто используется
	'xltx', //	Шаблон Microsoft Excel Open XML	Часто используется
]);

export const extensionsWord = stringLitArray([
	'doc', //	Документ Microsoft Word
	'docm', //	Документ Microsoft Word 2007-2013 Open XML с поддержкой макросов	Средне используется
	'docx', //	Документ Open XML Microsoft Word	Очень часто используется
	'dot', //	Шаблон Microsoft Word	Часто используется
	'dotm', //	Шаблон документа Open XML Microsoft Word с поддержкой макросов	Средне используется
	'dotx', //	Шаблон документа Microsoft Word Open XML
	'odt', // Текстовый документ OpenOffice
]);

export const extensionsCrypto = stringLitArray([
	'sig', //	SIG Crypto Signatures
	'sgn', //
	'sign', //
	'p7s', // Почтовое сообщение (е-mail), поддерживающее интеграцию электронной цифровой подписи (ЭЦП) отправителя
]);

export type FileExtensionsArhive = typeof extensionsArchive[number];
export type FileExtensionsAudio = typeof extensionsAudio[number];
export type FileExtensionsVideo = typeof extensionsVideo[number];
export type FileExtensionsImageRast = typeof extensionsImageRast[number];
export type FileExtensionsImageVect = typeof extensionsImageVect[number];
export type FileExtensionsImageGraph = typeof extensionsImageGraph[number];
export type FileExtensionsText = typeof extensionsText[number];
export type FileExtensionsPdf = typeof extensionsPdf[number];
export type FileExtensionsPowerPoint = typeof extensionsPowerPoint[number];
export type FileExtensionsExcel = typeof extensionsExcel[number];
export type FileExtensionsWord = typeof extensionsWord[number];
export type FileExtensionsCrypto = typeof extensionsCrypto[number];

export const isTypeArhive = (x: any): x is FileExtensionsArhive => extensionsArchive.includes(x);
export const isTypeAudio = (x: any): x is FileExtensionsAudio => extensionsAudio.includes(x);
export const isTypeVideo = (x: any): x is FileExtensionsVideo => extensionsVideo.includes(x);
export const isTypeImageRast = (x: any): x is FileExtensionsImageRast => extensionsImageRast.includes(x);
export const isTypeImageVect = (x: any): x is FileExtensionsImageVect => extensionsImageVect.includes(x);
export const isTypeImageGraph = (x: any): x is FileExtensionsImageGraph => extensionsImageGraph.includes(x);
export const isTypeText = (x: any): x is FileExtensionsText => extensionsText.includes(x);
export const isTypePdf = (x: any): x is FileExtensionsPdf => extensionsPdf.includes(x);
export const isTypePowerPoint = (x: any): x is FileExtensionsPowerPoint => extensionsPowerPoint.includes(x);
export const isTypeExcel = (x: any): x is FileExtensionsExcel => extensionsExcel.includes(x);
export const isTypeWord = (x: any): x is FileExtensionsWord => extensionsWord.includes(x);
export const isTypeCrypto = (x: any): x is FileExtensionsCrypto => extensionsCrypto.includes(x);

export type FileExtensions =
  | FileExtensionsArhive
  | FileExtensionsAudio
  | FileExtensionsVideo
  | FileExtensionsImageRast
  | FileExtensionsImageVect
  | FileExtensionsImageGraph
  | FileExtensionsText
  | FileExtensionsPdf
  | FileExtensionsPowerPoint
  | FileExtensionsExcel
  | FileExtensionsWord
  | FileExtensionsCrypto;

export type FileInfo = {
  id?: string;
  fileName: string;
  mimeType?: string;
  uri?: string;
  size?: number;
  file?: any;
};
