import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Form, Row } from 'react-bootstrap';
import { Formik } from 'formik';
import { InputForm, PageTitle } from '@astick/ui';
import { useAuth, useLoading } from '../../hooks';
import { RegisterUser, registerSchema } from '../../models';
import { IconMain } from '../../resources/icons';
import { useState } from 'react';

export const RegisterPage = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	const setLoading = useLoading();

	const user = auth.getUser();

	const [showPassword, setShowPassword] = useState(false);

	if (user) {
		return <Navigate to="/" />;
	}

	return (
		<>
			<PageTitle title="Регистрация" />
			<Formik
				validationSchema={registerSchema}
				initialValues={{
					lastName: '',
					firstName: '',
					surName: '',
					email: '',
					mobile: '',
					password: '',
					confirmPassword: '',
				} as RegisterUser}
				onSubmit={async (data) => {
					setLoading(true);
					const r = await auth.registerUser(data);
					setLoading(false);
					if (!r.success) {
						alert(r.code === 400 ? r.error : 'Не удалось зарегистрироваться, пожалуйста, повторите попытку');
						return;
					}
					alert(`${data.firstName}, вы успешно успешно зарегистрировались. Для подтверждения вашей электронной почты вам на email "${data.email}" было направлено письмо`);
					navigate('/');
				}}
			>
				{({ handleSubmit, values: _v, isSubmitting: _is, resetForm: _rm }) => (
					<div>
						<h4>Регистрация</h4>
						<Form noValidate onSubmit={handleSubmit}>
							<Row>
								<InputForm className="mb-3 col-md-4" label="Фамилия" name="lastName" />
								<InputForm className="mb-3 col-md-4" label="Имя" name="firstName" />
								<InputForm className="mb-3 col-md-4" label="Отчество" name="surName" />
							</Row>
							<Row>
								<InputForm className="mb-3 col-md-4" label="Email адрес" name="email" />
								<InputForm className="mb-3 col-md-4" label="Мобильный" name="mobile" mask="+7 ___ ___ __ __" placeholder='+7 ___ ___ __ __' />
							</Row>
							<div className="input-container-with-icon mb-3 col-md-4">
								<InputForm type={showPassword ? 'text' : 'password'} label="Новый пароль" name="password" />
								<IconMain type={showPassword ? 'password_hide' : 'password_show'} onClick={() => setShowPassword(!showPassword)} />
							</div>
							<InputForm className="mb-3 col-md-4" type="password" label="Подтверждение пароля" name="confirmPassword" />
							<Button type="submit">Зарегистрировать</Button>
						</Form>
					</div>
				)}
			</Formik >
		</>
	);
};
