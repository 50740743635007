import { useEffect } from 'react';

export const useScript = (url, callback?: () => void) => {
	useEffect(() => {
		const script = document.createElement('script');

		script.src = url;
		script.async = true;
		if (callback) {
			script.onload = callback;
		}

		document.body.appendChild(script);

		return () => {
			document.body.removeChild(script);
		};
	}, [url]);
};
