import { RegisterValidations } from '@astick/core';
import * as yup from 'yup';

/** Модель запроса сброса пароля */
export type RequestResetPassword = {
	/** Email адрес */
	email: string;
}

export const requestResetPasswordSchema = yup.object({
	email: RegisterValidations.email,
});


