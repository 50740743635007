import { Location } from './location.model';
import { Street } from './street.model';

export type Address = {
  id?: number;
  building?: string;
  room?: string;
  street?: Street;
  location?: Location;
};

export const AddressUtils = {
	getAddressString: (address?: Address) => {
		if (!address?.street?.city) {
			return '-';
		}
		const adrString: string[] = [];
		if (address.street.city.region?.country?.name) {
			adrString.push(address.street.city.region.country.name);
		}
		if (address.street.city.region?.name) {
			adrString.push(`г. ${address.street.city.region.name}`);
		}
		adrString.push(address.street.city.name);
		if (address.street.microDistrict) {
			adrString.push(`микрорайон ${address.street.microDistrict}`);
		}
		if (address.street.name) {
			adrString.push(`ул. ${address.street.name}`);
		}
		if (address.building) {
			adrString.push(address.building);
		}
		if (address.room) {
			adrString.push(address.room);
		}
		return adrString.join(', ');
	},
	validAddress: (address: any): boolean => {
		if (!address.lat) {
			alert('Не указана широта');
			return false;
		}
		if (!address.lon) {
			alert('Не указана долгота');
			return false;
		}
		const lat = parseFloat(address.lat);
		if (lat <= 0) {
			alert('Неверное значение широты');
			return false;
		}
		const lon = parseFloat(address.lon);
		if (lon <= 0) {
			alert('Неверное значение долготы');
			return false;
		}
		if (!address.country) {
			alert('Не указана страна');
			return false;
		}
		if (!address.region) {
			alert('Не указан регион');
			return false;
		}
		if (!address.city) {
			alert('Не указан город');
			return false;
		}
		if (!address.street) {
			alert('Не указана улица');
			return false;
		}
		if (!address.building) {
			alert('Не указано строение');
			return false;
		}
		return true;
	},
	getAddress: (address: any): Address | undefined => {
		if (!AddressUtils.validAddress(address)) {
			return undefined;
		}
		return {
			location: {
				type: 'point',
				coordinates: {
					lat: parseFloat(address.lat),
					lon: parseFloat(address.lon),
				},
			},
			street: {
				id: -1,
				name: address.street,
				microDistrict: address.microDistrict,
				city: {
					name: address.city,
					region: {
						name: address.region,
						country: {
							name: address.country,
						},
					},
				},
			},
			building: address.building,
		};
	},
	pasteAddress: async (): Promise<Address | undefined> => {
		try {
			const text = await navigator.clipboard.readText();
			return AddressUtils.getAddress(JSON.parse(text));
		} catch (err) {
			console.error('Something went wrong. Возможно, пользователь не дал разрешение на чтение данных из буфера обмена', err);
			return undefined;
		}
	},
};
