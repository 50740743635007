import { IResult } from '@astick/core';
import { SearchCatalogs, SearchProductBase, SearchProducts } from '../models/catalog';
import { CatalogsRepository } from '../repositories/catalogs.repository';

export class CatalogsService {
	// static async getBrands(): Promise<IResult<AvtoBrand[]>> {
	// 	return CatalogsRepository.getBrands();
	// }
	// static async getModels(brandName: string): Promise<IResult<AvtoModel[]>> {
	// 	return CatalogsRepository.getModels(brandName);
	// }
	// static async getCategories(brandName: string, modelId: string, path?: string[]): Promise<IResult<Category[]>> {
	// 	return CatalogsRepository.getCategories(brandName, modelId, path);
	// }

	static async searchCatalogs(searchValue: string): Promise<IResult<SearchCatalogs>> {
		return CatalogsRepository.searchCatalogs(searchValue);
	}

	static async searchProducts(catalogs: string, catalog: SearchProductBase): Promise<IResult<SearchProducts>> {
		return CatalogsRepository.searchProducts(catalogs, catalog);
	}
};
