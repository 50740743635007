import { CartItem } from 'src/models';

export const CartUtils = {
	equalsCartItem: (cartItem1: CartItem, cartItem2: CartItem): boolean => {
		return cartItem1.firmName === cartItem2.firmName
			&& cartItem1.articleNumber === cartItem2.articleNumber
			&& cartItem1.countInStock === cartItem2.countInStock
			&& cartItem1.countDate === cartItem2.countDate
			&& cartItem1.countMaxDate === cartItem2.countMaxDate
			&& cartItem1.priceUser === cartItem2.priceUser;
	},
	getCartTempId: (cartItem: CartItem): string => {
		return `_${cartItem.firmName}_${cartItem.articleNumber}_${cartItem.countInStock}_${cartItem.countDate}_${cartItem.countMaxDate}_${cartItem.priceUser}`;
	},
};