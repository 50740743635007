import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { InputForm, PageTitle } from '@astick/ui';
import { Login, loginSchema } from '../../models';
import { useAuth, useLoading } from '../../hooks';
import { useState } from 'react';
import { IconMain } from '../../resources/icons';

export const AuthPage = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	const setLoading = useLoading();

	const [showPassword, setShowPassword] = useState(false);

	const user = auth.getUser();
	if (user) {
		return <Navigate to="/" />;
	}

	return <Formik
		validationSchema={loginSchema}
		initialValues={{
			login: '',
			password: '',
			rememberMe: false,
		} as Login}
		onSubmit={async (data, { resetForm: _rm, setSubmitting: _s }) => {
			setLoading(true);
			const r = await auth.signIn(data.login, data.password);
			setLoading(false);
			if (!r.success) {
				alert(r.code === 400 ? r.error : 'Неверный логин или пароль');
				return;
			}
			navigate('/');
		}}
	>
		{({ handleSubmit }) => (
			<>
				<PageTitle title="Авторизация" />
				<h4>Авторизация</h4>
				<Form noValidate onSubmit={handleSubmit} className="col-md-5">
					<InputForm name="login" label="Почта или номер телефона" />
					<div className="input-container-with-icon">
						<InputForm name="password" type={showPassword ? 'text' : 'password'} label="Пароль" />
						<IconMain type={showPassword ? 'password_hide' : 'password_show'} onClick={() => setShowPassword(!showPassword)} />
					</div>
					{/* <InputForm name="rememberMe" type="checkbox" label="Запомнить?"></InputForm> */}
					<div className='space_between'>
						<a href="/account/register">Регистрация</a>
						<a href="/account/requestResetPassword">Забыли пароль?</a>
					</div>
					<div className="buttons">
						<Button type="submit">Войти</Button>
					</div>
				</Form>
			</>
		)}
	</Formik >;
};
