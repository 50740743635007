import { City } from '../city.model';

/** Класс офиса */
export class Office {
	/** офиса */
	public id!: string;

	/** Название офиса */
	public name!: string;

	/** Регион */
	public region!: string;

	/** ID города */
	public cityId!: string;

	/** Город */
	public city?: City;

	/** Адрес */
	public address?: string;

	/** Место нахождения */
	public location?: string;

	/** Телефоны */
	public telephones?: string[];

	/** Почты */
	public emails?: string[];

	/** График работы */
	public schedule?: string;

	/** Цвет */
	public color?: string;
	
	/** Флаг использования */
	public enable!: boolean;
}
