import { forwardRef, useImperativeHandle, useState } from 'react';
import { Spinner } from 'react-bootstrap';

export type LoaderProps = {
	isActive?: boolean;
	content?: any;
};

export type LoaderRef = {
	show(): void;
	hide(): void;
};

export const Loader = forwardRef<LoaderRef, LoaderProps>((props: LoaderProps, ref) => {
	const [show, setShow] = useState(!!props.isActive);

	useImperativeHandle(ref, () => ({
		show: () => {
      	setShow(true);
		},
		hide: () => {
			setShow(false);
		},
	}));

	return show ? (
		<div style={{ zIndex: 9999, position: 'fixed', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.2)', alignItems: 'center' }}>
			<div style={{ position: 'relative', top: '50%', textAlign: 'center' }}>{props.content ?? <Spinner animation="border" variant="primary" />}</div>
		</div>
	) : null;
});

Loader.displayName = 'Loader';
