import React, { useEffect, useState } from 'react';
import { IResult } from '@astick/core';
import { Cart, CartItem, emptyCart } from '../models';
import { useAuth, useLoading, useToast } from '../hooks';
import { ShopService } from '../services';


type CartContextType = Cart & {
	getCart(userId?: string): Promise<IResult<Cart>>;
	addCartItem: (cartItem: CartItem, userId?: string) => Promise<IResult<Cart>>;
	deleteCartItems(cartItems: CartItem[], userId?: string): Promise<IResult>;
};

const defaultValue: CartContextType = {
	...emptyCart,
	getCart: (): Promise<IResult> => {
		return new Promise((resolve) => {
			resolve({
				success: false,
			});
		});
	},
	addCartItem: async (): Promise<IResult> => {
		return new Promise((resolve) => {
			resolve({
				success: false,
			});
		});
	},
	deleteCartItems: async (): Promise<IResult> => {
		return new Promise((resolve) => {
			resolve({
				success: false,
			});
		});
	},
};

const CartContext = React.createContext<CartContextType>(defaultValue);

const CartProvider = ({ children }: { children: any }) => {
	const auth = useAuth();
	const toast = useToast();
	const setLoading = useLoading();

	const getCart = async (userId?: string): Promise<IResult<Cart>> => {
		const r = await ShopService.getCart(userId);
		if (!r.success) {
			toast.addError('Не удалось получить корзину');
			return r;
		}
		r.value!.priceTotal = 0;
		r.value!.priceMargin = 0;
		r.value!.products.forEach(product => {
			product.priceTotal = product.priceUser * product.count;
			product.priceMargin = (product.priceProviderWithNds ?? 0) * 100 * product.count;
			r.value!.priceTotal += product.priceTotal;
			r.value!.priceMargin += product.priceMargin;
		});
		return r;
	};

	const addCartItem = async (cartItem: CartItem, userId?: string): Promise<IResult<Cart>> => {
		setLoading(true);
		const resultAdd = await ShopService.addCartItem(cartItem, userId);
		if (!resultAdd.success) {
			toast.addError('Не удалось добавить товар в корзину');
			setLoading(false);
			return resultAdd;
		}
		const resultGetCart = await getCart(userId);
		if (!resultGetCart.success) {
			setLoading(false);
			return resultGetCart;
		}
		setCart({
			...resultGetCart.value!,
			getCart,
			addCartItem,
			deleteCartItems,
		});
		setLoading(false);
		return resultGetCart;
	};

	const deleteCartItems = async (cartItems: CartItem[], userId?: string): Promise<IResult> => {
		setLoading(true);
		const r = await ShopService.deleteCartItems(cartItems, userId);
		if (!r.success) {
			toast.addError('Не удалось удалить выделенные товары из корзины');
			setLoading(false);
			return r;
		}
		const resultGetCart = await getCart(userId);
		if (!resultGetCart.success) {
			setLoading(false);
			return resultGetCart;
		}
		setCart({
			...resultGetCart.value!,
			getCart,
			addCartItem,
			deleteCartItems,
		});
		setLoading(false);
		return r;
	};

	const [cart, setCart] = useState<CartContextType>({
		...emptyCart,
		getCart,
		addCartItem,
		deleteCartItems,
	});

	useEffect(() => {
		getCart(auth.getUser()?.id).then(r => {
			if (!r.success) {
				return;
			}
			setCart({
				...r.value!,
				getCart,
				addCartItem,
				deleteCartItems,
			});
		});
	}, []);

	return <CartContext.Provider value={cart}>
		{children}
	</CartContext.Provider>;
};

export { CartProvider, CartContext };
