// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader-file .loader-file-input {
  flex: 1 1;
}
.loader-file .loader-file-input input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.loader-file .loader-file-button button {
  min-width: 10rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}`, "",{"version":3,"sources":["webpack://./src/lib_ui/src/styles/loaderFile.control.scss"],"names":[],"mappings":"AACC;EACC,SAAA;AAAF;AAEE;EACC,0BAAA;EACA,6BAAA;AAAH;AAKE;EACC,gBAAA;EACA,yBAAA;EACA,4BAAA;AAHH","sourcesContent":[".loader-file {\n\t.loader-file-input {\n\t\tflex: 1;\n\n\t\tinput {\n\t\t\tborder-top-right-radius: 0;\n\t\t\tborder-bottom-right-radius: 0;\n\t\t}\n\t}\n\n\t.loader-file-button {\n\t\tbutton {\n\t\t\tmin-width: 10rem;\n\t\t\tborder-top-left-radius: 0;\n\t\t\tborder-bottom-left-radius: 0;\n\t\t}\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
