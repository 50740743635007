import lodash from 'lodash';

/** Утилита по работе с объектами */
export const ObjUtils = {
	...lodash,
	normalizeObjKeys: (oldObj: any) => {
		if (!oldObj) {
			return;
		}
		const newObj: any = {};
		Object.keys(oldObj).forEach((key) => {
			const value = oldObj[key];
			const keyWords = key.split('_');
			let newName = keyWords[0];
			for (let i = 1; i < keyWords.length; i++) {
				newName += keyWords[i][0].toUpperCase();
				if (keyWords[i].length > 1) {
					newName += keyWords[i].substring(1);
				}
			}
			newObj[newName] = value;
		});
		return newObj;
	},
	assign: (target, ...sources) => {
		if (!target) {
			return {};
		}
		sources.forEach((source) => {
			if (!source || target === source) {
				return;
			}
			Object.keys(source).forEach((key) => {
				const sVal = source[key];
				if (Array.isArray(sVal)) {
					target[key] = sVal;
				} else if (sVal && typeof sVal === 'object') {
					if (!target[key]) {
						target[key] = {};
					}
					target[key] = Object.keys(sVal).length === 0 ? sVal : ObjUtils.assign(target[key], sVal);
				} else {
					target[key] = sVal;
				}
			});
		});
		return target;
	},
	assignWithClear: (target, ...sources) => {
		Object.keys(target).forEach((k) => {
			delete target[k];
		});
		return ObjUtils.assign(target, ...sources);
	},
	getValue: (obj, path: string) => {
		if (!obj || !path) {
			return;
		}
		if (typeof obj !== 'object') {
			return;
		}
		return path.split('.').reduce((acc, part) => {
			if (!acc) {
				return;
			}
			const indexStr = part.replace(/.+\[(\d)\]$/g, '$1');
			const index = indexStr && indexStr !== '' ? parseInt(indexStr, 10) : undefined;
			if (index !== undefined && index !== null) {
				const f = part.replace(/(.+)\[\d\]$/g, '$1');
				const arr = acc[f];
				if (Array.isArray(arr) && arr.length > index) {
					return arr[index];
				}
			}
			return acc[part];
		}, obj);
	},
	setValue: (obj, path: string, value) => {
		if (!obj || !path) {
			return;
		}
		const fields = path.split('.');
		if (fields.length === 1) {
			obj[path] = value;
			return;
		}
		let r = obj[fields[0]] ?? {};
		if (typeof r !== 'object') {
			r = {};
		}
		obj[fields[0]] = r;
		let lastObj = r;
		for (let i = 1; i < fields.length - 1; i++) {
			const f = fields[i];
			if (!lastObj[f]) {
				lastObj[f] = {};
			}
			lastObj = lastObj[f];
		}
		lastObj[fields[fields.length - 1]] = value;
	},
	isString: (val: any): val is string => {
		return typeof (val?.valueOf()) === 'string';
	},
	isNumber: (val: any): val is number => {
		return typeof (val?.valueOf()) === 'number';
	},
	isArrayBuffer: (val: any): val is ArrayBuffer => {
		return Object.prototype.toString.call(val) === '[object ArrayBuffer]';
	},
	isEqualsArrays: (arr1, arr2) => {
		if (!arr1 && !arr2) {
			return true;
		}
		if ((!arr1 && arr2) || (arr1 && !arr2)) {
			return false;
		}
		if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
			return false;
		}
		return JSON.stringify(arr1) === JSON.stringify(arr2);
	},
	generateUUID: () => {
		let d = new Date().getTime();
		let d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;// Time in microseconds since page-load or 0 if unsupported
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
			let r = Math.random() * 16;// random number between 0 and 16
			if (d > 0) {// Use timestamp until depleted
				// eslint-disable-next-line no-bitwise
				r = (d + r) % 16 | 0;
				d = Math.floor(d / 16);
			} else {// Use microseconds since page-load if supported
				// eslint-disable-next-line no-bitwise
				r = (d2 + r) % 16 | 0;
				d2 = Math.floor(d2 / 16);
			}
			// eslint-disable-next-line no-bitwise
			return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
		});
	},
};
