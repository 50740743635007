import { useCallback, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import NumericEditor from '@inovua/reactdatagrid-community/NumberEditor';
import { PageableUtils } from '@astick/core';
import { Table, TableRef, TableTypeColumn } from '@astick/ui';
import { useAuth, useCart, useToast } from 'src/hooks';
import { CartItem } from 'src/models';

export const CartPage = () => {
	const toast = useToast();
	const auth = useAuth();
	const cart = useCart();

	const tableRef = useRef<TableRef>(null);

	const user = auth.getUser();
	const isManager = !!user?.isManager;

	const columns: TableTypeColumn[] = [

		{ name: 'articleNumber', header: 'Артикул', defaultWidth: 140, editable: false },
		{ name: 'firmName', header: 'Марка', defaultWidth: 140, editable: false },
		{ name: 'name', header: 'Наименование', editable: false, defaultWidth: 300 },
	];
	if (isManager) {
		columns.push(
			{ name: 'providerName', header: 'Поставщик', defaultWidth: 100, editable: false },
			{ name: 'priceProviderWithNDS', header: 'Цена пост., руб.', type: 'number', defaultWidth: 100, editable: false },
		);
	}
	columns.push(
		{ name: 'priceUser', header: 'Цена, руб.', type: 'number', defaultWidth: 100, editable: false },
		{ name: 'countDate', header: 'Кол-во ожид. дней', type: 'number', defaultWidth: 100, editable: false },
		{ name: 'countMinOrder', header: 'Мин. заказ, шт.', type: 'number', defaultWidth: 100, editable: false },
		{
			name: 'count', header: 'Кол-во, шт.', type: 'number', defaultWidth: 100, render: (p) => {
				const max = p.data.countInStock ?? 9999;
				return <NumericEditor {...p} editorProps={{
					inputProps: {
						title: `Максимально ${max} шт.`,
						min: 1,
						max,
					},
				}} />;
			},
		},
		{ name: 'priceTotal', header: 'Сумма, руб.', type: 'number', defaultWidth: 120, editable: false },
	);
	if (isManager) {
		columns.push(
			{ name: 'priceMargin', header: 'Маржа, руб.', type: 'number', defaultWidth: 120, editable: false },
		);
	}

	const [productsSelected, setProductsSelected] = useState<boolean | {
		[p: string]: CartItem
	}>({});

	useEffect(() => {
		document.title = 'Корзина';
	}, []);

	const loadCart = useCallback(async (_pageable) => {
		const r = await cart.getCart(user?.id);
		if (r.success) {
			return PageableUtils.getEmptyPagingResult(r.value!.products);
		} else {
			toast.addError('Не удалось получить корзину!');
		}
	}, []);

	const isSelected = productsSelected === true || Object.keys(productsSelected).length > 0;

	return <div className="form">
		<Table
			ref={tableRef}
			idProperty="id"
			columns={columns}
			deletable
			checkboxColumn
			load={loadCart}
			emptyText="Корзина пустая"
			selected={productsSelected}
			onSelectionChange={(sel) => {
				setProductsSelected(sel.selected ?? {} as any);
			}}
		/>
		<div className="buttons">
			<Button variant="danger" disabled={!isSelected} onClick={async () => {
				if (!window.confirm('Вы действительно хотите удалить выделенные товары из корзины?')) {
					return;
				}
				const selected = productsSelected === true ? tableRef.current?.getTable()?.data as any : Object.values(productsSelected);
				const r = await cart.deleteCartItems(selected, user?.id);
				if (!r.success) {
					return;
				}
				tableRef.current?.getTable()?.deselectAll();
				tableRef.current?.getTable()?.reload();
			}}>Удалить</Button>
			<Button disabled={!isSelected} onClick={async () => {
				if (!user) {
					alert('Для оформления заказа необходимо авторизоваться на сайте');
					return;
				}
				// if (!window.confirm('Вы подтверждаете ?')) {
				// 	return;
				// }
			}} >Оформить</Button>
		</div>
	</div>;
};
