import { useCallback, useEffect } from 'react';
import { IResult } from '@astick/core';
import { Role, roleSchema } from 'src/models';
import { useLoading } from 'src/hooks';
import { UsersService } from '../../services';
import { Table, TableTypeColumn } from '@astick/ui';
import { useToast } from '../../../base/hooks';

export const RolesPage = () => {
	const setLoading = useLoading();
	const toast = useToast();

	const columns: TableTypeColumn[] = [
		{ name: 'name', type: 'text', header: 'Роль', editable: false, minWidth: 120, maxWidth: 200, defaultFlex: 2 },
		{ name: 'description', type: 'textarea', header: 'Описание', minWidth: 250, maxWidth: 1000, defaultFlex: 1 },
	];

	useEffect(() => {
		document.title = 'Роли';
	}, []);

	const loadRoles = useCallback(async (pageable) => {
		const r = await UsersService.getRoles(pageable);
		if (r.success) {
			return r.value;
		} else {
			toast.addError('Не удалось получить список ролей!');
		}
	}, []);

	return <Table
		idProperty="name"
		columns={columns}
		schema={roleSchema}
		filterValue={[{
			name: 'name',
			operator: 'eq',
			type: 'string',
		}]}
		creatable
		editable
		deletable
		pagination
		showColumnMenuTool
		load={loadRoles}
		onSubmit={async (operation, data: Role) => {
			setLoading(true);
			let r: IResult | undefined;
			switch (operation) {
				case 'create':
					r = await UsersService.createRole(data);
					if (!r.success) {
						toast.addError(`Не удалось добавить новую роль "${data.name}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно добавлена новая роль "${data.name}"`);
					}
					break;
				case 'edit':
					r = await UsersService.updateRole(data);
					if (!r.success) {
						toast.addError(`Не удалось изменить роль "${data.name}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно изменена роль "${data.name}"`);
					}
					break;
				case 'delete':
					r = await UsersService.deleteRole(data.name);
					if (!r.success) {
						toast.addError(`Не удалось удалить роль "${data.name}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно удалена роль "${data.name}"`);
					}
					break;
				default:
					r = {
						success: false,
					};
			}
			setLoading(false);
			return r;
		}}
	/>;
};
