// tslint:disable-next-line: ban-types
export const cacheFn = <T extends (...args: any[]) => void>(fn: T, ctx?: any, ignoreKeyFields?: string[]): T => {
	const cache = new Map();
	// tslint:disable-next-line: only-arrow-functions
	return ((...args: any[]) => {
		let key: any;
		try {
			if (ignoreKeyFields && ignoreKeyFields.length > 0) {
				const argsKey: any[] = [];
				args?.forEach((arg) => {
					const argKey = { ...arg };
					ignoreKeyFields.forEach((k) => {
						delete argKey[k];
					});
					argsKey.push(argKey);
				});
				key = JSON.stringify(argsKey);
			} else {
				key = JSON.stringify(args);
			}
		} catch (err) {
			console.warn('CacheFn. json convert error: ', err);
			return fn.apply(ctx, args);
		}
		if (cache.has(key)) {
			return cache.get(key);
		}
		const result = fn.apply(ctx, args);
		cache.set(key, result);
		return result;
	}) as unknown as T;
};
