import { IHttpResult } from '@astick/core';
import { Token } from '../models/token.model';
import { SecurityEvents } from '../events/security.event';

export interface ISecurityRepository {
  refreshTokenAsync(refreshToken: string): Promise<IHttpResult<Token | undefined>>;
}

export interface ISecurityParams {
  securityRepository: ISecurityRepository;
  tokenRefreshTimeToFinish: number;
  tokenRefreshTryTime: number;
  getTokenStorage: () => Promise<Token | undefined>;
  setTokenStorage: (token?: Token) => Promise<void>;
}

export class SecurityService {
	private static securityRepository: ISecurityRepository;
	private static tokenRefreshTimeToFinish: number;
	private static tokenRefreshTryTime: number;
	private static getTokenStorage: () => Promise<Token | undefined>;
	private static setTokenStorage: (token?: Token) => Promise<void>;
	private static isInit = false;
	private static token: Token | undefined;
	private static deviceTrusted = false;
	private static tokenRefreshBlock?: Date;
	private static tokenRefreshSending = false;
	private static timer: any;

	private readonly _storageLastAuthUserId = '@lastAuthUserId';

	static async init(params: ISecurityParams) {
		SecurityService.securityRepository = params.securityRepository;
		SecurityService.tokenRefreshTimeToFinish = params.tokenRefreshTimeToFinish;
		SecurityService.tokenRefreshTryTime = params.tokenRefreshTryTime;
		SecurityService.getTokenStorage = params.getTokenStorage;
		SecurityService.setTokenStorage = params.setTokenStorage;

		if (!SecurityService.isInit) {
			const token = await SecurityService.getTokenStorage();
			if (token?.accessToken) {
				if (typeof token.expiresInDate === 'string') {
					token.expiresInDate = new Date(token.expiresInDate);
				}
				SecurityService.token = token;
				SecurityEvents.emitToken(token);
			}
			SecurityService.isInit = true;
			this.updateToken();
			SecurityService.timer = setInterval(() => this.updateToken(), 10000);
		}
	}

	static destroy() {
		if (SecurityService.isInit) {
			SecurityService.clearToken();
			clearInterval(SecurityService.timer);
			SecurityService.timer = undefined;
			SecurityService.isInit = false;
		}
	}

	static async initToken(token: Token, deviceTrusted: boolean) {
		SecurityService.updateTokenExpiresInDate(token);
		SecurityService.token = token;
		SecurityService.deviceTrusted = deviceTrusted;
		await SecurityService.setTokenStorage(token);
		SecurityEvents.emitToken(token);
	}

	static getToken(): Token | undefined {
		return SecurityService.token?.accessToken ? SecurityService.token : undefined;
	}

	static getDeviceTrusted(): boolean {
		return SecurityService.deviceTrusted;
	}

	static async clearToken() {
		const isClear = !!SecurityService.token;
		SecurityService.token = undefined;
		SecurityService.deviceTrusted = false;
		if (isClear) {
			await SecurityService.setTokenStorage();
			SecurityEvents.emitToken(undefined);
			SecurityEvents.emitClearToken();
		}
	}

	private static updateTokenExpiresInDate(token: Token) {
		if (!token) {
			return;
		}
		const dateNow = new Date();
		token.expiresInDate = new Date(dateNow.getTime() + token.expiresIn * 1000);
	}

	static updateToken() {
		SecurityService.updateTokenAsync().then();
	}

	static async updateTokenAsync() {
		if (SecurityService.tokenRefreshSending) {
			return;
		}
		const token = SecurityService.getToken();
		if (!token) {
			return;
		}
		const dateNow = new Date();
		if (
			new Date(dateNow.getTime() + SecurityService.tokenRefreshTimeToFinish * 1000) >= token.expiresInDate &&
      (!SecurityService.tokenRefreshBlock || dateNow >= SecurityService.tokenRefreshBlock)
		) {
			SecurityService.tokenRefreshSending = true;
			const result = await SecurityService.securityRepository.refreshTokenAsync(token.refreshToken);
			SecurityService.tokenRefreshSending = false;
			if (!result.success || !result.value) {
				if (result.response?.status === 500 && !result.response?.headers?.Server) {
					SecurityService.tokenRefreshBlock = new Date(dateNow.getTime() + SecurityService.tokenRefreshTryTime * 1000);
					console.warn('Сервер аутентификации не доступен');
				} else {
					SecurityService.clearToken();
					SecurityService.tokenRefreshBlock = undefined;
					console.log('Токен очистился');
				}
				return;
			}
			const tokenNew = result.value;
			SecurityService.initToken(tokenNew, SecurityService.getDeviceTrusted());
			SecurityService.tokenRefreshBlock = undefined;
			console.log('Токен обновился');
		}
	}
}
