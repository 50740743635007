import { useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { CheckboxProps } from './checkbox.control';
import { Label } from './label.control';
import { Message } from './message.control';

type CheckBoxListProps = Omit<CheckboxProps, 'onChange' | 'value'> & {
	data: any[];
	values?: any[];
	valueProperty?: string;
	labelProperty?: string;
	onChangeValue?: (val?: { item: any; value: boolean }) => void;
	onChangeValues?: (val?: any[]) => void;
};

export const CheckBoxList = (props: CheckBoxListProps) => {
	const { valueProperty = 'value', labelProperty = 'label' } = props;

	const lProps = useRef({
		initData: false,
		initValue: false,
	});

	useEffect(() => {
		if (lProps.current.initData && lProps.current.initValue) {
			if (!Array.isArray(props.data) || !Array.isArray(props.values)) {
				props.onChangeValues?.();
				return;
			}
			let changed = false;
			const values = props.values?.filter((v) => {
				const includes = props.data?.some((d) => v === d[valueProperty]);
				if (!includes) {
					changed = true;
				}
				return includes;
			});
			if (changed) {
				props.onChangeValues?.(values);
			}
		}
		lProps.current.initData = true;
	}, [props.data]);

	useEffect(() => {
		lProps.current.initValue = true;
	}, [props.values]);

	return props.data && props.data.length > 0 ? (
		<div className="checkbox-list">
			<Form.Group>
				{props.label ? <Label text={props.label} hint={props.hint} hintReadonly={props.hintReadonly} /> : null}
				<div className={props.message ? 'is-invalid' : undefined}>
					{props.data.map((item, index) => {
						const value = props.values?.includes(item[valueProperty]);
						return (
							<div key={index}>
								<Form.Check
									type="checkbox"
									label={item[labelProperty]}
									id={`checkbox-list-item-${item[labelProperty]}${index}`}
									checked={value}
									disabled={props.disabled}
									onChange={(e) => {
										const v = e.target.checked;
										const values = props.values ?? [];
										if (v) {
											values.push(item[valueProperty]);
										} else {
											const i = values.indexOf(item[valueProperty]);
											if (i === -1) {
												return;
											}
											values.splice(i, 1);
										}
										props.onChangeValue?.({
											item,
											value: v,
										});
										props.onChangeValues?.(values);
									}}
								/>
							</div>
						);
					})}
				</div>
				{props.message && props.message !== '' ? <Message type={props.messageType} text={props.message} /> : null}
			</Form.Group>
		</div>
	) : null;
};
