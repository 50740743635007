import { useLocation } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { useLoading } from '../../hooks';
import { useEffect, useState } from 'react';
import { AccountService } from '../../services';
import { ErrorWidget } from '../../widgets';
import { PageTitle } from '@astick/ui';

export const VerifyEmailPage = () => {
	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const email = searchParams.get('email');
	const token = searchParams.get('token');

	const setLoading = useLoading();
	const [isLoading, setIsLoading] = useState(false);
	const [isAlreadyConfirmed, setIsAlreadyConfirmed] = useState(false);
	const [isError, setIsError] = useState(false);
	const [isExpires, setIsExpires] = useState(false);
	const [isExpiresSuccess, setIsExpiresSuccess] = useState(false);

	const requestEmailVerified = async () => {
		const r = await AccountService.requestEmailVerified(email!);
		if (r.code === 400) {
			alert(r.error);
			return;
		}
		if (!r.success) {
			alert('Не удалось отправить запрос на сброс пароля, пожалуйста, повторите попытку');
			return;
		}
		setIsExpiresSuccess(true);
	};

	useEffect(() => {
		if (!email || !token)
			return;
		setLoading(true);
		setIsLoading(true);
		AccountService.verifyEmail(email, token).then(r => {
			setLoading(false);
			setIsLoading(false);
			if (r.success)
				return;
			if (r.code === 401) {
				setIsExpires(true);
				return;
			}
			if (r.code === 402) {
				setIsAlreadyConfirmed(true);
				return;
			}
			setIsError(true);
		});
	}, []);

	if (!email || !token)
		return <ErrorWidget message="Не верный запрос. 404" />;
	if (isExpires) {
		if (isExpiresSuccess)
			return <h4>Вам на электронную почту отправлено письмо для подтверждения вашей почты</h4>;
		return <div>
			<PageTitle title="Подтверждение почты" />
			<h4>Срок ссылки для подтверждения почты истек!</h4>
			<h6>Для подтверждения вашей почты отправьте, пожалуйста, новый запрос на электронную почту <strong>&quot;{email}&quot;</strong></h6>
			<Button onClick={requestEmailVerified}>Отправить</Button>
		</div>;
	}
	return isLoading ? <h4>Идет запрос</h4> : isError ? <ErrorWidget message="Не верный запрос. 500" /> : <h4>Ваша почта{isAlreadyConfirmed ? ' уже' : ''} подтверждена</h4>;
};
