import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { DialogParams } from '../core/dialog';
import { Loader, LoaderRef } from './loader.control';

export type DialogRef = {
	open: (dialog: DialogParams) => void;
	close: () => void;
	setLoading: (loader: boolean) => void;
};

type DialogProps = {
	dialog?: DialogParams;
	children?: any;
};

export const Dialog = forwardRef<DialogRef, DialogProps>((props, ref) => {
	const [dialog, setDialog] = useState<DialogParams>();

	const loadingRef = useRef<LoaderRef>(null);

	useImperativeHandle(ref, () => ({
		open: (d: DialogParams) => {
			setDialog(d);
		},
		close: () => {
			setDialog(undefined);
		},
		setLoading: (loader: boolean) => {
			if (loader) {
				loadingRef.current?.show();
			} else {
				loadingRef.current?.hide();
			}
		},
	}));

	return dialog ? <Modal show={!!dialog} onHide={() => setDialog(undefined)}>
		{dialog.title ? (
			<Modal.Header closeButton>
				<Modal.Title>{dialog.title}</Modal.Title>
			</Modal.Header>
		) : null}
		<Modal.Body>
			<div style={{ maxHeight: '700px', overflowY: 'scroll' }}>
				{dialog.content}
				<Loader ref={loadingRef} />
			</div>
		</Modal.Body>
		{dialog.isOk || dialog.isCancel ? <Modal.Footer>
			{dialog.isOk ? <Button onClick={() => setDialog(undefined)}>Ок</Button> : null}
			{dialog.isCancel ? <Button onClick={() => setDialog(undefined)}>Отмена</Button> : null}
		</Modal.Footer> : null}
	</Modal> : null;
});

Dialog.displayName = 'Dialog';