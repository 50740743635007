import { CSSProperties } from 'react';
import { Form } from 'react-bootstrap';
import { Hint } from './hint.control';
import '../styles/label.control.scss';

type LabelProps = {
	text: string;
	focused?: boolean;
	hint?: string;
	hintReadonly?: boolean;
	hintDisabled?: boolean;
	className?: string;
	style?: CSSProperties;
	onPressHint?: (event) => void;
};

export const Label = (props: LabelProps) => {
	return props.text !== null && props.text !== undefined ? (
		<div className={`label-control${props.className ? ` ${props.className}` : ''}`}>
			<Form.Label style={props.style}>{props.text}</Form.Label>
			{props.hint ? (
				<Hint
					readonly={props.hintReadonly}
					disabled={props.hintDisabled}
					text={props.hint}
					onClick={(e) => {
						props.onPressHint?.(e);
					}}
				/>
			) : null}
		</div>
	) : null;
};
