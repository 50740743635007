import { useCallback, useEffect } from 'react';
import { IResult } from '@astick/core';
import { Table, TableTypeColumn } from '@astick/ui';
import { useLoading } from 'src/hooks';
import { PricesService, UsersService } from '../../services';
import { Discount, discountSchema } from 'src/models/prices';
import { useToast } from '../../../base/hooks';

export const DiscountsPage = () => {
	const setLoading = useLoading();
	const toast = useToast();

	const columns: TableTypeColumn[] = [
		{
			name: 'roleName', header: 'Роль', type: 'autocomplete', minWidth: 50, defaultFlex: 2, getData: async (searchText) => {
				if (!searchText || searchText.length < 3)
					return;
				const r = await UsersService.getRoles({
					offset: 0,
					limit: 30,
					filters: [
						{ value: searchText },
					],
				});
				if (r.success) {
					return r.value?.items.map(item => ({ label: item.name, value: item.name }));
				} else {
					toast.addError('Не удалось получить список ролей!');
				}
			},
		},
		{ name: 'discount', header: 'Скидка', type: 'number', maxWidth: 200, defaultFlex: 1 },
	];

	useEffect(() => {
		document.title = 'Скидки для ролей';
	}, []);

	const loadDiscounts = useCallback(async (pageable) => {
		const r = await PricesService.getDiscounts(pageable);
		if (r.success) {
			return r.value;
		} else {
			toast.addError('Не удалось получить список скидок!');
		}
	}, []);

	return <Table
		idProperty="id"
		columns={columns}
		schema={discountSchema}
		sortable
		filterValue={[{
			name: 'roleName',
			operator: 'eq',
			type: 'string',
		}, {
			name: 'discount',
			operator: 'eq',
			type: 'number',
		}]}
		creatable
		editable
		deletable
		pagination
		showColumnMenuTool
		load={loadDiscounts}
		onSubmit={async (operation, data: Discount) => {
			setLoading(true);
			let r: IResult | undefined;
			switch (operation) {
				case 'create':
					r = await PricesService.createDiscount(data);
					if (!r.success) {
						toast.addError(`Не удалось добавить новую скидку для роли "${data.roleName}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно добавлена новая скидка для роли "${data.roleName}"`);
					}
					break;
				case 'edit':
					r = await PricesService.updateDiscount(data);
					if (!r.success) {
						toast.addError(`Не удалось изменить скидку для роли "${data.roleName}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно изменена скидка для роли "${data.roleName}"`);
					}
					break;
				case 'delete':
					r = await PricesService.deleteDiscount(data.id);
					if (!r.success) {
						toast.addError(`Не удалось удалить скидку для роли "${data.roleName}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно удалена скидка для роли "${data.roleName}"`);
					}
					break;
				default:
					r = {
						success: false,
					};
			}
			setLoading(false);
			return r;
		}}
	/>;
};
