import { memo, useMemo } from 'react';
import momentBase from 'moment';
import { Period, DateTypes } from '@astick/core';
import { DatePicker, DatePickerProps } from './datePicker.control';
import { Label } from './label.control';
import { Message } from './message.control';
import { ControlProps } from './models';
import '../styles/datePickerRange.control.scss';

export type DatePickerRangeProps = ControlProps & {
	label?: string;
  	hint?: string;
	hintReadonly?: boolean;
	hintDisabled?: boolean;
	value?: Period<Date | string>;
	mode?: 'period' | 'part';
	format?: string;
	resultDateType: DateTypes;
	editable?: boolean;
	clear?: boolean;
	minDate?: Date;
	maxDate?: Date;
	maxRange?: number;
	maxRangeType?: 'year' | 'month' | 'week' | 'day';
	picker1: DatePickerProps;
	picker2: DatePickerProps;
	/** Установка режима работы с датами в UTC */
	dateIsUtc?: boolean;
	onValueChangePicker1?: (value) => void;
	onValueChangePicker2?: (value) => void;
	onValueChange?: (value: Period<any>) => void;
};

const DatePickerRangeControl = (props: DatePickerRangeProps) => {
	const { mode = 'period', format = 'L', maxRangeType = 'month', value: periodProps, label, hint, hintReadonly, hintDisabled, dateIsUtc, ...otherProps } = props;
	const moment = (...args): momentBase.Moment => {
		return dateIsUtc ? momentBase.utc(...args) : momentBase(...args);
	};

	const period = useMemo(() => {
		const getDate = (value) => {
			let d: Date | undefined;
			if (!value) {
				d = undefined;
			} else if (value instanceof Date) {
				d = value;
			} else {
				d = moment(value, format).toDate();
			}
			return d;
		};

		return {
			startDate: getDate(periodProps?.startDate),
			endDate: getDate(periodProps?.endDate),
		};
	}, [JSON.stringify(periodProps)]);

	return (
		<div className="date-picker">
			{label ? <Label text={label} hint={hint} hintReadonly={hintReadonly} hintDisabled={hintDisabled} /> : null}
			<div className={`date-picker-container${props.message ? ' is-invalid' : ''}`}>
				<div className="date-picker-start">
					<DatePicker
						{...otherProps}
						{...otherProps.picker1}
						dateIsUtc={props.dateIsUtc}
						mode={props.picker1?.mode ?? 'date'}
						value={period?.startDate}
						minDate={props.picker1.minDate ?? props.minDate}
						maxDate={mode === 'period' ? (period.endDate ? period.endDate : props.picker1.maxDate ?? props.picker2.maxDate ?? props.maxDate) : props.picker1.maxDate}
						onValueChange={(startDateValue) => {
							const startDate = startDateValue;
							let endDate = period?.endDate;
							if (mode === 'period' && startDate && endDate && startDate > endDate) {
								endDate = startDate;
							}
							const p: Period<any> = { startDate, endDate };
							if (props.maxRange && p.startDate && p.endDate) {
								const ms = moment(p.startDate);
								const me = moment(p.endDate);
								const cl = ms.clone().add(props.maxRange, maxRangeType);
								if (cl.isBefore(me)) {
									p.endDate = cl.toDate();
								} else if (ms.isAfter(me)) {
									p.endDate = startDateValue;
								}
							}
							props.onValueChangePicker1?.(startDateValue);
							props.onValueChange?.(p);
						}}
					/>
				</div>
				<div className="date-picker-splitter" />
				<div className="date-picker-end">
					<DatePicker
						{...otherProps}
						{...otherProps.picker2}
						dateIsUtc={props.dateIsUtc}
						mode={props.picker2?.mode ?? 'date'}
						value={period?.endDate}
						minDate={props.picker2.minDate ?? props.minDate}
						maxDate={props.picker2.maxDate ?? props.maxDate}
						onValueChange={(endDateValue) => {
							let startDate = period?.startDate;
							const endDate = endDateValue;
							if (mode === 'period' && startDate && endDate && startDate > endDate) {
								startDate = endDate;
							}
							const p: Period<any> = { startDate, endDate };
							if (props.maxRange && p.startDate && p.endDate) {
								const ms = moment(p.startDate);
								const me = moment(p.endDate);
								if (ms.clone().add(props.maxRange, maxRangeType).isBefore(me)) {
									p.startDate = me.clone().add(-props.maxRange, maxRangeType).toDate();
								} else if (ms.isAfter(me)) {
									p.startDate = endDateValue;
								}
							}
							props.onValueChangePicker2?.(endDateValue);
							props.onValueChange?.(p);
						}}
					/>
				</div>
			</div>
			{props.message && props.message !== '' ? <Message type={props.messageType} text={props.message} /> : null}
		</div>
	);
};

export const DatePickerRange = memo(DatePickerRangeControl, (prevProps, nextProps) => {
	if (
		prevProps.label !== nextProps.label ||
    prevProps.value !== nextProps.value ||
    prevProps.picker1.label !== nextProps.picker1.label ||
    prevProps.picker1.message !== nextProps.picker1.message ||
    prevProps.picker2.label !== nextProps.picker2.label ||
    prevProps.picker2.message !== nextProps.picker2.message ||
    prevProps.onValueChange !== nextProps.onValueChange
	) {
		return false;
	}
	return true;
}) as typeof DatePickerRangeControl;
