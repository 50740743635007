/* eslint-disable max-len */
export type IconMenuTypes = 'search' | 'exit';

export const IconMenu = (props: { type: IconMenuTypes; size?: number }) => {
	const { size = 24 } = props;
	switch (props.type) {
		case 'search':
			return (
				<svg {...props} width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M12.5001 7.25006C12.5001 10.1751 10.1751 12.5001 7.25006 12.5001C4.32503 12.5001 2 10.1751 2 7.25006C2 4.32503 4.32503 2 7.25006 2C10.1751 2 12.5001 4.32503 12.5001 7.25006Z"
						className="stroke"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path d="M11.2266 11.2246L14.0016 13.9996" className="stroke" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			);
		case 'exit':
			return (
				<svg width="22" height="19" viewBox="0 0 22 19" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M14.7499 5.66663V1.91666C14.7499 1.41666 14.3332 1 13.8332 1H1.91666C1.41666 1 1 1.41666 1 1.91666V16.9165C1 17.4165 1.41666 17.8332 1.91666 17.8332H13.7499C14.2499 17.8332 14.6666 17.4165 14.6666 16.9165V13.1666"
						className="stroke"
						strokeWidth="2"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path d="M17.6641 12.7499L20.9974 9.41656L17.6641 6.08325" className="stroke" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M5.83203 9.41663H20.9986" className="stroke" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
				</svg>
			);
		default:
			return (
				<svg width="24" height="24" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M5 4V1C5 0.734784 5.10536 0.48043 5.29289 0.292893C5.48043 0.105357 5.73478 0 6 0H14C14.2652 0 14.5196 0.105357 14.7071 0.292893C14.8946 0.48043 15 0.734784 15 1V4H19C19.2652 4 19.5196 4.10536 19.7071 4.29289C19.8946 4.48043 20 4.73478 20 5V19C20 19.2652 19.8946 19.5196 19.7071 19.7071C19.5196 19.8946 19.2652 20 19 20H1C0.734784 20 0.48043 19.8946 0.292893 19.7071C0.105357 19.5196 0 19.2652 0 19V5C0 4.73478 0.105357 4.48043 0.292893 4.29289C0.48043 4.10536 0.734784 4 1 4H5ZM2 15V18H18V15H2ZM2 13H18V6H2V13ZM7 2V4H13V2H7ZM9 10H11V12H9V10Z"
						className="fill"
					/>
				</svg>
			);
	}
};
