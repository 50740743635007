import { InputHTMLAttributes } from 'react';
import { Form } from 'react-bootstrap';
import { Hint } from './hint.control';
import { Message } from './message.control';
import { ControlProps } from './models';

export type CheckboxProps = ControlProps & InputHTMLAttributes<any> & {
	hint?: string;
	hintReadonly?: boolean;
	hintDisabled?: boolean;
	onChange?: (value) => void;
};

export const Checkbox = (props: CheckboxProps) => {
	return (
    	<Form.Group>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<div className={!props.disabled ? 'clickable' : undefined}>
					<Form.Check
						{...props}
						id={`cbx_${Math.random()}`}
						type="checkbox"
						isInvalid={!!props.message}
						checked={typeof props.value === 'boolean' ? props.value : undefined}
						className={props.message ? 'is-invalid' : undefined}
						onClick={props.onClick}
						onChange={(e) => {
							props.onChange?.(e.target.checked ?? '');
						}}
					/>
					{props.message ? <Message type="error" text={props.message} /> : null}
				</div>
				{props.hint ? <Hint text={props.hint} readonly={props.hintReadonly} disabled={props.hintDisabled} /> : null}
			</div>
		</Form.Group>
	);
};
