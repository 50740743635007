import { Fragment, useEffect, useRef, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Info, Plus, Minus, ShoppingCart } from 'react-feather';
import { Formik } from 'formik';
import numbro from 'numbro';
import * as yup from 'yup';
import { FormikProvider, InputForm } from '@astick/ui';
import { SearchProduct, SearchProductBase, SearchProductResult, SearchProducts } from '../../models/catalog';
import { CartItem } from 'src/models';
import { CatalogsService } from 'src/services';
import { useAuth, useCart, useLoading, useQuery } from 'src/hooks';
import { CartUtils, SearchUtils } from 'src/utils';
import './products.page.scss';

type SearchProductResultGroupByName = Omit<SearchProductResult, 'products'> & {
	products: { [p: string]: SearchProduct[] };
}

type SearchProductsGroupByName = Omit<SearchProducts, 'products'> & {
	products: SearchProductResultGroupByName[];
}

const CountDate = ({ searchProduct }: { searchProduct: SearchProduct }) => {
	const proc = Math.floor(searchProduct.probabilityExist / 5) * 5;
	let countDate = searchProduct.countDate.toString();
	if (searchProduct.countDate !== searchProduct.countMaxDate) {
		countDate += ' - ' + searchProduct.countMaxDate;
	}
	return <div className="tableProducts_probabilityExist">
		<div>{countDate}</div>
		<div className={`pie-wrapper pie-wrapper--solid progress-${proc}`} title={`Вероятность поставки: ${searchProduct.probabilityExist}%`}>
			<span className="label">{searchProduct.probabilityExist}
				<span className="smaller">%</span>
			</span>
		</div>
	</div>;
};

const SearchProductsByName = ({ searchProducts, isManager, opened, showAddItemCart }: {
	searchProducts: SearchProduct[], isManager: boolean, opened: boolean, showAddItemCart: (item: CartItem) => void
}) => {
	const firstProduct = searchProducts?.[0];

	const [show, setShow] = useState(opened);

	if (!firstProduct) {
		return null;
	}
	return <>
		<tr className="result-group-products" onClick={() => setShow(!show)}>
			<td>{show ? <Minus /> : <Plus />}</td>
			{isManager ? <td>{firstProduct.providerName}</td> : null}
			<td>{firstProduct.firmName}</td>
			<td>{firstProduct.articleNumber}</td>
			<td>{firstProduct.name}</td>
			<td colSpan={7}></td>
		</tr>
		{show ? searchProducts.map((product, indexProduct) =>
			<tr key={indexProduct} className={`result-product${product.inStock ? ' in-stock' : ''}`}>
				<td></td>
				{isManager ? <td>{product.providerName}</td> : null}
				<td>{product.firmName}</td>
				<td>{product.articleNumber}</td>
				<td>{product.name}</td>
				<td className="result-number">{product.countInStock}</td>
				<td className="result-number">{product.countMinOrder}</td>
				<td><CountDate searchProduct={product} /></td>
				{isManager ? <td>{numbro(product.priceProviderWithNds).formatCurrency({ mantissa: 2 })}</td> : null}
				<td>{numbro(product.priceUser).formatCurrency({ mantissa: 2 })}</td>
				<td><Info /></td>
				<td><ShoppingCart onClick={() => showAddItemCart({ ...product, count: 1 })} /></td>
			</tr>,
		) : null}
	</>;
};

export const SearchProductsPage = () => {
	const query = useQuery();
	const setLoading = useLoading();
	const auth = useAuth();
	const cart = useCart();

	const submit = useRef<any>();

	const catalogs = query.get('catalogs');
	const firmName = query.get('firmName');
	const articleNumber = query.get('articleNumber');

	const [productsResult, setProductsResult] = useState<SearchProductsGroupByName>();
	const [showAddItemCart, setShowAddItemCart] = useState<{
		showing: boolean,
		item?: CartItem,
	}>({
		showing: false,
	});

	const search = async () => {
		if (!catalogs || !catalog || !catalog.articleNumber || !catalog.firmName) {
			return;
		}
		setLoading(true);
		const r = await CatalogsService.searchProducts(catalogs, catalog);
		setProductsResult(r.success ? {
			catalog: r.value!.catalog,
			products: r.value!.products.map(productGroup => ({
				type: productGroup.type,
				countProducts: productGroup.countProducts,
				products: productGroup.products.reduce(
					(productsGroup, product) => {
						const key = product.firmName;
						productsGroup[key] ||= [];
						productsGroup[key].push(product);
						return productsGroup;
					}, {} as { [p: string]: SearchProduct[] }),
			})),
			productsNonCatalog: r.value!.productsNonCatalog,
		} : {
			catalog: null,
			products: [],
			productsNonCatalog: [],
		});
		setLoading(false);
	};

	useEffect(() => {
		search().then();
	}, []);

	if (!catalogs || !firmName || !articleNumber)
		return <div className="search-products"><div>Ничего не найдено</div></div>;
	const catalog: SearchProductBase = {
		firmName,
		articleNumber,
		name: '-',
	};
	if (!catalog || !catalog.articleNumber || !catalog.firmName)
		return <div className="search-products"><div>Ничего не найдено</div></div>;
	if (!productsResult)
		return <div className="search-products"><div>Идет поиск...</div></div>;
	if (!productsResult.products || productsResult.products?.length === 0)
		return <div className="search-products"><div>Ничего не найдено</div></div>;

	const user = auth.getUser();
	const isManager = !!user?.isManager;

	const handleAddItemCartClose = () => setShowAddItemCart({
		showing: false,
	});

	const cartItemSchema = yup.object({
		count: yup.number().min(1, 'Минимальное количество 1')
			.max(showAddItemCart.item?.countInStock ?? 1, `Максимальное количество ${showAddItemCart.item?.countInStock}`)
			.required('Необходимо ввести количество'),
	});

	const getPriceTotal = (item: CartItem) => {
		return item ? item.priceUser * item.count : 0;
	};

	const getPriceMargin = (item: CartItem) => {
		return item ? ((item.priceProviderWithNds ?? 0) * 100 * item.count) / 100 : 0;
	};

	let count = 0;
	if (showAddItemCart.showing) {
		const cartItemFind = cart.products.find(p => CartUtils.equalsCartItem(p, showAddItemCart.item!));
		count = cartItemFind ? cartItemFind.count : 1;
	}

	return <div className="search-products">
		<Modal aria-labelledby="contained-modal-title-vcenter" centered show={showAddItemCart.showing} onHide={handleAddItemCartClose}>
			<Modal.Header closeButton>
				<Modal.Title>Добавление товара в корзину</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormikProvider validationSchema={cartItemSchema}>
					<Formik
						initialValues={{
							...showAddItemCart.item!,
							count,
							priceTotal: getPriceTotal(showAddItemCart.item!),
							priceMargin: getPriceMargin(showAddItemCart.item!),
						}}
						validationSchema={cartItemSchema}
						onSubmit={async (data) => {
							const r = await cart.addCartItem(data, user?.id);
							if (!r.success) {
								return;
							}
							handleAddItemCartClose();
						}}
					>
						{({ handleSubmit, values, setValues }) => {
							submit.current = handleSubmit;
							const macCount = showAddItemCart.item?.countInStock ?? 1;

							return <Form noValidate onSubmit={handleSubmit}>
								<InputForm name="articleNumber" label="Артикул" disabled={true} />
								<InputForm name="firmName" label="Марка" disabled={true} />
								<InputForm name="name" label="Наименование" disabled={true} />
								{isManager ? <>
									<InputForm name="providerName" label="Поставщик" disabled={true} />
									<InputForm name="priceProviderWithNds" label="Цена пост., руб." disabled={true} type="money" />
								</> : null}
								<InputForm name="priceUser" label="Цена, руб." disabled={true} type="money" />
								<InputForm name="countDate" label="Кол-во ожид. дней" disabled={true} />
								<InputForm name="countMinOrder" label="Мин. заказ, шт." disabled={true} />
								<InputForm name="count" label={`Кол-во (макс. ${macCount}), шт.`} type="number" min={1} max={macCount}
									onChangeValue={(v) => {
										const item = {
											...values,
											count: parseInt(v),
										};
										item.priceTotal = getPriceTotal(item);
										item.priceMargin = getPriceMargin(item);
										setValues(item);
									}}
								/>
								<InputForm name="priceTotal" label="Сумма, руб." disabled={true} type="money" />
								{isManager ? <InputForm name="priceMargin" label="Маржа, руб." disabled={true} type="money" /> : null}
							</Form>;
						}}
					</Formik >
				</FormikProvider>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={handleAddItemCartClose}>Отмена</Button>
				<Button type="submit" variant="primary" onClick={() => submit.current()}>Добавить</Button>
			</Modal.Footer>
		</Modal>
		<table>
			<tbody>
				{productsResult ? productsResult.products.map((product, index) =>
					<Fragment key={index}>
						<tr>
							<td className="result-group-title" colSpan={isManager ? 12 : 10}>
								{SearchUtils.getSearchResultProductsTypesNames(product.type)} ({product.countProducts})
							</td>
						</tr>
						<tr className="result-group-headers">
							<td></td>
							{isManager ? <td>Поставщик</td> : null}
							<td>Бренд</td>
							<td>Артикул</td>
							<td>Наименование</td>
							<td>Нал.</td>
							<td>Мин. <br />заказ, шт.</td>
							<td>Ожид. <br />срок, дн.</td>
							{isManager ? <td>Цена пост.,<br /> руб.</td> : null}
							<td>Цена,<br /> руб.</td>
							<td></td>
							<td></td>
						</tr>
						{Object.values(product.products).map((products, indexProducts) =>
							<SearchProductsByName key={indexProducts} isManager={isManager} searchProducts={products}
								opened={product.type === 'article'} showAddItemCart={(item) => setShowAddItemCart({
									showing: true,
									item,
								})} />)}
					</Fragment>,
				) : null}
			</tbody>
		</table>
	</div>;
};
