import React, { useState } from 'react';

import useSettingsState from '../hooks/useSettingsState';
import { SidebarBehaviors, SidebarPositions } from '../models/constants';

type SidebarContextType = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  position: SidebarPositions;
  setPosition: (position: SidebarPositions) => void;
  behavior: SidebarBehaviors;
  setBehavior: (behavior: SidebarBehaviors) => void;
};

const SidebarContext = React.createContext<SidebarContextType>({
	isOpen: true,
	setIsOpen: () => { },
	position: 'left',
	setPosition: () => { },
	behavior: 'sticky',
	setBehavior: () => { },
});

function SidebarProvider({ children }: { children: any }) {
	const [isOpen, setIsOpen] = useState(true);
	const [position, setPosition] = useSettingsState('sidebarPosition', 'left');
	const [behavior, setBehavior] = useSettingsState('sidebarBehavior', 'sticky');

	return (
		<SidebarContext.Provider
			value={{
				isOpen,
				setIsOpen,
				position,
				setPosition,
				behavior,
				setBehavior,
			}}
		>
			{children}
		</SidebarContext.Provider>
	);
}

export { SidebarProvider, SidebarContext };
