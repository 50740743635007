export type EventResult<T> = { type: string; initType: string; target: T; defaultPrevented?: any };

export class EventTarget {
	private listeners: {[p: string]: ((ev: EventResult<any>) => void)[]} = {};

	addEventListener = (type: string, callback: any) => {
		if (!(type in this.listeners)) {
			this.listeners[type] = [];
		}
		this.listeners[type].push(callback);
	};

	removeEventListener = (type: string, callback: any) => {
		if (!(type in this.listeners)) {
			return;
		}
		const stack = this.listeners[type];
		for (let i = 0, l = stack.length; i < l; i++) {
			if (stack[i] === callback) {
				stack.splice(i, 1);
				return;
			}
		}
	};

	dispatchEvent = (event: EventResult<any>) => {
		if (!(event.type in this.listeners)) {
			return true;
		}
		const stack = this.listeners[event.type].slice();
		for (let i = 0, l = stack.length; i < l; i++) {
			stack[i].call(this, event);
		}
		return !event.defaultPrevented;
	};
}
