import React from 'react';
import * as yup from 'yup';

type FormikContextType = {
    validationSchema: any | (() => any);
};

export const FormikContext = React.createContext<FormikContextType>({
	validationSchema: yup.object(),
});

export const FormikProvider = ({ validationSchema, children }: FormikContextType & {
    children: any
}) => {
	const contextValue = {
		validationSchema,
	};

	return <FormikContext.Provider value={contextValue}>{children}</FormikContext.Provider>;
};