import { SwipeablePanelProps } from '@astick/core';

export const dialogTypes = ['modal', 'swipeablePanel'] as const;
export type DialogTypes = typeof dialogTypes[number];

export type DialogParams = {
	type?: DialogTypes;
	title?: string;
	content?: any;
	isOk?: boolean;
	isCancel?: boolean;
	swipeableProps?: SwipeablePanelProps;
};
