import { useRef } from 'react';
import { Form, InputGroup } from 'react-bootstrap';

export type InputRange = {
  value1?: number | Date;
  value2?: number | Date;
};

export type RangeInputProps = {
  type?: 'number' | 'money' | 'date';
  value?: InputRange;
  placeholder1?: string;
  placeholder2?: string;
  min?: number;
  name?: string;
  onChangeValue?: (value: InputRange) => void;
};

export const RangeInput = (props: RangeInputProps) => {
	const inputRef1 = useRef<any>(null);
	const inputRef2 = useRef<any>(null);

	const getDateValue = (v) => {
		if (!v) {
			return '';
		}
		return v instanceof Date ? v.toISOString().slice(0, 10) : v.length > 10 ? v.slice(0, 10) : v ?? '';
	};

	return (
		<div>
			<InputGroup>
				<Form.Control
					ref={inputRef1}
					type={props.type ?? 'number'}
					name={`${props.name}Min`}
					value={props.type === 'date' ? getDateValue(props.value?.value1) : props.value?.value1}
					placeholder={props.placeholder1}
					min={props.min}
					onChange={(e) => {
						if (props.onChangeValue) {
							if (props.type === 'date') {
								props.onChangeValue({
									value1: e.target?.value ? new Date(e.target?.value) : undefined,
									value2: inputRef2.current.value ? new Date(inputRef2.current.value) : undefined,
								});
							} else {
								const n1 = parseFloat(e.target?.value?.toString().replace(/\s/g, ''));
								const n2 = parseFloat(inputRef2.current.value?.toString().replace(/\s/g, ''));
								props.onChangeValue({
									value1: isNaN(n1) ? undefined : n1,
									value2: isNaN(n2) ? undefined : n2,
								});
							}
						}
					}}
					onBlur={() => {
						if (props.type === 'date') {
							const n1 = inputRef1.current.value;
							let n2 = inputRef2.current.value;
							if (!n2 || n1 > n2) {
								n2 = n1;
								inputRef2.current.value = n2;
							}
							props.onChangeValue?.({
								value1: n1 ? new Date(n1) : undefined,
								value2: n2 ? new Date(n2) : undefined,
							});
						} else {
							const n1 = parseFloat(inputRef1.current.value?.toString().replace(/\s/g, ''));
							let n2 = parseFloat(inputRef2.current.value?.toString().replace(/\s/g, ''));
							if (n1 > n2) {
								n2 = n1;
								inputRef2.current.value = n2;
							}
							props.onChangeValue?.({
								value1: isNaN(n1) ? undefined : n1,
								value2: isNaN(n2) ? undefined : n2,
							});
						}
					}}
				/>
				<Form.Control
					ref={inputRef2}
					type={props.type ?? 'number'}
					name={`${props.name}Max`}
					value={props.type === 'date' ? getDateValue(props.value?.value2) : props.value?.value2}
					placeholder={props.placeholder2}
					min={props.min}
					onChange={(e) => {
						if (props.onChangeValue) {
							if (props.type === 'date') {
								props.onChangeValue({
									value1: inputRef1.current.value ? new Date(inputRef1.current.value) : undefined,
									value2: e.target?.value ? new Date(e.target?.value) : undefined,
								});
							} else {
								const n1 = parseFloat(inputRef1.current.value?.toString().replace(/\s/g, ''));
								const n2 = parseFloat(e.target?.value?.toString().replace(/\s/g, ''));
								props.onChangeValue({
									value1: isNaN(n1) ? undefined : n1,
									value2: isNaN(n2) ? undefined : n2,
								});
							}
						}
					}}
					onBlur={() => {
						if (props.type === 'date') {
							let n1 = inputRef1.current.value;
							const n2 = inputRef2.current.value;
							if (!n1 || n1 > n2) {
								n1 = n2;
								inputRef1.current.value = n2;
							}
							props.onChangeValue?.({
								value1: n1 ? new Date(n1) : undefined,
								value2: n2 ? new Date(n2) : undefined,
							});
						} else {
							let n1 = parseFloat(inputRef1.current.value?.toString().replace(/\s/g, ''));
							const n2 = parseFloat(inputRef2.current.value?.toString().replace(/\s/g, ''));
							if (n1 > n2) {
								n1 = n2;
								inputRef1.current.value = n2;
							}
							props.onChangeValue?.({
								value1: isNaN(n1) ? undefined : n1,
								value2: isNaN(n2) ? undefined : n2,
							});
						}
					}}
				/>
			</InputGroup>
		</div>
	);
};
