import { IResult, Pageable, Page } from '@astick/core';
import { Discount, Provider, ProviderRole, Rate } from 'src/models';
import { PricesRepository } from 'src/repositories/prices.repository';

export class PricesService {
	//#region Discounts
	static async getDiscounts(paging: Pageable): Promise<IResult<Page<Discount>>> {
		return PricesRepository.getDiscounts(paging);
	}

	static async createDiscount(discount: Discount): Promise<IResult<Discount>> {
		return PricesRepository.createDiscount(discount);
	}

	static async updateDiscount(discount: Discount): Promise<IResult<Discount>> {
		return PricesRepository.updateDiscount(discount);
	}

	static async deleteDiscount(discountId: string): Promise<IResult<Discount>> {
		return PricesRepository.deleteDiscount(discountId);
	}
	//#endregion

	//#region Providers
	static async getProviders(paging: Pageable): Promise<IResult<Page<Provider>>> {
		return PricesRepository.getProviders(paging);
	}

	static async createProvider(provider: Provider): Promise<IResult<Provider>> {
		return PricesRepository.createProvider(provider);
	}

	static async updateProvider(provider: Provider): Promise<IResult<Provider>> {
		return PricesRepository.updateProvider(provider);
	}

	static async deleteProvider(providerId: string): Promise<IResult<Provider>> {
		return PricesRepository.deleteProvider(providerId);
	}
	//#endregion

	//#region ProvidersRoles
	static async getProvidersRoles(pageable: Pageable): Promise<IResult<Page<ProviderRole>>> {
		return PricesRepository.getProvidersRoles(pageable);
	}

	static async createProviderRole(providerRole: ProviderRole): Promise<IResult<ProviderRole>> {
		return PricesRepository.createProviderRole(providerRole);
	}

	static async updateProviderRole(providerRole: ProviderRole): Promise<IResult<ProviderRole>> {
		return PricesRepository.updateProviderRole(providerRole);
	}

	static async deleteProviderRole(providerRoleId: string): Promise<IResult> {
		return PricesRepository.deleteProviderRole(providerRoleId);
	}
	//#endregion

	//#region Rates
	static async getRates(pageable: Pageable): Promise<IResult<Page<Rate>>> {
		return PricesRepository.getRates(pageable);
	}

	static async createRate(rate: Rate): Promise<IResult<Rate>> {
		return PricesRepository.createRate(rate);
	}

	static async updateRate(rate: Rate): Promise<IResult<Rate>> {
		return PricesRepository.updateRate(rate);
	}

	static async deleteRate(rateId: string): Promise<IResult> {
		return PricesRepository.deleteRate(rateId);
	}
	//#endregion
};
