import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, Button } from 'react-bootstrap';
import {
	Layouts,
	SidebarBehaviors,
	SidebarPositions,
	Themes,
} from '../models/constants';
import useOuterClick from '../hooks/useOuterClick';
import { useTheme, useSidebar } from '../hooks';
import useLayout from '../hooks/useLayout';
import { BookOpen, Sliders } from 'react-feather';

const themeOptions: {
  name: string;
  value: Themes;
}[] = [
	{
		name: 'Default',
		value: 'default',
	},
	{
		name: 'Colored',
		value: 'colored',
	},
	{
		name: 'Dark',
		value: 'dark',
	},
	{
		name: 'Light',
		value: 'light',
	},
];

const sidebarPositionOptions: {
  name: string;
  value: SidebarPositions;
}[] = [
	{
		name: 'Left',
		value: 'left',
	},
	{
		name: 'Right',
		value: 'right',
	},
];

const sidebarBehaviorOptions: {
  name: string;
  value: SidebarBehaviors;
}[] = [
	{
		name: 'Sticky',
		value: 'sticky',
	},
	{
		name: 'Fixed',
		value: 'fixed',
	},
	{
		name: 'Compact',
		value: 'compact',
	},
];

const layoutOptions: {
  name: string;
  value: Layouts;
}[] = [
	{
		name: 'Fluid',
		value: 'fluid',
	},
	{
		name: 'Boxed',
		value: 'boxed',
	},
];

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Settings = () => {
	const query = useQuery();
	const [isOpen, setIsOpen] = useState(false);

	const { theme, setTheme } = useTheme();
	const { position, setPosition, behavior, setBehavior } = useSidebar();
	const { layout, setLayout } = useLayout();

	const innerRef = useOuterClick(() => {
		setIsOpen(false);
	});

	const setSettingByQueryParam = (name, set) => {
		const value = query.get(name);
		if (value) {
			set(value);
		}
	};

	// Read from query parameter (e.g. ?theme=dark)
	// only for demo purposes
	useEffect(() => {
		setSettingByQueryParam('theme', setTheme);
		setSettingByQueryParam('sidebarPosition', setPosition);
		setSettingByQueryParam('sidebarBehavior', setBehavior);
		setSettingByQueryParam('layout', setLayout);
	}, []);

	return (
		<div
			ref={innerRef}
			className={`settings js-settings ${isOpen ? 'open' : ''}`}
		>
			<div className="settings-toggle">
				<div
					className="settings-toggle-option settings-toggle-option-text js-settings-toggle"
					title="Theme Builder"
					onClick={() => setIsOpen(true)}
				>
					<Sliders className="feather align-middle" /> Builder
				</div>
				<a
					className="settings-toggle-option"
					title="Documentation"
					href="/docs"
					target="_blank"
				>
					<BookOpen className="feather align-middle" />
				</a>
			</div>
			<div className="settings-panel">
				<div className="settings-content">
					<div className="settings-title d-flex align-items-center">
						<button
							type="button"
							className="btn-close float-right js-settings-toggle"
							aria-label="Close"
							onClick={() => setIsOpen(false)}
						></button>
						<h4 className="mb-0 ms-2 d-inline-block">Theme Builder</h4>
					</div>
					<div className="settings-body">
						<Alert variant="primary">
							<div className="alert-message">
								<strong>Hey there!</strong> Set your own customized style below.
                Choose the ones that best fits your needs.
							</div>
						</Alert>

						<div className="mb-3">
							<span className="d-block font-size-lg fw-bold">Color scheme</span>
							<span className="d-block text-muted mb-2">
                The perfect color mode for your app.
							</span>
							<div className="row g-0 text-center mx-n1 mb-2">
								{themeOptions.map(({ name, value }) => (
									<div className="col-6" key={value}>
										<label className="mx-1 d-block mb-1">
											<input
												className="settings-scheme-label"
												type="radio"
												name="theme"
												value={value}
												checked={theme === value}
												onChange={() => setTheme(value)}
											/>
											<div className="settings-scheme">
												<div
													className={`settings-scheme-theme settings-scheme-theme-${value}`}
												></div>
											</div>
										</label>
										{name}
									</div>
								))}
							</div>
						</div>
						<hr />
						<div className="mb-3">
							<span className="d-block font-size-lg fw-bold">
                Sidebar position
							</span>
							<span className="d-block text-muted mb-2">
                Toggle the position of the sidebar.
							</span>
							<div>
								{sidebarPositionOptions.map(({ name, value }) => (
									<label className="me-1" key={value}>
										<input
											className="settings-button-label"
											type="radio"
											name="sidebarPosition"
											value={value}
											checked={position === value}
											onChange={() => setPosition(value)}
										/>
										<div className="settings-button">{name}</div>
									</label>
								))}
							</div>
						</div>
						<hr />
						<div className="mb-3">
							<span className="d-block font-size-lg fw-bold">
                Sidebar behavior
							</span>
							<span className="d-block text-muted mb-2">
                Change the behavior of the sidebar.
							</span>
							<div>
								{sidebarBehaviorOptions.map(({ name, value }) => (
									<label className="me-1" key={value}>
										<input
											className="settings-button-label"
											type="radio"
											name="sidebarBehavior"
											value={value}
											checked={behavior === value}
											onChange={() => setBehavior(value)}
										/>
										<div className="settings-button">{name}</div>
									</label>
								))}
							</div>
						</div>
						<hr />
						<div className="mb-3">
							<span className="d-block font-size-lg fw-bold">Layout</span>
							<span className="d-block text-muted mb-2">
                Toggle container layout system.
							</span>
							<div>
								{layoutOptions.map(({ name, value }) => (
									<label className="me-1" key={value}>
										<input
											className="settings-button-label"
											type="radio"
											name="layout"
											value={value}
											checked={layout === value}
											onChange={() => setLayout(value)}
										/>
										<div className="settings-button">{name}</div>
									</label>
								))}
							</div>
						</div>
					</div>
					<div className="settings-footer">
						<div className="d-grid">
							<Button
								as="a"
								rel="noreferrer"
								href="https://themes.getbootstrap.com/product/appstack-react-admin-dashboard-template/"
								target="_blank"
								variant="primary"
								size="lg"
							>
                Purchase
							</Button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Settings;
