export const languages = [
	'id',
	'ca',
	'cy',
	'da',
	'de',
	'et',
	'en',
	'es',
	'eu',
	'fr',
	'hi',
	'hr',
	'it',
	'kk',
	'lv',
	'lt',
	'hu',
	'ms',
	'nl',
	'no',
	'pl',
	'pt',
	'pt-br',
	'ro',
	'sk',
	'rs',
	'fi',
	'sv',
	'sw',
	'tel',
	'tr',
	'vi',
	'nl-BE',
	'is',
	'cs',
	'gr',
	'bg',
	'mk',
	'ru',
	'tg',
	'ua',
	'he',
	'ar',
	'fa',
	'th',
	'ka',
	'ja',
	'zh-cn',
	'zh-tw',
	'ko',
] as const;
export type Language = typeof languages[number];
export const languageDefault: Language = 'ru';

export const languagesNumbro = [
	'bg', // Unknown Code	            
	'cs-CZ', // Czech	                    
	'da-DK', // Danish	                    
	'de-AT', // Unknown Code	            
	'de-CH', // German (Switzerland)	    
	'de-DE', // German (Standard)	        
	'de-LI', // Unknown Code	            
	'el', // Unknown Code	            
	'en-AU', // Unknown Code	            
	'en-GB', // English (United Kingdom)	
	'en-IE', // Unknown Code	            
	'en-NZ', // Unknown Code	            
	'en-US', // English (United States)	    
	'en-ZA', // Unknown Code	            
	'es-AR', // Spanish (Argentina)	        
	'es-CL', // Unknown Code	            
	'es-CO', // Unknown Code	            
	'es-CR', // Unknown Code	            
	'es-ES', // Spanish (Spain)	            
	'es-MX', // Unknown Code	            
	'es-NI', // Unknown Code	            
	'es-PE', // Unknown Code	            
	'es-PR', // Unknown Code	            
	'es-SV', // Unknown Code	            
	'et-EE', // Estonian (Estonia)	        
	'fa-IR', // Farsi (Iran)	            
	'fi-FI', // Finnish (Finland)	        
	'fil-PH', // Filipino	                
	'fr-CA', // French (Canada)	            
	'fr-CH', // French (Switzerland)	    
	'fr-FR', // French (France)	            
	'he-IL', // Unknown Code	            
	'hu-HU', // Hungarian (Hungary)	        
	'id', // Unknown Code	            
	'it-CH', // Unknown Code	            
	'it-IT', // Italian (Standard)	        
	'ja-JP', // Japanese	                
	'ko-KR', // Unknown Code	            
	'lv-LV', // Latvian (Latvia)	        
	'nb', // Unknown Code	            
	'nb-NO', // Norwegian (Norway)	        
	'nl-BE', // Dutch (Belgium)	            
	'nl-NL', // Dutch (The Netherlands)	    
	'nn', // Unknown Code	            
	'pl-PL', // Polish (Poland)	            
	'pt-BR', // Portuguese (Brazil)	        
	'pt-PT', // Portuguese (Portugal)	    
	'ro-RO', // Unknown Code	            
	'ru-RU', // Russian (Russia)	        
	'ru-UA', // Russian (Ukraine)	        
	'sk-SK', // Slovak	                    
	'sl', // Unknown Code	            
	'sr-Cyrl-RS', // Unknown Code	            
	'sv-SE', // Swedish	                    
	'th-TH', // Thai	                    
	'tr-TR', // Turkish	                    
	'uk-UA', // Ukrainian	                
	'zh-CN', // Chinese (PRC)	            
	'zh-MO', // Unknown Code	            
	'zh-SG', // Unknown Code	            
	'zh-TW', // Unknown Code	            
] as const;
export type LanguageNumbro = typeof languagesNumbro[number];

export const getLanguageNumbro = (language: Language): LanguageNumbro => {
	if (!language) {
		return 'ru-RU';
	}
	return languagesNumbro.find(l => l.match(/^[A-Za-z]+/)?.[1] === language) ?? 'ru-RU';;
};
