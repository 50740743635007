import { useEffect, useState } from 'react';
import { useField } from 'formik';
import { SelectForm, SelectFormProps } from './selectForm.control';

type AutocompleteFormProps = Omit<SelectFormProps, 'getData'> & {
	getData: (searchText: string) => Promise<any[] | undefined>;
};

export const AutocompleteForm = (props: AutocompleteFormProps) => {
	const { valueProperty = 'value' } = props;

	const [f, meta] = useField(props.name);
	const field: any = { ...f };
	delete field.onChange;

	const [data, setData] = useState<any[] | undefined>(f.value ? [f.value] : undefined);
	const [loading, setLoading] = useState(false);

	const value = props.value ?? data?.find((item) => (item && f.value ? item[valueProperty] === f.value : false))?.[valueProperty];

	const updateData = (v) => {
		if (!v || !props.getData) {
			return;
		}
		setLoading(true);
		props.getData(v).then((items) => {
			setData(items);
			setLoading(false);
		});
	};

	useEffect(() => {
		updateData(f.value);
	}, []);

	return (
		<div style={{ position: 'relative' }}>
			<div className={meta.touched && !!meta.error ? 'is-invalid' : 'is-valid'}>
				<SelectForm
					{...props}
					getData={undefined}
					options={data}
					isSearchable={true}
					isLoading={loading}
					value={value}
					openMenuOnFocus={false}
					openMenuOnClick={false}
					onInputChange={(v) => {
						updateData(v);
					}}
				/>
			</div>
		</div>
	);
};

AutocompleteForm.displayName = 'AutocompleteForm';
