import SidebarNavSection from './SidebarNavSection';
import { MenuSection } from '../../models';

const SidebarNav = ({ items }: {items: MenuSection[]}) => {
	return (
		<ul className="sidebar-nav">
			{items &&
        items.map((item) => (
        	<SidebarNavSection
        		key={item.title}
        		pages={item.pages}
        		title={item.title}
        	/>
        ))}
		</ul>
	);
};

export default SidebarNav;
