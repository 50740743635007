/* eslint-disable no-template-curly-in-string */
import * as yup from 'yup';

/** Поставщик товаров */
export type Provider = {
    /** ID поставщика товаров */
    id: string;

    /** Расширенный ID поставщика товаров */
    extraId: string;

    /** Наименование поставщика товаров */
    name: string;

    /** Юридическое наименование поставщика товаров */
    jurName: string;

    /** Хост Web поставщика */
    host: string;

    /** Минимальная сумма отгрузки */
    minShipment: number;

    /** Шаблон ссылки на ресурс поставщика */
    extraProductLinkTemplate: string;

    /** Описание поставщика */
    description: string;

    /** Приоритет поставщика поиска товаров */
    priority: number;

    /** Флаг необходимости синхронизации с 1C */
    isSync1C: boolean;
}

export const providerSchema = yup.object({
	extraId: yup.string().default('0').required('Необходимо указать расширенный ID поставщика'),
	name: yup.string().required('Необходимо указать наименование поставщика'),
	jurName: yup.string().required('Необходимо указать юридическое наименование поставщика'),
	host: yup.string().required('Необходимо указать хост Web поставщика'),
	minShipment: yup.number().default(1).positive().min(1, 'Минимальная значение минимальной суммы отгрузки ${min}.').required('Необходимо ввести минимальную сумму отгрузки'),
	extraProductLinkTemplate: yup.string().required('Необходимо указать шаблон ссылки на ресурс поставщика'),
	description: yup.string().required('Необходимо указать описание поставщика'),
	priority: yup.number().default(0).positive().min(0, 'Минимальная значение приоритета поставщика поиска товаров ${min}.')
		.required('Необходимо ввести приоритет поставщика поиска товаров'),
	isSync1C: yup.boolean().default(false),
});