// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.date-picker-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.date-picker-container .date-picker-splitter::after {
  content: "  -  ";
  font-size: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/lib_ui/src/styles/datePickerRange.control.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACC,aAAA;EACA,mBAAA;EACA,qBAAA;AAED;AAAC;EACC,gBAAA;EACA,eAAA;AAEF","sourcesContent":[".date-picker-container {\n\tdisplay: flex;\n\tflex-direction: row;\n\talign-items: flex-end;\n\n\t.date-picker-splitter::after {\n\t\tcontent: \"\\00a0\\00a0-\\00a0\\00a0\";\n\t\tfont-size: 2rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
