import { StyleMargin, StylePadding } from './style';

export const chartTypes = [
	'Area' /** Линейный декорированный по точкам график, сравнивающий два значения */,
	'Bar' /** Столбчатый график */,
	'Line' /** Линейный график */,
	'Pie' /** Круговой график */,
] as const;
export type ChartTypes = typeof chartTypes[number];

export type ChartData = any[];

export type ChartItemProps = {
  /** Тип графика */
  chartType?: ChartTypes;
  /** Наименование аттрибута объекта получения данных графика */
  dataKey: string;
  /** Системное наименование графика */
  name?: string;
  /** Цвет заливки графика */
  fill?: string;
  /** Цвет обводки графика */
  stroke?: string;
  /** Ширина обводки графика */
  strokeWidth?: number;
};

export type LegendType = 'plainline' | 'line' | 'square' | 'rect' | 'circle' | 'cross' | 'diamond' | 'star' | 'triangle' | 'wye' | 'none';

export type Payload = {
  value: any;
  id?: string;
  type?: LegendType;
  color?: string;
  payload?: {
    strokeDasharray: any;
  };
  formatter?: any;
  inactive?: boolean;
  legendIcon?: any;
};

export const chartXAxisDomains = ['category', 'number', 'date'] as const;
export type ChartXAxisDomains = typeof chartXAxisDomains[number];
export const chartXAxisDomainsDateFormat = ['year', 'month', 'day', 'hour', 'minute', 'second'] as const;
export type ChartXAxisDomainsDateFormat = typeof chartXAxisDomainsDateFormat[number];

export type XAxis = {
  /** If set true, the axis do not display in the chart. (DEFAULT: false) */
  hide?: boolean;
  /** The key of data displayed in the axis. */
  dataKey?: string;
  /** The unique id of x-axis. (DEFAULT: 0) */
  xAxisId?: string | number;
  /** The width of axis which is usually calculated internally. (DEFAULT: 0) */
  width?: number;
  /** The height of axis, which can be setted by user. (DEFAULT: 30) */
  height?: number;
  /** The orientation of axis. (DEFAULT: 'bottom') */
  orientation?: 'bottom' | 'top';
  /** The type of axis. (DEFAULT: 'category') */
  type?: ChartXAxisDomains;
  /** The date format if type equals 'date'. (DEFAULT: 'day') */
  dateUnit?: ChartXAxisDomainsDateFormat;
  /** The date step if type equals 'date'. (DEFAULT: 1) */
  dateStep?: number;
  /** Allow the ticks of XAxis to be decimals or not. (DEFAULT: true) */
  allowDecimals?: boolean;
  /** When domain of the axis is specified and the type of the axis is 'number', if allowDataOverflow is set to be false, the domain will be adjusted when the minimum value of data is smaller than domain[0] or the maximum value of data is greater than domain[1] so that the axis displays all data values. If set to true, graphic elements (line, area, bars) will be clipped to conform to the specified domain. (DEFAULT: false) */
  allowDataOverflow?: boolean;
  /** Allow the axis has duplicated categories or not when the type of axis is "category". (DEFAULT: true) */
  allowDuplicatedCategory?: boolean;
  /** The angle of axis ticks. (DEFAULT: 0) */
  angle?: number;
  /** The count of axis ticks. Not used if 'type' is 'category'. (DEFAULT: 5) */
  tickCount?: number;
  /** Specify the domain of axis when the axis is a number axis. The length of domain should be 2, and we will validate the values in domain. And each element in the array can be a number, 'auto', 'dataMin', 'dataMax', a string like 'dataMin - 20', 'dataMax + 100', or a function that accepts a single argument and returns a number. If any element of domain is set to be 'auto', comprehensible scale ticks will be calculated, and the final domain of axis is generated by the ticks.
   * FORMAT:
    <XAxis type="number" domain={['dataMin', 'dataMax']} />
    <XAxis type="number" domain={[0, 'dataMax']} />
    <XAxis type="number" domain={['auto', 'auto']} />
    <XAxis type="number" domain={[0, 'dataMax + 1000']} />
    <XAxis type="number" domain={['dataMin - 100', 'dataMax + 100']} />
    <XAxis type="number" domain={[dataMin => (0 - Math.abs(dataMin)), dataMax => (dataMax * 2)]} />
   * (DEFAULT: [0, 'auto']) */
  domain?: Array<string | number | Date>;
  /** Смещение границ дат оси Х */
  domainDateOffsetUnit?: number;
  /** If set 0, all the ticks will be shown. If set preserveStart", "preserveEnd" or "preserveStartEnd", the ticks which is to be shown or hidden will be calculated automatically. (DEFAULT: 'preserveEnd') */
  interval?: 'preserveStart' | 'preserveEnd' | 'preserveStartEnd' | number;
  /** Specify the padding of x-axis. (DEFAULT: { left: 0, right: 0 })
FORMAT:
<XAxis padding={{ left: 10 }} />
<XAxis padding={{ right: 20 }} />
<XAxis padding={{ left: 20, right: 20 }} />
   */
  padding?: StylePadding;
  /** The minimum gap between two adjacent labels. (DEFAULT: 5) */
  minTickGap?: number;
  /** If set false, no axis line will be drawn. If set a object, the option is the configuration of axis line. (DEFAULT: true) */
  axisLine?: boolean;
  /** If set false, no axis tick lines will be drawn. If set a object, the option is the configuration of tick lines. (DEFAULT: true) */
  tickLine?: boolean;
  /** The length of tick line. (DEFAULT: 6) */
  tickSize?: number;
  /** The formatter function of tick. */
  tickFormatter?: () => any;
  /** Set the values of axis ticks manually. */
  ticks?: any[];
  /** If set false, no ticks will be drawn. If set a object, the option is the configuration of ticks. If set a React element, the option is the custom react element of drawing ticks.
   * FORMAT:
   * <XAxis tick={false} />
    <XAxis tick={{stroke: 'red', strokeWidth: 2}} />
    <XAxis tick={<CustomizedTick />} />
   */
  tick?: any;
  /** If set true, flips ticks around the axis line, displaying the labels inside the chart instead of outside. (DEFAULT: false) */
  mirror?: boolean;

  /** Reverse the ticks or not. (DEFAULT: false) */
  reversed?: boolean;

  /** If set a string or a number, default label will be drawn, and the option is content. If set a React element, the option is the custom react element of drawing label. If an object, the option is the props of a new Label instance.
   * FORMAT:
    <XAxis label="Height" />
    <XAxis label={<CustomizedLabel />} />
    <XAxis label={{ value: "XAxis Label" }} />
   */
  labelString?: any;
  /** If set to 'auto', the scale function is decided by the type of chart, and the props type. When set to 'time', make sure to also set type to 'number' and to include a domain. (DEFAULT: auto)
   * FORMAT:
   * 
   * <XAxis scale="log" />
   * 
   * import { scaleLog } from 'd3-scale';
   * const scale = scaleLog().base(Math.E);
   * 
   * ...
      <XAxis scale={scale} />
    ...
   */
  scale?: 'auto' | 'linear' | 'pow' | 'sqrt' | 'log' | 'identity' | 'time' | 'band' | 'point' | 'ordinal' | 'quantile' | 'quantize' | 'utc' | 'sequential' | 'threshold';
  /** The unit of data displayed in the axis. This option will be used to represent an index unit in a scatter chart. */
  unit?: string;
  /** The name of data displayed in the axis. This option will be used to represent an index in a scatter chart. */
  name?: string;
  /** Мин width label (mobile) */
  minStepX?: number;
  /** Максимальная ширина равная количеству ширины устройства (mobile) */
  maxCountScreenWidth?: number;
};

export type ChartLegend = {
  /** The width of legend. */
  width?: number;
  /** The height of legend. */
  height?: number;
  /** The layout of legend items. DEFAULT: 'horizontal' */
  layout?: 'horizontal' | 'vertical';
  /** The alignment of legend items in 'horizontal' direction, which can be 'left', 'center', 'right'. DEFAULT: 'center' */
  align?: 'left' | 'center' | 'right';
  /** The alignment of legend items in 'vertical' direction, which can be 'top', 'middle', 'bottom'. DEFAULT: 'middle' */
  verticalAlign?: 'top' | 'middle' | 'bottom';
  /** The size of icon in each legend item. DEFAULT: 14 */
  iconSizeNumber?: number;
  /** The type of icon in each legend item. */
  iconType?: 'line' | 'plainline' | 'square' | 'rect' | 'circle' | 'cross' | 'diamond' | 'star' | 'triangle' | 'wye';

  /** The source data of the content to be displayed in the legend, usually calculated internally. DEFAULT: [] */
  payload?: Payload[];

  /** The width of chart container, usually calculated internally. */
  chartWidthNumber?: number;

  /** The height of chart container, usually calculated internally. */
  chartHeight?: number;

  /** The margin of chart container, usually calculated internally. */
  margin?: StyleMargin;

  /** If set to a React element, the option will be used to render the legend. If set to a function, the function will be called to render the legend's content. */
  content?: any;

  /** The formatter function of each text in legend. */
  formatter?: any;

  /**
   * The style of legend container which is a "position: absolute;" div element.
   * Because the position of legend is quite flexible, so you can change the position by the value of top, left, right, bottom in this option.
   * And the format of wrapperStyle is the same as React inline style.
   */
  wrapperStyle?: any;

  /** The customized event handler of click on the items in this group */
  onClick?: () => void;
};

export type ChartProps = {
  type?: ChartTypes;
  /** Флаг отображения сетки */
  isGrid?: boolean;
  /** Параметры сетки */
  grid?: any;
  /** Флаг отображения заголовка */
  isTooltip?: boolean;
  /** Параметры заголовка */
  tooltip?: any;
  /** Флаг отображения легенды */
  isLegend?: boolean;
  /** Параметры легенды */
  legend?: ChartLegend;
  isXAxis?: boolean;
  XAxis?: XAxis;
  isYAxis?: boolean;
  YAxis?: any;
  /** Флаг отображения значения на графике */
  isLabel?: boolean;
  /** Флаг необходимости отображения таблицы данных */
  isTable?: boolean;
  /** Текст при отсутствии данных для отображения в таблице */
  tableTextEmpty?: string;
  /** Наименование атрибута группировки данных */
  tableGroupField?: string;
  /** Текст кнопки "Подробнее" в таблице данных */
  tableTextMoreButton?: string;
  /** Флаг необходимости отображения итоговой суммы */
  isTotal?: boolean;
  getRenderTableItem?: (chart: ChartItemProps, item: any, index: number) => JSX.Element | null;
  charts?: ChartItemProps[];
  /** Флаг использования половины кругового графика */
  isHalf?: boolean;
  data?: {
    dataChart?: ChartData;
    dataTable?: ChartData | {[p: string]: ChartData};
  };

  onLegendClick?: (chart: ChartItemProps) => void;
  onTableTabChanged?: (chart: ChartItemProps, index: number) => void;
};
