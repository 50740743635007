import numbro from 'numbro';
import { ShoppingCart, User as UserIcon } from 'react-feather';
import { User } from '../../models';
import { useAuth, useCart } from '../../hooks';
import { IconMain } from '../../resources/icons';

export const MainHeaderBottom = (props: {
	navigate: (url: string) => void;
	phones: string[];
	schedule: string;
	user?: User;
}) => {
	const { navigate, phones, schedule, user } = props;

	const auth = useAuth();
	const cart = useCart();

	// TODO Доработать баланс
	const balanceAvailable = 0;

	return <div className="main_header_bottom">
		<div className="main_width">
			<div className="main_header_bottom_content">
				<div className="main_header_bottom_content_left">
					<div className="main_header_bottom_block main_header_bottom_logo" onClick={() => navigate('/')}>
						<IconMain className="logo_image" type="logo" />
						<IconMain className="logo_title" type="logoTitle" />
					</div>
					<div className="main_header_bottom_block main_header_bottom_contacts">
						<div className="main_header_bottom_contacts_phone">{phones.join(', ')}</div>
						<div className="main_header_bottom_contacts_schedule">{schedule}</div>
					</div>
				</div>
				<div className="main_header_bottom_content_right">
					<div className="main_header_bottom_logo main_header_bottom_block_logo" onClick={() => navigate('/')}>
						<div className="main_header_bottom_block_logo_img"></div>
						<div className="main_header_bottom_block_logo_name"></div>
					</div>
					<div className="main_header_bottom_block main_header_bottom_private">
						<div className="main_header_bottom_img main_header_bottom_private_img">
							{!user ? <i className="user-icon">{<UserIcon />}</i>
								: <a className="ser-icon" href="/client/personaldata" target="_blank">{<UserIcon />}</a>
							}
						</div>
						<div className="main_header_bottom_right">
							{!user ? <div className="main_header_bottom_title main_header_bottom_private_title">ЛИЧНЫЙ КАБИНЕТ</div>
								: <div className="main_header_bottom_title main_header_bottom_private_title"
									title={`${user!.initials}\nДоступный остаток: ${balanceAvailable}`}>
									{user.isManager ?
										<a href="#" onClick={() => {
											// f_ChangeCartUser();
											return false;
										}}>
											{user.initials}
											<span id="cartUser">
												<span {...(balanceAvailable < 0 && { style: { color: 'red' } })}>
													{numbro(balanceAvailable).formatCurrency({ mantissa: 2 })}
												</span>
											</span>
										</a>
										: <>{user.initials}<span {...(balanceAvailable < 0 && { style: { color: 'red' } })}>
											{numbro(balanceAvailable).formatCurrency({ mantissa: 2 })}
										</span></>
									}
								</div>
							}
							<div className="main_header_bottom_private_buttons">
								{!user ? <>
									<a onClick={() => navigate('/account/auth')} className="main_header_bottom_private_buttons_in">Вход</a>
									<a onClick={() => navigate('/account/register')} className="main_header_bottom_private_buttons_reg">Регистрация</a>
								</> : <>
									<a className="main_header_bottom_private_buttons_uline main_header_bottom_private_buttons_in" target="_blank"
										href={`${user.isAdmin ? 'admin' : user.isManager ? 'manager' : 'client'}`} rel="noreferrer">
										{user.isAdmin ? 'АРМ админа' : user.isManager ? 'АРМ менеджера' : 'Личный кабинет'}
									</a>
									<a className="main_header_bottom_private_buttons_uline main_header_bottom_private_buttons_out" onClick={() => {
										auth.signOut();
										navigate('/');
									}}>Выход</a>
								</>}
							</div>
						</div>
					</div>
					<div className="main_header_bottom_block main_header_bottom_cart">
						<div className="main_header_bottom_img main_header_bottom_cart_img">
							<a className="cart-icon" onClick={() => navigate('/cart')}>{<ShoppingCart />}</a>
						</div>
						<div className="main_header_bottom_right">
							<div className="main_header_bottom_title main_header_bottom_cart_title">КОРЗИНА</div>
							<a className="main_header_bottom_cart_total" onClick={() => navigate('/cart')}>
								<span className="cart_total">{numbro(cart.priceTotal).formatCurrency({ mantissa: 2 })}</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>;
};
