import { createContext } from 'react';
import { User } from '../models/user/user.model';
import { AccountService } from '../services/accounts.service';
import { IResult } from '@astick/core';
import { RegisterUser } from '../models';

export const useAuthProvider = () => {
	const registerUser = async (user: RegisterUser): Promise<IResult<RegisterUser>> => {
		return await AccountService.registerUser(user);
	};
	const requestResetPassword = async (email: string): Promise<IResult> => {
		return await AccountService.requestResetPassword(email);
	};
	const resetPassword = async (email: string, token: string, password: string): Promise<IResult> => {
		return await AccountService.resetPassword(email, token, password);
	};
	const signIn = async (userName: string, password: string): Promise<IResult<User>> => {
		return await AccountService.signIn(userName, password);
	};
	const signOut = () => {
		AccountService.signOut();
	};

	return {
		registerUser,
		requestResetPassword,
		resetPassword,
		signIn,
		signOut,
		getUser: () => AccountService.getCurrentUser(),
	};
};

export type AuthContextType = {
	registerUser: (user: RegisterUser) => Promise<IResult<RegisterUser>>;
	requestResetPassword: (email: string) => Promise<IResult>;
	resetPassword: (email: string, token: string, password: string) => Promise<IResult>;
	signIn: (userName: string, password: string) => Promise<IResult<User>>;
	signOut: () => void
	getUser: () => User | undefined;
};

export const AuthContext = createContext<AuthContextType>({
	registerUser: (): Promise<IResult<RegisterUser>> => {
		return new Promise((resolve) => {
			resolve({
				success: false,
			});
		});
	},
	requestResetPassword: (): Promise<IResult> => {
		return new Promise((resolve) => {
			resolve({
				success: false,
			});
		});
	},
	resetPassword: (): Promise<IResult> => {
		return new Promise((resolve) => {
			resolve({
				success: false,
			});
		});
	},
	signIn: async (): Promise<IResult<User>> => {
		return new Promise((resolve) => {
			resolve({
				success: false,
			});
		});
	},
	signOut: () => {
		// empty
	},
	getUser: (): User | undefined => {
		return;
	},
});

export const AuthProvider = (props: any) => {
	const auth = useAuthProvider();
	return <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>;
};
