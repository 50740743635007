export type CheckboxGroupProps = {
	label?: string;
	name: string;
	disabled?: boolean;
	type: 'checkbox' | 'radio';
	typeValue?: 'checks' | 'flag';
	inline?: boolean;
	clear?: boolean;
	clearLabel?: string;
	data: { [p: string]: { label: string; value?: any } };
	value?: number;
	onChangeValue?: (checks: string[] | number | string | undefined) => void;
};

export const CheckboxGroup = (props: CheckboxGroupProps) => {
	const { clearLabel = 'Неважно' } = props;

	const clearKey = '$clear';
	const data: {
		[p: string]: {
			flag: number;
			value: any;
			checked: boolean;
		};
	} = {};

	const getChecks = () => {
		const values: string[] = [];
		if (!data) {
			return values;
		}
		Object.keys(data).forEach((key) => {
			if (key !== clearKey && data[key].checked) {
				values.push(data[key].value);
			}
		});
		return values;
	};

	const getFlag = () => {
		if (!data) {
			return undefined;
		}
		let flag = 0;
		Object.keys(data).forEach((key) => {
			if (key !== clearKey && data[key].checked) {
				// eslint-disable-next-line no-bitwise
				flag |= data[key].flag;
			}
		});
		return flag > 0 ? flag : undefined;
	};

	const getCheckControl = (index: number, key: string, value: any, label?: string) => {
		let initFlag = key === clearKey ? 0 : 1;
		if (key !== clearKey) {
			for (let i = 0; i < index; i++) {
				if (props.clear && i === 0) {
					continue;
				}
				initFlag *= 2;
			}
		}
		const id = `cbx_${props.name}_${key}`;
		let checked = false;
		const checkedClear = () => key === clearKey && props.value === undefined;
		if (props.type === 'radio') {
			checked = props.value === value || checkedClear();
		} else {
			data[key] = { flag: initFlag, value, checked: false };
			if (checkedClear()) {
				checked = true;
			} else if (props.value) {
				// eslint-disable-next-line no-bitwise
				if ((props.value & initFlag) !== 0) {
					checked = true;
				}
			}
			data[key].checked = checked;
		}
		return (
			<div key={key} className={`form-check${props.inline ? ' form-check-inline' : ''}`}>
				<input
					id={id}
					type={props.type}
					checked={checked}
					disabled={props.disabled}
					className={props.type === 'checkbox' ? 'form-check-input' : 'form-check-input form-radio-input'}
					onChange={(e) => {
						if (props.type === 'radio') {
							if (e.target.checked) props.onChangeValue?.(value);
							return;
						}
						if (key === clearKey) {
							Object.keys(data).forEach((k) => {
								data[k].checked = false;
							});
							props.onChangeValue?.(props.type === 'checkbox' && props.typeValue === 'flag' ? undefined : []);
							return;
						}
						data[key].checked = !!e.target.checked;
						props.onChangeValue?.(props.type === 'checkbox' && props.typeValue === 'flag' ? getFlag() : getChecks());
					}}
				/>
				<label htmlFor={id} className={`form-check-label${!props.disabled ? ' clickable' : ''}`}>
					{label}
				</label>
			</div>
		);
	};

	const items = props.clear && props.data ? { [clearKey]: { label: clearLabel }, ...props.data } : props.data;

	return items ? (
		<div>
			{props.label ? <div className="label">{props.label}</div> : null}
			{Object.keys(items).map((key, index) => getCheckControl(index, key, items[key]?.value ?? (key !== clearKey ? key : undefined), items[key].label))}
		</div>
	) : null;
};
