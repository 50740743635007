import { useField } from 'formik';
import { CheckboxGroup, CheckboxGroupProps } from '../checkboxGroup.control';
import { Form } from 'react-bootstrap';

type CheckboxGroupFormProps = CheckboxGroupProps;

export const CheckboxGroupForm = (props: CheckboxGroupFormProps) => {
	const [f, meta, helpers] = useField(props.name);
	const field: any = { ...f };
	delete field.onChange;

	return (
		<div className='mb-3' style={{ position: 'relative' }}>
			<div className={meta.touched && !!meta.error ? 'is-invalid' : 'is-valid'}>
				<CheckboxGroup
					{...field}
					{...props}
					onChangeValue={(val) => {
						helpers.setValue(val);
						props.onChangeValue?.(val);
					}}
				/>
			</div>
			{meta.touched && meta.error ? (
				<Form.Control.Feedback type="invalid" tooltip>
					{meta.error}
				</Form.Control.Feedback>
			) : null}
		</div>
	);
};

CheckboxGroupForm.displayName = 'InputForm';
