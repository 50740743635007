import React, { Children, FormHTMLAttributes, cloneElement, useState } from 'react';
import { Form as FormBase, FormProps as FormPropsBase } from 'react-bootstrap';

export type FormProps = FormPropsBase & FormHTMLAttributes<any>;

export const Form = React.memo((props: FormProps) => {
	const [data, setData] = useState<any>();

	const childrenWithProps = (content: any, req = false) => {
		if (!req) {
			// return;
		}
		const map = Children.map(content, (child) => {
			if (!child) {
				return;
			}
			if (typeof child === 'string') {
				return child;
			}
			const name = child.type?.displayName;
			if (name === 'Text') {
				return child;
			}
			const children = child.props?.children ?? null;
			if (children) {
				return cloneElement(child, {
					...child.props,
					children: childrenWithProps(children, true),
				});
			} else {
				const clone = cloneElement(child, {
					...child.props,
					value: data?.[child.props.name],
					onChange: (item) => {
						console.log('🚀 ~ map ~ item11:', item);
						setData({ ...data, [child.props.name]: item.value });
					},
				});
				return clone;
			}
		});
		return <>{map}</>;
	};

	return (
		<FormBase {...props}>{childrenWithProps(props.children)}</FormBase>
	);
});

Form.displayName = 'Form';
