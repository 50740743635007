import { Navigate, useNavigate } from 'react-router-dom';
import { Button, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import { InputForm, PageTitle } from '@astick/ui';
import { useAuth, useLoading } from '../../hooks';
import { RequestResetPassword, requestResetPasswordSchema } from '../../models/account/requestResetPassword.model';

export const RequestResetPasswordPage = () => {
	const auth = useAuth();
	const navigate = useNavigate();
	const setLoading = useLoading();

	const user = auth.getUser();
	if (user) {
		return <Navigate to="/" />;
	}

	return <>
		<PageTitle title="Сброс пароля" />
		<Formik
			validationSchema={requestResetPasswordSchema}
			initialValues={{
				email: '',
			} as RequestResetPassword}
			onSubmit={async (data, { resetForm: _rm, setSubmitting: _s }) => {
				setLoading(true);
				const r = await auth.requestResetPassword(data.email);
				setLoading(false);
				if (!r.success) {
					alert(r.code === 400 ? r.error : 'Не удалось отправить запрос на сброс пароля, пожалуйста, повторите попытку');
					return;
				}
				alert(`На почту "${data.email}" отправлено письмо с инструкцией сброса пароля`);
				navigate('/');
			}}
		>
			{({ handleSubmit }) => (
				<div>
					<h4>Сброс пароля</h4>
					<Form noValidate onSubmit={handleSubmit} className="col-md-5">
						<InputForm name="email" label="Электронная почта" />
						<div className="buttons">
							<Button type="submit">Отправить</Button>
						</div>
					</Form>
				</div>
			)}
		</Formik >
	</>;
};
