import { useEffect } from 'react';
import useLocalStorage from './useLocalStorage';

function useSettingsState(key: string, initialValue: string) {
	const [value, setValue] = useLocalStorage(key, initialValue);
  
	useEffect(() => {
		// Replace style sheet if dark theme gets toggled
		if (key === 'theme') {
			// const theme = value === "dark" ? "dark" : "light";
			// const stylesheet = document.querySelector(".js-stylesheet");
			// stylesheet?.setAttribute("href", `/css/${theme}.css`);
			document.documentElement.setAttribute('data-bs-theme', value);
			document.body.dataset[key] = value;
			// document.body.className += ' InovuaReactDataGrid--theme-dark';
			// document.body.setAttribute('data-bs-theme', value)
			// document.body.setAttribute('data-sidebar-theme', value)
		} else {
			document.body.dataset[key] = value;
		}
	}, [value, key]);

	return [value, setValue];
}

export default useSettingsState;
