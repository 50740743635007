import { EventTarget } from './eventTarget.event';

export class OperationsEvents {
	private static _eventTarget = new EventTarget();
	private static getEventTarget(): EventTarget {
		return OperationsEvents._eventTarget;
	}

	static addEventListener = (type: string, callback: any) => {
		OperationsEvents.getEventTarget().addEventListener(type, callback);
	};

	static removeEventListener = (type: string, callback: any) => {
		OperationsEvents._eventTarget?.removeEventListener(type, callback);
	};

	static emit = (type: string, target: any) => {
		OperationsEvents._eventTarget?.dispatchEvent({
			type,
			initType: type,
			target,
		});
	};
}
