import { createContext, useRef, useState } from 'react';
import { AddToast, ToastProvider, useToasts } from 'react-toast-notifications';
import { Language } from '@astick/core';
import { Loader, LoaderRef } from '@astick/ui/src/components';
import { AuthProvider } from './auth.context';
import { CartProvider } from './cart.context';

export type MainContextType = {
	getTheme: () => string;
	setTheme: (theme: string) => void;
	getLocale: () => Language;
	setLocale: (locale: Language) => void;
	setLoading: (loader: boolean) => void;
	addInfo: (message: string) => void;
	addError: (message: string) => void;
	addToast: AddToast;
};

export const MainContext = createContext<MainContextType>({
	getTheme: () => 'light',
	setTheme: (_theme: string) => {
		// empty
	},
	getLocale: () => 'ru',
	setLocale: (_locale: Language) => {
		// empty
	},
	setLoading: () => {
		// empty
	},
	addInfo: () => {
		// empty
	},
	addError: () => {
		// empty
	},
	addToast: () => {
		// empty
	},
});

const Main = (props: any) => {
	const [theme, setTheme] = useState('light');
	const [locale, setLocale] = useState<Language>('ru');
	const { addToast } = useToasts();

	const loadingRef = useRef<LoaderRef>(null);

	return (
		<MainContext.Provider
			value={{
				getTheme: () => theme,
				setTheme,
				getLocale: () => locale,
				setLocale,
				setLoading: (loader: boolean) => {
					if (loader) {
						loadingRef.current?.show();
					} else {
						loadingRef.current?.hide();
					}
				},
				addToast,
				addInfo: (message: string) => {
					addToast(message, { appearance: 'info', autoDismiss: true });
				},
				addError: (message: string) => {
					addToast(message, { appearance: 'error', autoDismiss: false });
				},
			}}
		>
			<>
				<Loader ref={loadingRef} />
				{props.children}
			</>
		</MainContext.Provider>
	);
};

export const MainProvider = (props: any) => {
	return (
		<ToastProvider autoDismissTimeout={2000} placement={'bottom-center'}>
			<AuthProvider>
				<CartProvider>
					<Main>{props.children}</Main>
				</CartProvider>
			</AuthProvider>
		</ToastProvider>
	);
};
