import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import { MainContext } from 'src/contexts';

export const useMain = () => {
	return useContext(MainContext);
};

export const useQuery = () => {
	return new URLSearchParams(useLocation().search);
};

export const useLoading = () => {
	return useMain().setLoading;
};

export const useTheme = () => {
	return useMain().getTheme();
};

export const useLocale = () => {
	return useMain().getLocale();
};