export class FormatUtils {
	static formatMoney = (amount, decimalCount = 2, decimal = '.', thousands = ',') => {
		try {
			decimalCount = Math.abs(decimalCount);
			decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

			const negativeSign = amount < 0 ? '-' : '';

			const d = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)), 10);
			const i = d.toString();
			const j = i.length > 3 ? i.length % 3 : 0;

			return (
				negativeSign +
        (j ? i.substr(0, j) + thousands : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
        (decimalCount
        	? decimal +
            Math.abs(amount - d)
            	.toFixed(decimalCount)
            	.slice(2)
        	: '')
			);
		} catch (e) {
			console.warn('🚀 ~ file: format.utils.ts ~ formatMoney ~ e', e);
		}
	};

	static formatValueByUnit = (value: number, unit?: string, exp: number = 1) => {
		const prefixTable = ['к', 'М', 'Г'].map((prefix, index) => ({
			prefix,
			multiplier: Math.pow(1000, index + 1),
		}));
		const { prefix, multiplier } = prefixTable.find((item) => {
			const ratio = value / item.multiplier;
			return ratio >= 1 && ratio < 1000;
		}) ?? {
			prefix: '',
			multiplier: 1,
		};
		const factor = Math.pow(10, exp);
		const shortValue = Math.floor((value / multiplier) * factor) / factor;
		return `${shortValue} ${prefix}${unit ?? ''}`;
	};
}
