import { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Page, Pageable } from '@astick/core';
import { Table, TableRef, TableTypeColumn } from '@astick/ui';
import { Role } from 'src/models';
import { useToast } from '../../base/hooks';
import { UsersService } from '../services';

type RolesWidgetProps = {
	getSelectedRoles: (data) => Promise<string[]>;
	onSubmit: (data, roles: string[]) => void;
}

export type RolesWidgetRef = {
	open: (title: string, data) => void;
	close: () => void;
};

export const RolesWidget = forwardRef<RolesWidgetRef, RolesWidgetProps>((props, ref) => {
	const toast = useToast();
	const tableRolesRef = useRef<TableRef>(null);

	const [showRoles, setShowRoles] = useState<{
		showing: boolean,
		title: string,
		data?: any,
	}>({
		showing: false,
		title: 'Роли',
	});

	useImperativeHandle(ref, () => ({
		open: (title: string, data) => {
			setShowRoles({
				showing: true,
				title,
				data,
			});
		},
		close: () => {
			handleRolesClose();
		},
	}));

	const rolesColumns: TableTypeColumn[] = [
		{ name: 'name', type: 'text', header: 'Роль', editable: false, minWidth: 120, maxWidth: 200, defaultFlex: 2 },
		{ name: 'description', type: 'textarea', header: 'Описание', minWidth: 250, maxWidth: 1000, defaultFlex: 1 },
	];

	const [rolesSelected, setRolesSelected] = useState<{
		[p: string]: {
			name: string;
			description?: string;
		}
	}>({});

	const handleRolesClose = () => setShowRoles({
		showing: false,
		title: 'Роли',
	});

	const loadRoles = useCallback(async (pageable: Pageable): Promise<Page<Role> | undefined> => {
		if (!showRoles.showing) {
			return;
		}
		const r = await UsersService.getRoles(pageable);
		if (!r.success) {
			toast.addError('Не удалось получить список ролей!');
			return;
		}
		const roles = r.value;
		const selected = await props.getSelectedRoles(showRoles.data) ?? [];
		setRolesSelected(selected.reduce((obj, v) => {
			obj[v] = {
				name: v,
			};
			return obj;
		}, {} as {
				[p: string]: {
					name: string;
					description?: string;
				}
			}));
		return roles;
	}, [showRoles]);
	
	return <Modal aria-labelledby="contained-modal-title-vcenter" centered show={showRoles.showing} onHide={handleRolesClose}>
		<Modal.Header closeButton>
			<Modal.Title>{showRoles.title}</Modal.Title>
		</Modal.Header>
		<Modal.Body>
			<Table
				ref={tableRolesRef}
				idProperty="name"
				columns={rolesColumns}
				filterValue={[{
					name: 'name',
					operator: 'eq',
					type: 'string',
					value: null,
				}]}
				pagination
				checkboxColumn
				load={loadRoles}
				selected={rolesSelected}
				onSelectionChange={(sel) => {
					setRolesSelected(sel.selected ?? {} as any);
				}}
			/>
		</Modal.Body>
		<Modal.Footer>
			<Button variant="secondary" onClick={handleRolesClose}>
				Отмена
			</Button>
			<Button type="submit" variant="primary" onClick={async () => {
				const sel = tableRolesRef.current?.getTable()?.computedSelected;
				const roles = sel ? Object.keys(sel) : [];
				props.onSubmit(showRoles.data, roles);
			}}>
				Сохранить
			</Button>
		</Modal.Footer>
	</Modal>;
});

RolesWidget.displayName = 'RolesWidget';
