export class ArrayUtils {
	static groupBy(arr: any[], groupingKeyFn?: (g) => string): { [k: string]: any[] } {
		if (!arr) {
			return {};
		}
		if (typeof groupingKeyFn !== 'function') {
			throw new Error('groupBy take a function as only parameter');
		}
		return arr.reduce((result, item) => {
			const key = groupingKeyFn(item);
			if (!result[key]) {
				result[key] = [];
			}
			result[key].push(item);
			return result;
		}, {});
	}
}
