import { CSSProperties, InputHTMLAttributes, forwardRef, useEffect, useState } from 'react';
import { Form, FormControl, InputGroup } from 'react-bootstrap';
import { ComponentEditable, ComponentFocusable } from '@astick/core';
import { TextInputType } from '../core/textInput';
import { ControlProps } from './models';
import { Message } from './message.control';
import { Label } from './label.control';
import '../styles/textInput.control.scss';

export type TextInputProps = ControlProps & TextInputType & Omit<InputHTMLAttributes<any>, 'onChange'> & {
	regex?: RegExp;
	regexMessage?: string;
	className?: string;
	classes?: {
		label?: string;
		text?: string;
	};
	styles?: {
		label?: CSSProperties;
		text?: CSSProperties;
	};
};

export type InputRef = ComponentFocusable & ComponentEditable & HTMLInputElement;

export const TextInput = forwardRef<InputRef, TextInputProps>((props, ref) => {
	const { regexMessage, ...propsControl } = props;

	const [message, setMessage] = useState<string>();
	const [focusable, setFocusable] = useState(false);

	const setCustomValidity = () => {
		if (props.message || !focusable) {
			return;
		}
		const el = (ref as any);
		if (!el) {
			return;
		}
		const msg = !props.regex || !el.value || el.value && props.regex.test(el.value) ? '' : regexMessage ?? 'Неверный формат';
		el.setCustomValidity(msg);
		setMessage(el.validity.valid ? undefined : el.validationMessage);
	};

	useEffect(() => {
		setCustomValidity();
	}, [focusable, props.regex, props.regexMessage]);

	useEffect(() => {
		setMessage(props.message);
	}, [props.message]);

	return (
		<Form.Group className={`position-relative text-input${props.className ? ` ${props.className}` : ''}`}>
			{props.label ? <Label text={props.label} hint={props.hint} hintReadonly={props.hintReadonly} className={props.classes?.label} style={props.styles?.label} /> : null}
			<InputGroup className={message ? 'is-invalid' : undefined}>
				<FormControl
					{...propsControl}
					ref={(r) => {
						ref = r;
					}}
					size={undefined}
					value={props.value}
					disabled={props.disabled}
					className={`${props.classes?.text ?? ''}${props.loading ? ' loading' : ''}${message ? ' is-invalid' : ''}`}
					placeholder={props.placeholder}
					maxLength={props.maxLength}
					style={props.styles?.text}
					onClick={props.onClick}
					onBlur={(e) => {
						setFocusable(true);
						props.onBlur?.(e);
					}}
					onChange={(e) => {
						const v = e.target.value ?? '';
						setCustomValidity();
						props.onChange?.(v, e);
					}}
				/>
				{props.loading ? (
					<div className={`input-group-text indicatorsContainer${props.disabled ? ' disabled' : ''}`}>
						<div className="loadingIndicator" aria-hidden="true">
							<span className="loadingDot"></span>
							<span className="loadingDot"></span>
							<span className="loadingDot"></span>
						</div>
					</div>
				) : null}
			</InputGroup>
			{message ? <Message type="error" text={message} /> : null}
		</Form.Group>
	);
});

TextInput.displayName = 'TextInput';