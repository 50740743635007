import { PasswordValidations, RegisterValidations } from '@astick/core';
import * as yup from 'yup';

/** Модель регистрации пользователя */
export type RegisterUser = {
	/** Фамилия */
	lastName: string;

	/** Имя */
	firstName: string;

	/** Отчество */
	surName: string;

	/** Email адрес */
	email: string;

	/** Мобильный номер */
	mobile: string;

	/** Пароль */
	password: string;

	/** Подтверждение пароля */
	confirmPassword: string;
}

export const registerSchema = yup.object({
	lastName: RegisterValidations.lastName,
	firstName: RegisterValidations.firstName,
	surName: RegisterValidations.surName,
	email: RegisterValidations.email,
	mobile: RegisterValidations.mobile,
	password: PasswordValidations.password,
	confirmPassword: PasswordValidations.getPasswordConfirmValid('password'),
});


