import { InputHTMLAttributes, useRef } from 'react';
import { useField } from 'formik';
import { Form, FormControlProps } from 'react-bootstrap';
import { useMask } from '@react-input/mask';
import { ControlProps } from '../models';
import { useFormikContext } from '../../hooks';
import { FormikUtils } from '../../utils/formik.utils';

export type InputFormProps = ControlProps & FormControlProps & InputHTMLAttributes<any> & {
	label?: string | React.ReactNode;
	text?: string;
	type?: 'text' | 'textarea' | 'password' | 'number' | 'money' | 'date' | 'checkbox' | 'radio' | 'switch';
	name: string;
	mask?: string;
	maskChar?: string;
	rows?: number;
	onChangeValue?: (value) => void;
};

export const InputForm = (props: InputFormProps) => {
	const lProps = useRef({
		focused: false,
	});
	const inputRef = useRef<any>(null);

	const _props = {...props};
	delete _props.onChangeValue;

	const [f, meta, helpers] = useField(props.name);
	const { validationSchema } = useFormikContext();
	const field: any = { ...f };
	delete field.onChange;

	const id = `cbx_${props.name}`;
	const isRequired = FormikUtils.isRequiredField(validationSchema, props.name);
	const label = `${props.label}${isRequired ? '*' : ''}`;

	const getDateValue = (v) => {
		if (!v) {
			return '';
		}
		return v instanceof Date ? v.toISOString().slice(0, 10) : v.length > 10 ? v.slice(0, 10) : v ?? '';
	};

	const value = f.value && (props.type === 'number' || props.type === 'money') && typeof f.value === 'string'
		? parseFloat(f.value?.replace(/\s/g, ''))
		: props.type === 'date'
			? getDateValue(f.value)
			: f.value ?? '';

	const initText = () => {
		if (!inputRef?.current) {
			return;
		}
		if (!f.value) {
			return;
		}
		if (props.type === 'money') {
			inputRef.current.value = parseFloat(f.value?.toString().replace(/\s/g, ''))?.toLocaleString();
		}
	};

	const inputMaskRef = useMask({
		mask: props.mask,
		replacement: { _: /\d/ },
	});

	return (
		<div className={`mb-3 ${props.className}`} style={{ ...props.style, position: 'relative' }}>
			{props.type === 'checkbox' || props.type === 'radio' || props.type === 'switch' ? (
				<Form.Check
					{..._props}
					{...field}
					id={id}
					className={!props.disabled ? 'clickable' : undefined}
					type={props.type}
					label={label}
					name={props.name}
					checked={!!f.value}
					isInvalid={props.isInvalid || !!meta.error}
					onChange={(e) => {
						const v = e.target.checked;
						helpers.setValue(v);
						f.onChange(v.toString());
						props.onChangeValue?.(v);
					}}
				/>
			) : (
				<>
					{label ? <div className="label">{label}</div> : null}
					<Form.Control
						{..._props}
						{...field}
						ref={props.mask ? inputMaskRef : (r) => {
							inputRef.current = r;
							if (!lProps.current.focused) {
								initText();
							}
						}}
						className={props.onClick ? 'clickable' : ''}
						as={props.type === 'textarea' ? 'textarea' : undefined}
						name={props.name}
						value={value}
						isInvalid={props.isInvalid || (meta.touched && !!meta.error)}
						onChange={(e) => {
							const v = props.type === 'date' ? new Date(e.target.value) : e.target.value;
							helpers.setValue(v);
							props.onChangeValue?.(v);
						}}
						onFocus={() => {
							lProps.current.focused = true;
							if (props.type === 'money' && f.value) {
								inputRef.current.value = f.value.toString().replace(/\s/g, '');
							}
						}}
						onBlur={(e) => {
							lProps.current.focused = false;
							initText();
							f.onBlur(e);
						}}
					/>
				</>
			)}
			<Form.Control.Feedback type="invalid">
				{meta.error}
			</Form.Control.Feedback>
		</div>
	);
};

InputForm.displayName = 'InputForm';
