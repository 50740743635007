import moment from 'moment';
import { UnitsOfTime } from '../models/date';

/** Утилита по работе с датой */
export class DateUtils {
	/* Получение формата даты по единицы измерения даты */
	static getFormatByUnit = (unit: UnitsOfTime) => {
		return ['second', 'seconds', 's'].includes(unit)
			? 'DD.MM.YYYY HH:mm:ss'
			: ['minute', 'minutes', 'm'].includes(unit)
				? 'DD.MM.YYYY HH:mm'
				: ['hour', 'hours', 'h'].includes(unit)
					? 'DD.MM.YYYY HH:00'
					: ['day', 'days', 'd'].includes(unit)
						? 'DD.MM.YYYY'
						: ['month', 'months', 'M'].includes(unit)
							? 'MM.YYYY'
							: ['year', 'years', 'y'].includes(unit)
								? 'YYYY'
								: 'DD.MM.YYYY HH:mm:ss';
	};
	/** Сравнение дат */
	static equals = (unit: UnitsOfTime, date1: Date, date2: Date) => {
		if (!date1 && !date2) {
			return false;
		}
		if ((date1 && !date2) || (!date1 && date2)) {
			return true;
		}
		try {
			const dateFormat = DateUtils.getFormatByUnit(unit);
			return moment(date1).format(dateFormat) === moment(date2).format(dateFormat);
		} catch (e) {
			console.warn('🚀 ~ file: format.utils.ts ~ DateUtils ~ e', e);
			return false;
		}
	};
}
