/* eslint-disable max-len */
export type ControlsIcons = 'hint' | 'arrowDown' | 'arrowUp' | 'edit' | 'delete' | 'info';

export const controlsIcons: { [p in ControlsIcons]: (size?: number) => any } = {
	arrowDown: (size = 24) => (
		<svg xmlns="http://www.w3.org/2000/svg" width={size ?? 8} height={size ?? 6} fill="none" viewBox="0 0 8 6">
			<path d="M6.66536 1.66669L3.9987 4.33335L1.33203 1.66669" stroke="#90A4AE" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	),
	arrowUp: (size = 24) => (
		<svg xmlns="http://www.w3.org/2000/svg" width={size ?? 8} height={size ?? 6} fill="none" viewBox="0 0 8 6">
			<path d="M1.33195 4.33398L3.99862 1.66732L6.66528 4.33398" stroke="#90A4AE" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	),
	hint: (size = 24) => (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} fill="none" viewBox="0 0 24 24">
			<path
				fill="#000"
				fillRule="evenodd"
				d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM10.9136 13.2756C10.8303 13.836 11.3056 14.2984 11.8721 14.2984H12.3423C12.6194 14.2984 12.8488 14.083 12.8663 13.8065C12.9232 13.2796 13.1485 12.8199 13.5422 12.4274L14.1715 11.8065C14.6635 11.3118 15.008 10.8629 15.2048 10.4597C15.4016 10.0511 15.5 9.61828 15.5 9.16129C15.5 8.15591 15.197 7.37903 14.591 6.83065C13.985 6.27688 13.133 6 12.035 6C10.9473 6 10.0875 6.29032 9.4556 6.87097C9.08087 7.21816 8.81632 7.64512 8.66195 8.15186C8.47809 8.75539 9.02141 9.28226 9.65233 9.28226C10.1864 9.28226 10.5845 8.84723 10.9561 8.44128C11.0079 8.3846 11.0593 8.32848 11.1104 8.27419C11.3435 8.02688 11.6517 7.90323 12.035 7.90323C12.843 7.90323 13.2469 8.35753 13.2469 9.26613C13.2469 9.5672 13.1693 9.85484 13.0139 10.129C12.8585 10.3978 12.5451 10.75 12.0738 11.1855C11.6077 11.6156 11.2865 12.0538 11.1104 12.5C11.0232 12.721 10.9576 12.9795 10.9136 13.2756ZM10.9706 15.8871C10.7375 16.1183 10.621 16.414 10.621 16.7742C10.621 17.129 10.7349 17.422 10.9628 17.6532C11.1959 17.8844 11.5015 18 11.8796 18C12.2577 18 12.5607 17.8844 12.7886 17.6532C13.0216 17.422 13.1382 17.129 13.1382 16.7742C13.1382 16.414 13.0191 16.1183 12.7808 15.8871C12.5477 15.6505 12.2473 15.5323 11.8796 15.5323C11.5118 15.5323 11.2088 15.6505 10.9706 15.8871Z"
				clipRule="evenodd"
			/>
		</svg>
	),
	edit: (size = 24) => (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 64 64">
			<path d="M55.736,13.636l-4.368-4.362c-0.451-0.451-1.044-0.677-1.636-0.677c-0.592,0-1.184,0.225-1.635,0.676l-3.494,3.484   l7.639,7.626l3.494-3.483C56.639,15.998,56.639,14.535,55.736,13.636z" />
			<polygon points="21.922,35.396 29.562,43.023 50.607,22.017 42.967,14.39  " />
			<polygon points="20.273,37.028 18.642,46.28 27.913,44.654  " />
			<path d="M41.393,50.403H12.587V21.597h20.329l5.01-5H10.82c-1.779,0-3.234,1.455-3.234,3.234v32.339   c0,1.779,1.455,3.234,3.234,3.234h32.339c1.779,0,3.234-1.455,3.234-3.234V29.049l-5,4.991V50.403z" />
		</svg>
	),
	delete: (size = 24) => (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
			<path d="M11.169,3.99l9.637,-0c-0.148,-0.414 -0.386,-0.795 -0.703,-1.111c-0.563,-0.563 -1.326,-0.879 -2.122,-0.879c-1.218,-0 -2.769,-0 -3.987,-0c-0.796,-0 -1.559,0.316 -2.121,0.879c-0.317,0.316 -0.556,0.697 -0.704,1.111Z" />
			<path d="M5.984,7.99l-0,18.989c-0,0.796 0.316,1.559 0.878,2.121c0.563,0.563 1.326,0.879 2.122,0.879c3.463,-0 10.536,-0 14,-0c0.795,-0 1.558,-0.316 2.121,-0.879c0.563,-0.562 0.879,-1.325 0.879,-2.121l-0,-18.989l1.012,-0c0.552,-0 1,-0.448 1,-1c-0,-0.552 -0.448,-1 -1,-1l-22.004,-0c-0.552,-0 -1,0.448 -1,1c-0,0.552 0.448,1 1,1l0.992,-0Zm9.004,5.011l0.012,10c0.001,0.552 0.449,1 1.001,0.999c0.552,-0.001 1,-0.449 0.999,-1.001l-0.012,-10c-0.001,-0.552 -0.45,-1 -1.002,-0.999c-0.552,0.001 -0.999,0.449 -0.998,1.001Zm3.993,0l0.013,10c0,0.552 0.449,1 1.001,0.999c0.552,-0.001 0.999,-0.449 0.999,-1.001l-0.013,-10c-0,-0.552 -0.449,-1 -1.001,-0.999c-0.552,0.001 -0.999,0.449 -0.999,1.001Zm-7.987,0l0.012,10c0.001,0.552 0.45,1 1.001,0.999c0.552,-0.001 1,-0.449 0.999,-1.001l-0.012,-10c-0.001,-0.552 -0.45,-1 -1.001,-0.999c-0.552,0.001 -1,0.449 -0.999,1.001Z" />
		</svg>
	),
	info: (size = 24) => (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32">
			<path d="M16,12a2,2,0,1,1,2-2A2,2,0,0,1,16,12Zm0-2Z" />
			<path d="M16,29A13,13,0,1,1,29,16,13,13,0,0,1,16,29ZM16,5A11,11,0,1,0,27,16,11,11,0,0,0,16,5Z" />
			<path d="M16,24a2,2,0,0,1-2-2V16a2,2,0,0,1,4,0v6A2,2,0,0,1,16,24Zm0-8v0Z" />
		</svg>
	),
};
