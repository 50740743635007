import { useEffect, useState } from 'react';
import { Form, FormControlProps } from 'react-bootstrap';
import { useField } from 'formik';
import { Select, SelectProps } from '../select.control';
import { useFormikContext } from '../../hooks';
import { FormikUtils } from '../../utils';

export type SelectFormProps = FormControlProps & SelectProps & {
  name: string;
  getData?: () => Promise<any[] | undefined>;
};

export const SelectForm = (props: SelectFormProps) => {
	const { valueProperty = 'value' } = props;

	const [f, meta, helpers] = useField(props.name);
	const { validationSchema } = useFormikContext();
	const field: any = { ...f };

	const [data, setData] = useState<any[] | undefined>(props.options ? props.options as any[] : f.value ? [f.value] : undefined);
	const [loading, setLoading] = useState(false);

	const isRequired = FormikUtils.isRequiredField(validationSchema, props.name);

	const value = props.value ?? data?.find((item) => (item && f.value !== undefined && f.value !== null ? item[valueProperty] === f.value : false))?.[valueProperty];

	const updateData = () => {
		if (!props.getData) {
			return;
		}
		setLoading(true);
		props.getData().then((items) => {
			setData(items);
			setLoading(false);
		});
	};

	useEffect(() => {
		updateData();
	}, []);

	return (
		<div className='mb-3' style={{ position: 'relative' }}>
			<Select
				{...field}
				isLoading={loading}
				{...props}
				options={data}
				label={`${props.label}${isRequired ? '*' : ''}`}
				name={props.name}
				value={value}
				messageType="error"
				message={meta.touched && meta.error ? meta.error : undefined}
				isInvalid={props.isInvalid || (meta.touched && !!meta.error)}
				onChange={(value) => {
					if (props.onChange) {
						props.onChange(value);
						return;
					}
					const v = value ? value[valueProperty] : undefined;
					helpers.setValue(v);
				}}
				onBlur={(e) => {
					f.onBlur(e);
					helpers.setTouched(true);
				}}
			/>
			<Form.Control.Feedback type="invalid">
				{meta.error}
			</Form.Control.Feedback>
		</div>
	);
};

SelectForm.displayName = 'SelectForm';