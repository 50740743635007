// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-control-date-empty-value {
  color: #aaa;
}`, "",{"version":3,"sources":["webpack://./src/lib_ui/src/styles/datePicker.control.scss","webpack://./src/lib_ui/src/styles/base.scss"],"names":[],"mappings":"AAEA;EACG,WCHiB;ADEpB","sourcesContent":["@import './base.scss';\n\n.form-control-date-empty-value {\n  \tcolor: $placeholder_color;\n}\n","$placeholder_color: #aaa;"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
