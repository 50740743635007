import { EventTarget } from '../eventTarget.event';

export type IOEventsTypes = 'io' | 'touch' | 'key';

export class IOEventsTarget {
	private _eventTarget = new EventTarget();

	addEventListener = (type: IOEventsTypes, callback: any) => {
		this._eventTarget.addEventListener(type, callback);
	};

	removeEventListener = (type: IOEventsTypes, callback: any) => {
		this._eventTarget.removeEventListener(type, callback);
	};

	emitIO = (sourceType: string, target: any) => {
		this._eventTarget.dispatchEvent({
			type: 'io',
			initType: sourceType,
			target,
		});
	};

	emitTouch = (target: any): string => {
		this._eventTarget.dispatchEvent({
			type: 'touch',
			initType: 'touch',
			target,
		});
		return 'touch';
	};

	emitKey = (target: any): string => {
		this._eventTarget.dispatchEvent({
			type: 'key',
			initType: 'key',
			target,
		});
		return 'key';
	};
}
