import { Page, Pageable } from '../models';

export const PageableUtils = {
	getEmptyPageable: (): Pageable => ({
		limit: 9999,
		offset: 0,
	}),
	getEmptyPagingResult: <T = any>(items?: T[]): Page<T> => {
		const size = items?.length ?? 0;
		return {
			limit: 9999,
			offset: 0,
			size,
			totalSize: size,
			items: items ?? [],
		};
	},
};
