/* eslint-disable max-len */
export type IconFormTypes = 'created' | 'updated';

export const IconForm = (props: { type: IconFormTypes; size?: number }) => {
	switch (props.type) {
		case 'created':
			return (
				<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M10.125 6.3252H3.225C3.075 6.3252 3 6.4002 3 6.55019V15.7751C3 15.9251 3.075 16.0001 3.225 16.0001H10.2C10.275 16.0001 10.425 15.9251 10.425 15.7751V6.55019C10.35 6.4002 10.275 6.3252 10.125 6.3252Z"
						stroke="#C1CCD1"
						strokeMiterlimit="10"
					/>
					<path d="M5.17578 9.5498H8.17576" stroke="#C1CCD1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
					<path d="M5.17578 12.4004H8.17576" stroke="#C1CCD1" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
					<path
						d="M11.0265 13.6749H13.0515C13.1265 13.6749 13.2765 13.5999 13.2765 13.4499V4.225C13.2765 4.075 13.2015 4 13.0515 4H6.07656C6.00156 4 5.85156 4.075 5.85156 4.225V6.02499"
						stroke="#C1CCD1"
						strokeMiterlimit="10"
					/>
				</svg>
			);
		case 'updated':
			return (
				<svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M6.09309 5.20898H3.4884C2.66978 5.20898 2 5.87876 2 6.69738V14.4371C2 15.2557 2.66978 15.9254 3.4884 15.9254H11.8234C12.642 15.9254 13.3118 15.2557 13.3118 14.4371V11.8324"
						stroke="#C1CCD1"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						d="M13.1619 4.3907C12.641 3.86977 11.8224 3.86977 11.3014 4.3907L6.01764 9.67452C6.01764 9.67452 5.94322 9.74894 5.94322 9.82336L5.27344 12.3536C5.27344 12.4281 5.27344 12.5769 5.34786 12.6513C5.42228 12.7257 5.4967 12.7257 5.64554 12.7257L8.17581 12.056C8.25023 12.056 8.25023 11.9815 8.32465 11.9815L13.6085 6.69772C14.1294 6.17678 14.1294 5.35816 13.6085 4.83722L13.1619 4.3907ZM8.47349 11.6839L6.2409 9.45126L8.47349 11.6839ZM13.2364 6.9954L11.0038 4.7628L13.2364 6.9954Z"
						stroke="#C1CCD1"
						strokeMiterlimit="10"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</svg>
			);
	}
};
