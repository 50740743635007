/* eslint-disable no-template-curly-in-string */
/* eslint-disable no-control-regex */
/* eslint-disable no-useless-escape */
// tslint:disable: no-invalid-template-strings

import * as yup from 'yup';

const userNameRegex = /^^(?=.{2,128}$)(?!.*( )\1)([А-ЯЁ][–\-\—]?)([А-яЁё ][–\-\—]?)*$/;
const userNameMessage = 'Допустимые символы: начинается с заглавной буквы, может содержать кириллицу, пробелы, тире. Минимальное количество символов: 2.';

export const AuthValidations = {
	login: yup.string().required('Не заполнено!').max(100, 'Максимальная длина телефона или e-mail ${max} символов'),
	password: yup.string().required('Не заполнено!').max(100, 'Максимальная длина пароля ${max} символов'),
};

export const PasswordValidations = {
	password: yup
		.string()
		.required('Необходимо ввести пароль')
		.min(8, 'Минимальная длина пароля ${min} символов')
		.max(100, 'Максимальная длина пароля ${max} символов')
		.matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-.?!,:;()"\'\/])[A-Za-z\d-.?!,:;()"\'\/]{8,}$/, {
			excludeEmptyString: true,
			message:
				// eslint-disable-next-line max-len
				'Допустимые символы пароля: латиница, цифры и знаки пунктуации .?!,:;-"()\'/. Пароль должен содержать не менее 8 символов, не менее одного символа цифры, заглавной, маленькой буквы, знака пунктуации.',
		}),
	getPasswordConfirmValid: (name: string) =>
		yup
			.string()
			.required('Необходимо повторить пароль')
			.oneOf([yup.ref(name)], 'Пароли не совпадают!'),
};

export const RegisterValidations = {
	lastName: yup.string().min(2, 'Минимальная длина фамилии ${min} буквы.').matches(userNameRegex, {
		excludeEmptyString: true,
		message: userNameMessage,
	}).required('Необходимо ввести фамилию'),
	firstName: yup.string().min(2, 'Минимальная длина имени ${min} буквы.').matches(userNameRegex, {
		excludeEmptyString: true,
		message: userNameMessage,
	}).required('Необходимо ввести имя'),
	surName: yup.string().min(2, 'Минимальная длина отчества ${min} буквы.').matches(userNameRegex, {
		excludeEmptyString: true,
		message: userNameMessage,
	}).required('Необходимо ввести отчество'),
	email: yup.string().matches(
		// eslint-disable-next-line max-len
		/(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i,
		{
			excludeEmptyString: true,
			message: 'Неверный формат email',
		},
	).required('Необходимо ввести email'),
	mobile: yup.string().matches(/^\+\d 9\d{2} \d{3} \d{2} \d{2}$|^\+\d $/i, {
		excludeEmptyString: true,
		message: 'Неверный формат номера телефона',
	}).required('Необходимо ввести номера телефона'),
	mobileSmall: yup.string().matches(/^\+\d{11}$/i, {
		excludeEmptyString: true,
		message: 'Неверный формат номера телефона',
	}),
	...PasswordValidations,
};

export const validate = (validSchema: yup.Schema<any>, value: any) => {
	try {
		validSchema.validateSync(value, { abortEarly: false });
		return undefined;
	} catch (err) {
		return (err as any).inner.reduce((p, c) => {
			const r = { ...p };
			r[c.path] = c.message;
			return r;
		}, {});
	}
};
