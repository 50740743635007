import { useCallback, useEffect } from 'react';
import { IResult } from '@astick/core';
import { Table, TableTypeColumn } from '@astick/ui';
import { useLoading } from 'src/hooks';
import { PricesService } from '../../services';
import { Provider, providerSchema } from 'src/models/prices';
import { useToast } from '../../../base/hooks';

export const ProvidersPage = () => {
	const setLoading = useLoading();
	const toast = useToast();

	const columns: TableTypeColumn[] = [
		{ name: 'id', header: 'ID', maxWidth: 100, defaultVisible: false, creatableHidden: true, editable: false },
		{ name: 'extraId', header: 'Расширенный ID поставщика', maxWidth: 100 },
		{ name: 'name', header: 'Поставщик', maxWidth: 150 },
		{ name: 'jurName', header: 'Юридическое наименование поставщика', maxWidth: 200 },
		{ name: 'host', header: 'Хост Web поставщика', width: 200, maxWidth: 250 },
		{ name: 'minShipment', header: 'Мин. сумма отгрузки', type: 'number', maxWidth: 150 },
		{ name: 'extraProductLinkTemplate', header: 'Шаблон ссылки на ресурс поставщика', maxWidth: 300 },
		{ name: 'description', header: 'Описание поставщика', width: 200, maxWidth: 300 },
		{ name: 'priority', header: 'Приоритет поставщика поиска товаров', type: 'number', maxWidth: 70 },
		{ name: 'isSync1C', header: 'Синхронизация с 1C', type: 'boolean', maxWidth: 70 },
	];

	useEffect(() => {
		document.title = 'Поставщики товаров';
	}, []);

	const loadProviders = useCallback(async (pageable) => {
		const r = await PricesService.getProviders(pageable);
		if (r.success) {
			return r.value;
		} else {
			toast.addError('Не удалось получить список поставщиков товаров!');
		}
	}, []);

	return <Table
		idProperty="id"
		columns={columns}
		schema={providerSchema}
		sortable
		defaultSortInfo={[{
			name: 'name',
			dir: 1,
		}]}
		filterValue={[{
			name: 'name',
			operator: 'eq',
			type: 'string',
		}, {
			name: 'isSync1C',
			operator: 'eq',
			type: 'boolean',
		}]}
		creatable
		editable
		deletable
		pagination
		showColumnMenuTool
		load={loadProviders}
		onSubmit={async (operation, data: Provider) => {
			setLoading(true);
			let r: IResult | undefined;
			switch (operation) {
				case 'create':
					r = await PricesService.createProvider(data);
					if (!r.success) {
						toast.addError(`Не удалось добавить нового поставщика "${data.name}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно добавлен новый поставщик "${data.name}"`);
					}
					break;
				case 'edit':
					r = await PricesService.updateProvider(data);
					if (!r.success) {
						toast.addError(`Не удалось изменить поставщика "${data.name}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно изменен поставщик "${data.name}"`);
					}
					break;
				case 'delete':
					r = await PricesService.deleteProvider(data.id);
					if (!r.success) {
						toast.addError(`Не удалось удалить поставщика "${data.name}".${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
					} else {
						toast.addSuccess(`Успешно удален поставщик "${data.name}"`);
					}
					break;
				default:
					r = {
						success: false,
					};
			}
			setLoading(false);
			return r;
		}}
	/>;
};
