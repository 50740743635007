import { SearchResultProductsTypes } from 'src/models';

export const SearchUtils = {
	/** Получение наименование типа результата поиска товаров
     * @param {type} Тип результата поиска товаров
     */
	getSearchResultProductsTypesNames: (type: SearchResultProductsTypes): string => {
		switch (type) {
			case 'article':
				return 'Запрошенный артикул';
			case 'originals':
				return 'Предложения по оригинальным производителям';
			case 'substitutes':
				return 'Предложения по заменителям';
			case 'details':
				return 'Детали, содержащиеся в запрошенном артикуле';
			case 'aggregate':
				return 'Агрегаты, содержащие запрошенный артикул';
			default:
				return 'Прочие';
		}
	},
};