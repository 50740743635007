import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Category } from '../../models/catalog';
import '../../styles/categories.scss';

export const CategoriesPage = () => {
	const prm = useParams();

	const [category, _setCategory] = useState<Category>();
	const [subCategory, setSubCategory] = useState<Category>();

	const { brand, modelId } = prm;

	useEffect(() => {
		if (!brand || !modelId) {
			return;
		}
		// CatalogsService.getCategories(brand, modelId, path?.split('/')).then((r) => {
		// 	if (r.success && r.value?.length === 1) {
		// 		setCategory(r.value[0]);
		// 	} else {
		// 		toast.addError('Не удалось получить список категорий!');
		// 	}
		// });
	}, [modelId]);

	if (!brand || !category) {
		return null;
	}
	if (!category) {
		return (<div>Категория отсутствуют</div>);
	}

	return (<div className="categories">
		<h3>Каталог &quot;{brand}&quot;:</h3>
		<h4>{category.name}:</h4>
		<div className="categories-items">
			{category.items?.map((cat, index) => (
				<div key={index} className="catalog-block" onClick={() => setSubCategory(cat)}>
					<h4>{cat.number}</h4>
					<div>{cat.name}</div>
					{subCategory?.id === cat.id ?
						<div>
							{cat.items?.map((subCat, index) => (
								<div key={index} className="catalog-block">
									<h4>{subCat.number}</h4>
									<div>{subCat.name}</div>
								</div>
							))}
						</div> : null}
				</div>
			))}
		</div>
	</div>);
};
