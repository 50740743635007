import { SecurityEventsTarget, SecurityEventsTypes } from './targets/securityTarget.event';
import { Token } from '../models/token.model';

export class SecurityEvents {
	private static _actionsEventsTarget = new SecurityEventsTarget();

	static addEventListener = (type: SecurityEventsTypes, callback: any) => {
		SecurityEvents._actionsEventsTarget.addEventListener(type, callback);
	};

	static removeEventListener = (type: SecurityEventsTypes, callback: any) => {
		SecurityEvents._actionsEventsTarget.removeEventListener(type, callback);
	};

	static emitToken = (token?: Token) => {
		SecurityEvents._actionsEventsTarget.emitToken(token);
	};

	static emitClearToken = () => {
		SecurityEvents._actionsEventsTarget.emitClearToken();
	};
}
