import { MainRoutes } from './routes/main.route';

const App = () => {
	return (
		<div className="App">
			<MainRoutes />
		</div>
	);
};

export default App;
