import { useCallback, useEffect, useRef } from 'react';
import { IResult, Pageable } from '@astick/core';
import { Table, TableRef, TableTypeColumn } from '@astick/ui';
import { useLoading } from 'src/hooks';
import { useToast } from '../../../base/hooks';
import { PricesService } from '../../services';
import { ProviderRole, providerRoleSchema, typesSearchInProvidersArray } from 'src/models/prices';
import { RolesWidget, RolesWidgetRef } from '../../widgets/roles.widgets';
import './providersRoles.page.scss';

export const ProvidersRolesPage = () => {
	const setLoading = useLoading();
	const toast = useToast();

	const tableRef = useRef<TableRef>(null);
	const rolesRef = useRef<RolesWidgetRef>(null);

	const columns: TableTypeColumn[] = [
		{ name: 'id', header: 'ID', maxWidth: 100, defaultVisible: false, creatableHidden: true, editable: false },
		{
			name: 'isActive', header: 'Активность', width: 70, creatableHidden: true, editable: false, render: (item) => {
				return <div className="is_active">
					<div className={item.data.isActive ? 'active' : 'no_active'}></div>
				</div>;
			},
		},
		{
			name: 'providerId', path: 'provider.name', header: 'Поставщик', render: (item) => item.data.provider?.name, type: 'dropdown', maxWidth: 150,
			getData: async () => {
				const r = await PricesService.getProviders({
					offset: 0,
					limit: 999,
					sort: [{
						name: 'name',
						direction: 'asc',
					}],
				});
				if (r.success) {
					return r.value?.items.map(item => ({ label: item.name, value: item.id }));
				} else {
					toast.addError('Не удалось получить список поставщиков!');
				}
			},
		},
		{ name: 'rolesNames', header: 'Роли', maxWidth: 300, creatableHidden: true, editable: false, sortable: false, render: (item) => item.data.rolesNames?.join(', ') },
		{ name: 'isEnabled', header: 'Использование', type: 'boolean', maxWidth: 100 },
		{ name: 'token', header: 'Токен доступа', maxWidth: 200, visible: false },
		{ name: 'login', header: 'Логин доступа', maxWidth: 200, visible: false },
		{ name: 'password', header: 'Пароль доступа', maxWidth: 200, visible: false },
		{ name: 'priority', header: 'Приоритет', maxWidth: 200 },
		{ name: 'minShipment', header: 'Мин. сумма отгрузки', type: 'number', maxWidth: 150 },
		{ name: 'isAnalog', header: 'Поиск аналогов', type: 'boolean', maxWidth: 100 },
		{ name: 'minProbabilityExist', header: 'Мин. процент вероятности', type: 'number', maxWidth: 150 },
		{ name: 'isSearchCatalogs', header: 'Необходимость поиска списка каталогов', type: 'boolean', maxWidth: 100 },
		{ name: 'typeSearch', header: 'Тип поиска', type: 'dropdown', maxWidth: 150, data: typesSearchInProvidersArray },
	];

	useEffect(() => {
		document.title = 'Настройки поставщиков';
	}, []);

	const loadProvidersRoles = useCallback(async (pageable: Pageable) => {
		const r = await PricesService.getProvidersRoles(pageable);
		if (r.success) {
			return r.value;
		} else {
			toast.addError('Не удалось получить список настроек поставщиков товаров!');
		}
	}, []);

	return <>
		<RolesWidget ref={rolesRef} getSelectedRoles={(providerRole: ProviderRole) => Promise.resolve(providerRole.rolesNames)}
			onSubmit={async (providerRole: ProviderRole, roles) => {
				providerRole.rolesNames = roles;
				const res = await PricesService.updateProviderRole(providerRole);
				if (!res.success) {
					toast.addError(`Не удалось изменить роли для настроек поставщика "${providerRole.provider.name}".${res.code === 400 ? ` Ошибка: ${res.error}` : ''}`);
				} else {
					toast.addSuccess(`Успешно изменены роли для настроек поставщика "${providerRole.provider.name}"`);
				}
				tableRef.current?.getTable()?.reload();
				rolesRef.current?.close();
			}} />
		<Table
			ref={tableRef}
			idProperty="id"
			columns={columns}
			schema={providerRoleSchema}
			sortable
			defaultSortInfo={[{
				name: 'providerId',
				path: 'provider.name',
				dir: 1,
			}]}
			filterValue={[{
				name: 'providerId',
				operator: 'eq',
				type: 'string',
			}, {
				name: 'isEnabled',
				operator: 'eq',
				type: 'boolean',
			}, {
				name: 'priority',
				operator: 'eq',
				type: 'number',
			}, {
				name: 'minShipment',
				operator: 'eq',
				type: 'number',
			}, {
				name: 'isAnalog',
				operator: 'eq',
				type: 'boolean',
			}, {
				name: 'minProbabilityExist',
				operator: 'eq',
				type: 'number',
			}, {
				name: 'isSearchCatalogs',
				operator: 'eq',
				type: 'boolean',
			}, {
				name: 'typeSearch',
				operator: 'eq',
				type: 'select',
				getData: () => {
					return typesSearchInProvidersArray.map(ts => ({
						label: ts.label,
						id: ts.value,
					}));
				},
			}]}
			creatable
			editable
			deletable
			pagination
			showColumnMenuTool
			load={loadProvidersRoles}
			renderRowContextMenu={(menuProps, { rowProps }) => {
				const providerRole = rowProps.data;
				if (!providerRole) {
					return;
				}
				menuProps.autoDismiss = true;
				menuProps.items.splice(1, 0, {
					label: 'Роли',
					onClick: () => {
						rolesRef.current!.open(`Роли настройки поставщика "${providerRole.provider.name}"`, providerRole);
					},
				});
			}}
			onSubmit={async (operation, newData: ProviderRole, initData: ProviderRole) => {
				setLoading(true);
				let r: IResult | undefined;
				switch (operation) {
					case 'create':
						r = await PricesService.createProviderRole(newData);
						if (!r.success) {
							toast.addError(`Не удалось добавить новую настройку поставщика.${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
						} else {
							toast.addSuccess('Успешно добавлена новая настройка поставщика');
						}
						break;
					case 'edit':
						newData.rolesNames = initData.rolesNames;
						r = await PricesService.updateProviderRole(newData);
						if (!r.success) {
							toast.addError(`Не удалось изменить настройку поставщика.${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
						} else {
							toast.addSuccess('Успешно изменена настройка поставщика');
						}
						break;
					case 'delete':
						r = await PricesService.deleteProviderRole(initData.id);
						if (!r.success) {
							toast.addError(`Не удалось удалить настройку поставщика.${r.code === 400 ? ` Ошибка: ${r.error}` : ''}`);
						} else {
							toast.addSuccess('Успешно удалена настройка поставщика');
						}
						break;
					default:
						r = {
							success: false,
						};
				}
				setLoading(false);
				return r;
			}}
		/>
	</>;
};
