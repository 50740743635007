import { EventTarget } from '@astick/core';
import { Token } from '../../models/token.model';

export type SecurityEventsTypes = 'token' | 'cleanToken';

export class SecurityEventsTarget {
	private _eventTarget = new EventTarget();

	addEventListener = (type: SecurityEventsTypes, callback: any) => {
		this._eventTarget.addEventListener(type, callback);
	};

	removeEventListener = (type: SecurityEventsTypes, callback: any) => {
		this._eventTarget.removeEventListener(type, callback);
	};

	emitToken = (token?: Token): string => {
		this._eventTarget.dispatchEvent({
			type: 'token',
			initType: 'token',
			target: token,
		});
		return 'token';
	};

	emitClearToken = () => {
		this._eventTarget.dispatchEvent({
			type: 'cleanToken',
			initType: 'cleanToken',
			target: undefined,
		});
	};
}
