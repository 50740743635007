export type ComponentFocusable = {
  focus();
  blur();
  isFocused(): boolean;
};

export type ComponentEditable = {
  getValue?(): any;
  isDisabled(): boolean;
};

export const labelModes = ['top', 'float', 'leftFlexStart', 'leftFlexEnd', 'leftCenter', 'leftSpaceBetween', 'leftSpaceAround', 'leftSpaceEvenly'] as const;
export type LabelModes = typeof labelModes[number];
