import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchCatalogs } from '../../models/catalog';
import { CatalogsService } from 'src/services';
import { useAuth, useLoading, useQuery } from 'src/hooks';
import './catalogs.page.scss';

export const SearchCatalogsPage = () => {
	const query = useQuery();
	const navigate = useNavigate();
	const setLoading = useLoading();
	const auth = useAuth();

	const searchText = query.get('value');

	const [catalogs, setCatalogs] = useState<SearchCatalogs>();

	const search = async () => {
		if (!searchText) {
			return;
		}
		setLoading(true);
		const r = await CatalogsService.searchCatalogs(searchText);
		setCatalogs(r.success ? r.value : {
			searchType: 'article',
			searchValue: searchText,
			catalogsProducts: [],
		});
		setLoading(false);
	};

	useEffect(() => {
		search().then();
	}, []);

	if (!searchText)
		return <div className="catalogs"><div>Ничего не найдено</div></div>;
	if (!catalogs)
		return <div className="catalogs"><div>Идет поиск...</div></div>;
	if (!catalogs.catalogsProducts || catalogs.catalogsProducts.length === 0)
		return <div className="catalogs"><div>Ничего не найдено</div></div>;

	const isManager = auth.getUser()?.isManager;

	return <div className="search-catalogs">
		<h4>
			Запрос найден в <span className="catalogsCount">{catalogs.catalogsProducts.length}</span> каталог{catalogs.catalogsProducts.length === 1 ? 'e' : 'ах'}
		</h4>
		<div>
			<table data-align="left" data-selected="true" data-card-review-auto="false"
				data-visible-snackbar="false" data-show-toolbar={isManager}>
				<thead>
					<tr>
						<th>Бренд</th>
						<th>Артикул</th>
						<th>Описание</th>
						{isManager ? <th>Поставщики</th> : null}
					</tr>
				</thead>
				<tbody>
					{catalogs.catalogsProducts.map((catalog, index) =>
						<tr key={index} onClick={() => {
							navigate(`/search/products?catalogs=${encodeURIComponent(catalog.catalogs)}&firmName=${catalog.firmName}&articleNumber=${catalog.articleNumber}`);
						}}>
							<td>{catalog.firmName}</td>
							<td>{catalog.articleNumber}</td>
							<td>{catalog.name}</td>
							{isManager ? <td>{catalog.providers?.join(', ') ?? '-'}</td> : null}
						</tr>,
					)}
				</tbody>
			</table>
		</div>
	</div>;

};
