import * as yup from 'yup';
import { Provider } from './provider.model';

const typesSearchInProviders = ['price', 'webService'] as const;

/** Типы поиска товаров у поставщиков */
export const typesSearchInProvidersArray: {
    label: string;
    value: string;
}[] = [{
	label: 'По прайсам',
	value: typesSearchInProviders[0],
}, {
	label: 'По web-сервису',
	value: typesSearchInProviders[1],
}];

/** Типы поиска товаров у поставщиков */
export type TypesSearchInProviders = typeof typesSearchInProviders[number];

/** Поставщик товаров для пользователей */
export type ProviderRole = {
    /** ID поставщика товаров для пользователей */
    id: string;

    /** Роли пользователей */
    rolesNames: string[];

    /** ID поставщика товаров */
    providerId: string;

    /** ID поставщика товаров */
    provider: Provider;

    /** Токен доступа к поставщику товаров */
    token: string;

    /** Логин доступа к поставщику товаров */
    login: string;

    /** Пароль доступа к поставщику товаров */
    password: string;

    /** Приоритет поставщика поиска товаров */
    priority: number;

    /** Минимальная сумма отгрузки */
    minShipment: number;

    /** Флаг необходимости поиска аналогов */
    isAnalog: boolean;

    /** Минимальный процент вероятности поставки товаров */
    minProbabilityExist: number;

    /** Флаг необходимости поиска списка каталогов товаров */
    isSearchCatalogs: boolean;

    /** Флаг использования поставщика роли */
    isEnabled: boolean;

    /** Тип поиска */
    typeSearch: TypesSearchInProviders;
}

export const providerRoleSchema = yup.object().shape({
	// rolesNames: yup.array().of(yup.string().min(1, 'Минимальная значение наименования роли ${min}.')).required('Необходимо указать роли пользователей'),
	providerId: yup.string().required('Необходимо указать поставщика товаров'),
	token: yup.string().when('login', (login, schema) => {
		return !login  || !login?.[0] ? schema.required('Необходимо ввести токен доступа к поставщику товаров') : schema;
	}),
	login: yup.string().when('token', (token, schema) => {
		return !token || !token?.[0] ? schema.required('Необходимо ввести токен или логин c паролем доступа к поставщику товаров') : schema;
	}),
	password: yup.string().when('token', (token, schema) => {
		return !token || !token?.[0] ? schema.required('Необходимо ввести токен или логин c паролем доступа к поставщику товаров') : schema;
	}),
	priority: yup.number().default(0).required('Необходимо ввести приоритет поставщика поиска товаров'),
	minShipment: yup.number().default(0),
	isAnalog: yup.boolean().default(true),
	minProbabilityExist: yup.number().default(0),
	isSearchCatalogs: yup.boolean().default(true),
	isEnabled: yup.boolean().default(true),
	typeSearch: yup.string().default<TypesSearchInProviders>('price').required('Необходимо ввести Тип поиска'),
}, [['token', 'login']]);